import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
// import swst from './modules/swst';
// import stxt from './modules/stxt';
// import zyzx from './modules/zyzx';
// import fwzc from './modules/fwzc';
// import tzgg from './modules/tzgg';
import my from './modules/my';
import ecology from './modules/ecology';
import synergy from './modules/synergy';
import resource from './modules/resource';
import support from './modules/support';
import activityCenter from './modules/activityCenter';


import Layout from '@/components/Layout.vue';

Vue.use(VueRouter)

const routes = [
    {
      path: '',
      name: 'Layout',
      component: Layout,
      redirect: 'index',
      children:[
        {
          path: '/index',
          name: 'home',
          component: HomeView,
          meta: { title: '首页' },
        },
        {
          path: '/login',
          component: () => import('@/views/login'),
          meta: { title: '登录' },
      
          hidden: true,
          beforeEnter(to,from,next){
            // console.log('router中的监听',to);
            // console.log('router中的监听',from);
            if(from.fullPath == '/'){
              next('/index')
            }else{
              next()
            }
          }
        },
        {
          path: '/register',
          component: () => import('@/views/register'),
          meta: { title: '注册' },
          hidden: true,
          beforeEnter(to,from,next){
            if(from.fullPath == '/'){
              next('/index')
            }else{
              next()
            }
          }
        },
        {
          path: '/search',
          component: () => import('@/views/search'),
          hidden: true,
          meta: { title: '搜索' },
        },
        {
          path: '/forget',
          component: () => import('@/views/forget'),
          meta: { title: '修改密码' },
          hidden: true,
          beforeEnter(to,from,next){
            if(from.fullPath == '/'){
              next('/index')
            }else{
              next()
            }
          }
        },
        {
          path: '/forbiddens',
          component: () => import('@/views/forbiddens'),
          name: '鉴权页面',
          meta: { title: '无权访问',isreturn:true },
          hidden: true,
        },
      ]
    },

  // swst,
  // stxt,
  // zyzx,
  // fwzc,
  // tzgg,
  my,
  ecology,
  synergy,
  resource,
  support,
  activityCenter,
]
let routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode:'hash',
  routes
})

export default router

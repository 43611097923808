<template>
  <!-- <div class="content1" :style="{width}"> -->
  <div class="content1" >
    <!-- <div class="write">
      <div class="left" @click="drawer = true">
        <el-input placeholder="请留下您宝贵的意见和建议" v-model="input4" >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div> -->
    <el-button type="primary" round class="submit" @click="isLogin">评论</el-button> 

    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      direction="btt"
      :size="size"
    >
      <div>
        <div class="writeCommetn">
          <!--  style="font-size: 0.768rem;" -->
          <el-input
            type="textarea"
            :rows="7"
            placeholder="请留下您宝贵的意见和建议"
            v-model="value.content"
            :disabled="!userInfo || !userInfo.id"
            v-focus
            maxlength="200"
            show-word-limit
            style="font-size: 0.9rem;"
          >
          </el-input>
          <div class="rate" style="text-align: right;" v-if="userInfo && userInfo.id">
            <span v-if="commentCheckSwitch === '1' && userInfo && userInfo.account" style="color: #E6A23C;margin-right: 20px;">
                        评论需管理员审核后公开
               </span>
            <span>
          
              <el-button type="primary" round @click="submit">发布反馈</el-button>
            </span>
          </div>
          <div style="text-align: right;"  class="rate" v-else>
            <el-button type="primary" round @click="$router.push('/login')">登录后发布反馈</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "WriteComment",
  // components: {
  //   [Notify.Component.name]: Notify.Component,
  // },
  props:{
    value:{
        type:Object,
        default:{}
      },
      commentListConfig:{
        type:Object,
        default:{}
      },
      commentCheckSwitch:{
        type:String,
        default:'0'
      }
  },
  data() {
    return {
      drawer: false,
      width:0,
      size:0
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted(){
    let width = document.body.offsetWidth
    this.width=width + 'px'
    console.log("widht",width);
    // this.size = 200/window.screen.availHeight * 100 +'%'
    this.size = 250
  },
  
  methods:{
    isLogin(){
      if(this.userInfo && this.userInfo.id){
        this.drawer = true
      }else{
        this.$router.push('/login')
      }
    },
    submit(){
      // Promise.all([this.feedback(),this.point()]).then(res=>{
      //   this.$notify({ type: 'success', message: '提交成功!等待审核' })
      //   this.drawer = false
      // })
      Promise.all([this.feedback()]).then(res=>{
       this.commentCheckSwitch == '1'? this.$notify({ type: 'success', message: '提交成功!等待审核' }) :this.$notify({ type: 'success', message: '提交成功!' })
        this.drawer = false
        this.value.content = ''
        this.$emit('submitSuccess',1)
      })
    },
    feedback(){
      this.$apis(this.commentListConfig.feedback,this.value, 'POST')
        .then((res) => {
          
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        })
    },
    point(){
     return this.$apis(this.commentListConfig.point,this.value, 'POST')
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.records)
          this.loading = false
          this.page.pageNum += 1
          if(this.commentList.length == res.data.total) this.noMore = true
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        })
    }
  }
};
</script>

<style scoped lang="scss">

.content1 {
  // overflow: hidden;
  // background: #ffffff;
  // height: 2.88rem /* 90px -> 2.88rem */;
  // display: flex;
  //   justify-content: center;
  //   align-items: center;
  .write {
    width: 22.08rem /* 690px -> 22.08rem */;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    .left {
      width: 100%;
      height: 2.88rem /* 90px -> 2.88rem */;
    }
    .right {
      font-size: 0.512rem /* 16px -> .512rem */;
      width: 10%;
      text-align: right;
      img {
        vertical-align: top;
        width: 0.96rem /* 30px -> .96rem */;
        height: 0.96rem /* 30px -> .96rem */;
      }
      // margin-top: 0.64rem /* 20px -> .64rem */;
    }
  }
  // box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
  // ::v-deep .el-input {
  //   top: -0.64rem /* -20px -> -0.64rem */!important
  // }
  .writeCommetn {
    width: 22.08rem /* 690px -> 22.08rem */;
    margin: 0 auto;
    padding-top: 0.64rem /* 20px -> .64rem */;
    font-size: 0.768rem /* 24px -> .768rem */;
    .rate {
      // display: flex;
      // justify-content: space-between;
      margin-top: .64rem /* 20px -> .64rem */;
    }
  }

}
::v-deep .el-input__inner{
  height: 2rem;
}
// @media screen and (min-width: 0px) and (max-width: 380px) {
//   .content1{
//     ::v-deep .el-input {
//     top: -0.32rem /* -10px -> -0.32rem */!important
//   }
//   }

// }
.submit{
  // position: fixed;
  // bottom: .96rem /* 30px -> .96rem */;
  // right: 1.6rem /* 50px -> 1.6rem */;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}
</style>
<template>
  <div>
    <div class="bl">
      <div class="middle">
        <div class="top">{{ topObj.top }}</div>
        <div class="bottom">{{ topObj.bottom  }}</div>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name:"Top",
  props:{
    topObj:{
      type:Object,
      default:{}
    }
  }
};
</script>

<style lang="scss" scoped>
.bl{
    position: relative;
    background: url(../assets/bl/jcrjTopImg.png) no-repeat;
    background-position: 0 0 ;
    height: 6.4rem /* 200px -> 6.4rem */;
    .middle{
      position: absolute;
      color: #FFFFFF;
      margin-top: 1.92rem /* 60px -> 1.92rem */;
      margin-left: .96rem /* 30px -> .96rem */;
      .top{
        font-size: 1.024rem /* 32px -> 1.024rem */;
      }
      .bottom{
        font-size: .64rem /* 20px -> .64rem */;
        margin-top: .64rem /* 20px -> .64rem */;
      }
    }
  
}
</style>
<template>
  <div class="content1" :style="{width}">
    <div class="write">
      <div class="left" @click="drawer = true">
        <el-input placeholder="请输入内容" >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      direction="btt"
      :size="size"
    >
      <div>
        <div class="writeCommetn">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="value.comment"
          >
          </el-input>
          <div class="rate">
            <!-- <span>
              <span style="float: left"> 产品评分： </span>
              <span style="float: left">
                <el-rate v-model="value.point"></el-rate>
              </span>
            </span> -->
            <span>
              <span style="float: left"> 附件： </span>
              <span style="float: left">
                <FileUpload v-model="value.files"></FileUpload>
              </span>
            </span>
          </div>
          <div class="bottom">
            <el-button type="primary" round @click="submit" style="width: 100%;">发布反馈</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "CommentGd",
  // components: {
  //   [Notify.Component.name]: Notify.Component,
  // },
  props:{
    value:{
        type:Object,
        default:()=>{return {}}
      },
      commentListConfig:{
        type:Object,
        default:()=>{return {}}
      },
      commentCheckSwitch:{
        type:String,
        default:'0'
      }
  },
  data() {
    return {
      drawer: false,
      width:0,
      size:"32%"
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted(){
    let width = document.body.offsetWidth
    this.width=width + 'px'
    console.log("widht",width);
    console.log("window.screen.availHeight: ",window.screen.availHeight);
    this.size = 250/window.screen.availHeight * 100 +'%'
  },
  
  methods:{
    submit(){
      // Promise.all([this.feedback(),this.point()]).then(res=>{
      //   this.$notify({ type: 'success', message: '提交成功!等待审核' })
      //   this.drawer = false
      // })
      Promise.all([this.feedback()]).then(res=>{
        this.$notify({ type: 'success', message: '提交成功!' })
        this.drawer = false
        this.$emit('submitSuccess',1)
      })
    },
    feedback(){
      let data = JSON.parse(JSON.stringify(this.value))
      let url = '/swbus/frontapis/swUcWorkOrderComment/submit'
          data.worderId = this.$route.query.id
          // data.worderNo = this.rows && this.rows.worderNo ? this.rows.worderNo : ''
          data.worderNo  = ''
          data.userType = '1'
          // console.log("handlefabu-----------url,data",url,data)

        return  this.$apis(url, data)
            .then((res) => {
              this.$success('发布成功')
              // this.$emit('submitSuccess',1)
            })
            .catch((error) => {
              console.log('error-----------', error)
            })
    },
    point(){
     return this.$apis(this.commentListConfig.point,this.value, 'POST')
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.records)
          this.loading = false
          this.page.pageNum += 1
          if(this.commentList.length == res.data.total) this.noMore = true
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        })
    }
  }
};
</script>

<style scoped lang="scss">

.content1 {
  overflow: hidden;
  background: #ffffff;
  height: 2.88rem /* 90px -> 2.88rem */;
  display: flex;
    justify-content: center;
    align-items: center;
  .write {
    width: 22.08rem /* 690px -> 22.08rem */;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    .left {
      width: 90%;
      height: 2.88rem /* 90px -> 2.88rem */;
      display: flex;
    align-items: center;
    }
    .right {
      font-size: 0.512rem /* 16px -> .512rem */;
      width: 10%;
      text-align: right;
      img {
        vertical-align: top;
        width: 0.96rem /* 30px -> .96rem */;
        height: 0.96rem /* 30px -> .96rem */;
      }
      // margin-top: 0.64rem /* 20px -> .64rem */;
    }
  }
  box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
  // ::v-deep .el-input {
  //   top: -0.64rem /* -20px -> -0.64rem */!important
  // }
  .writeCommetn {
    width: 22.08rem /* 690px -> 22.08rem */;
    margin: 0 auto;
    padding-top: 0.64rem /* 20px -> .64rem */;
    font-size: 0.768rem /* 24px -> .768rem */;
    .rate {
      display: flex;
      justify-content: space-between;
      margin-top: .64rem /* 20px -> .64rem */;
    }
  }

}
.bottom{
  position: fixed;
  bottom: .32rem /* 10px -> .32rem */;
  width: 22.08rem /* 690px -> 22.08rem */;
}
@media screen and (min-width: 0px) and (max-width: 380px) {
  .content1{
    ::v-deep .el-input {
    top: -0.32rem /* -10px -> -0.32rem */!important
  }
  }

}
</style>
import request from '@/utils/request'

export function api (url, data, method = 'post', notLimitReqTime = false) {
    return request({
        url: url,
        method: method,
        data: data,
        notLimitReqTime: notLimitReqTime,//不限制请求时间间隔
    });
}
export function apip (url, query,data={}, method = 'get') {
    return request({
        url: url,
        method: method,
        data:data,
        params: query
    });
}
<template>
  <div class="content">
    <div class="top">
      <!-- <img :src="isopen?require('@/assets/HeadImg/open.png'):require('@/assets/HeadImg/close.png')" alt="" srcset="" @click="isopen = !isopen"> -->
      <img v-if="!goBack" :src="isactiveLeft?require('@/assets/HeadImg/open.png'):require('@/assets/HeadImg/close.png')" alt="" srcset="" @click.stop="initClick" :class="{imgShark1:true,rota0:isactiveLeft,rota360:!isactiveLeft}">
      <img v-else="!goBack" src="@/assets/HeadImg/goBack.png" alt="" srcset="" @click="goback" >

      <div style="max-width: 50%;">
        <img src="@/assets/bl/topLogo.png" alt="" srcset="">
        <!-- <span style="margin-left: .64rem /* 20px -> .64rem */;font-size: .896rem /* 28px -> .896rem */;">{{ $route.meta.title }}</span> -->
        <div style="" class="headTitle">
        <span >{{ detailTitle }}</span>
        </div>
      </div>
      <div class="imgs">
        <img src="@/assets/HeadImg/search.png" alt="" srcset="" @click="$router.push('/search')" v-if="$route.path != '/search'">
      <!-- <img src="@/assets/HeadImg/person.png" alt="" srcset="" @click="$nav('/my/menu')"> -->
      <img :class="{isLogin:userInfo && userInfo.id}" :src="userInfo && userInfo.id ? userInfo.avatar?userInfo.avatar : require('@/assets/bl/avatar.png'): require('@/assets/HeadImg/person.png')" alt="" srcset="" @click="$nav('/my/menu')">
      </div>
    </div>
      <!-- <div class="bottom" v-if="isopen" > -->
      <div class="bottom" >
          <div class="left" style="height: 0;transition: all 0.3s;overflow-y: hidden;" :class="{isactiveLeft:isactiveLeft}">
            <div style="overflow-x:visible">
              <div v-for="(item,index) in routes" :key="index" @click="changRoute(item,false,true)" :class="{backRed:item.isactive,}"  style="padding-left:0">
          {{ item.label }}
        </div>
            </div>
      </div>
      
      <div class="right"  style="height:0;width: 0;transition: width 0.3s,opacity 0.3s;opacity: 0;" :class="{widthRem:routeChildren.length && isactiveLeft}">
        <div v-for="(item,index) in routeChildren" :key="index"  @click="changRoute(item,false,true)" :class="{isactive:item.isactive}">
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  buildTree
} from '@/requires/index'

import { mapState } from 'vuex'
export default {
  name:"Head",
  data() {
    return {
      isopen:false,
      routes:[],
      routeChildren:[],
      //动画控制
      isactiveLeft:false,
      edg:0,
      title:"首页",
      isTopClick:false,
      goBack:false,
      detailTitle:this.$route.meta.title
    };
  },
  created(){
    this.init()
    this.$bus.$on('changeTitle',(res)=>{
      this.detailTitle = this.$route.meta.title
    })
    this.$bus.$on('getRoutesList',()=>{
      this.getRoutes()
    })
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted(){
    window.addEventListener('click', (e) => { 
        var elem = e.target;
        var targetArea = document.querySelector('.bottom');
        if(!(targetArea && targetArea.contains(elem))){
          this.isactiveLeft = false
          this.routes.map(item=>item.isactive = false)
          this.routeChildren = []
          }
      })
  },
  watch:{
    $route: {
            handler: function (route) {
                // console.log('head',route);
                if(route.matched[0].path == '/my' && route.path !== '/my/menu'){
                  this.goBack = true
                }else{
                  this.goBack = false
                }
            },
            immediate: true
        },
    'isactiveLeft':{
      handler(newval,oldval){
       
        let that = this
      this.$nextTick(()=>{
        if(newval){
          this.allFalse(this.routes)
          let path = '/#' + this.$route.path
          this.routes.forEach(item=>{
            // if(item.data && item.data.content.includes(path)){
            if(item.data && item.data.content == path){
              this.changRoute(item,true)
              item.isactive = true
            }else{
                item.children && item.children.map((item1)=>{
                //  if(item1.data && item1.data.content.includes(path)){
                 if(item1.data && item1.data.content == path) {
                  that.changRoute(item,true)
                  that.routeChildren && that.routeChildren.map(item2=>{
                    item2.isactive = false
                    this.$set(item2,'isactive',false)
                    if(item1.data && item2.data && item1.data.content == item2.data.content) {
                      item2.isactive = true
                    }
                  })
                 }
                })
              }
          })
        }
      })
      }
    }
  },
  methods:{
    goback(){
      this.$router.go(-1)
    },
    initClick(){
      this.isTopClick = true
      this.isactiveLeft = !this.isactiveLeft
      this.routes.map(item=>item.isactive = false)
      this.routeChildren = []
    },
    init(){
      this.getRoutes()
    },
     getBrowserInterfaceSize() {
        var pageWidth = window.innerWidth;
        var pageHeight = window.innerHeight;
    
        if (typeof pageWidth != "number") {
            //在标准模式下面
            if (document.compatMode == "CSS1Compat" ) {
                pageWidth = document.documentElement.clientWidth;
                pageHeight = document.documentElement.clientHeight;
            } else {
                pageWidth = document.body.clientWidth;
                pageHeight = window.body.clientHeight;
            }
        }
    
        return {
            pageWidth: pageWidth,
            pageHeight: pageHeight
        }
    },
    allFalse(arr){
      arr.forEach(item=>{
        item.children && item.children.map(item=>item.isactive = false)
      })
    },
   getRoutes(){
      buildTree().then(res=>{
        this.routes = res.data && res.data.map(item=>{
          item.isactive = false
          item.children && item.children.forEach(item => item.isactive = false)
          return item
        })
      })
    },
    changRoute(item,boolean,boolean1){
      boolean1 && (this.isTopClick = false)
      if(item.children && item.children.length){
        this.routeChildren = item.children
        this.routes.map(item1=>item1.isactive = false)
        this.routes.forEach(item1=>{
          if(item.id == item1.id) item.isactive = true
        })
      }else{
        this.routeChildren = []
        this.routes.map(item1=>item1.isactive = false)
        // this.$router.push({
        //   path:item.data.content.replace('/#','')
        // }).catch((error)=>{})
       
        if(this.isTopClick) return 
        this.isSoftware(item)
        this.isopen = !this.isopen
        !boolean &&(this.isactiveLeft = !this.isactiveLeft) 
      }
    },
    isSoftware(route){
      if(route && route.data && route.data.content){
        if(new RegExp("^/pages.*$").test(route.data.content) || new RegExp("^http.*$").test(route.data.content) ) {
          route.zidingyiMenu = true
        }
      }
      if(route.zidingyiMenu){
        // console.log(route,'======');

        if(route.label == "软件下载"){
          let url = '/swbus/frontapis/user/loadInfo'
          // this.$apis(url, {},'get').then((res)=>{
          //   if(res.code === 200){
          //       // window.location.href = '/api/swbus/frontapis/nexus/login?url='+e.target.getAttribute('href')+'&account='+res.data.account
          //       window.location.href = '/api/swbus/frontapis/nexus/login?url='+route.data.content.replace('/#','')+'&account='+res.data.account
          //     }else{
          //           // window.location.href = e.target.getAttribute('href')
          //           // this.$router.push(route)
          //           window.location.href = route.data.content
          //     }
          // })
        }else{
          this.$fun.swLogSiteView({ pageUrl:route.data.content, pageName: route.label }).then((res) => {
            window.location.href = route.data.content
          })
        }
      }else{
          this.$router.push(route.data.content.replace('/#',''))
      }

      
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  height: 2.56rem /* 80px -> 2.56rem */;
  position: relative;
  z-index: 500;
  background: #ffffff;
  padding: 0 .96rem /* 30px -> .96rem */;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  .top{
    height: 2.56rem /* 80px -> 2.56rem */;
    & img:first-child{
      width: .96rem /* 30px -> .96rem */;
      // height: .96rem /* 30px -> .96rem */;
      object-fit: cover;
    }
    // & img:nth-child(2){
    //   width: 3.2rem /* 100px -> 3.2rem */;
    //   height: 1.28rem /* 40px -> 1.28rem */;
    // }
  
    & img:last-child{
        width: .96rem /* 30px -> .96rem */;
        height: .96rem /* 30px -> .96rem */;
    }
    div{
     
      & img:first-child{
        margin-right: .96rem /* 30px -> .96rem */;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div:nth-child(2){
      display: flex;
      align-items: center;
    }
    & > div:nth-child(2) img{
      height: 1.28rem /* 40px -> 1.28rem */;
      width: auto;
      margin-right: 0;
    }
  }
  .bottom{
    position: absolute;
    top: 2.56rem /* 80px -> 2.56rem */;
    .left{
      float: left;
      width: 6.4rem /* 200px -> 6.4rem */;
      margin-left: -0.96rem /* -30px -> -0.96rem */;
      background: #F8F8F8;
      font-size: .832rem /* 26px -> .832rem */;
      div{
        // padding-left: .96rem /* 30px -> .96rem */;
        text-align: center;
        height: 3.2rem /* 100px -> 3.2rem */;
        line-height: 3.2rem /* 100px -> 3.2rem */;
      }
    }
    .right{ 
      float: left;
      // width: 9.6rem /* 300px -> 9.6rem */;
      background: #FFFFFF;
      font-size: .768rem /* 24px -> .768rem */;
      div{
        padding-left: 1.28rem /* 40px -> 1.28rem */;
        margin-top: 1.28rem /* 40px -> 1.28rem */;
        width: 9.6rem!important;;
      }
    }
  }
  .backRed {
    background: #FFFFFF;
    color: #0055FF;
  }
  .isactiveLeft{
    height:calc(100vh - 2.56rem)!important;
    
  }
  .heightNo{
    height: 0!important;
  }
  .widthRem{
    width: 9.6rem!important;
    opacity: 1!important;
    height: calc(100vh - 2.56rem)!important;
  }
  .imgShark1{
    transition:all 0.5s;
  }
}
.rota0{
  transform: rotate(0);
}
.rota360{
  transform: rotate(360deg);
}
.isLogin{
  width: 1.28rem /* 40px -> 1.28rem */!important;
  height: 1.28rem /* 40px -> 1.28rem */!important;
}
.imgs{
  display: flex;
  align-items: center;
}
.isactive{
  color: #0055FF;
}
.headTitle{
  margin-left: .64rem /* 20px -> .64rem */;
  font-size: .896rem /* 28px -> .896rem */;
  white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
}
</style>
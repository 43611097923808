<template>
  <div class="content">
    
    <div class="blockTop">
      <!-- <el-carousel height="16rem" arrow="always" :autoplay="true" indicator-position="none">
        <el-carousel-item v-for="(item, index) in blockActiveGroupList" :key="'item' + index" >
          <div class="contet">
            <el-image class="ws-img" :src="item.imgUrl || require('@/assets/mimgs/imgs/autoBg.png')" fit="cover" style="cursor: pointer">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" style="font-size: 200px" />
              </div>
            </el-image>
            <div class="bottom" v-if="item.titlePosition == '00'">
              <div><img src="@/assets/mimgs/imgs/topSw.png" alt=""></div>
              <div>{{ item && item.name ? item.name : ''  }}</div>
              <div v-if="clickSwCmsSlideshow(item)"><el-button type="primary" round size="mini" @click="clickSwCmsSlide(item)">{{ item.buttonDes }}</el-button></div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel> -->
      <van-swipe >
      <van-swipe-item v-for="(item, index) in blockActiveGroupList" :key="'item' + index" >
        <div class="contetSwiper">
            <el-image class="ws-img" :src="item.imgUrl || require('@/assets/mimgs/imgs/autoBg.png')" fit="cover" style="cursor: pointer">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline" style="font-size: 200px" />
              </div>
            </el-image>
            <div class="bottom" v-if="item.titlePosition == '00'">
              <div><img src="@/assets/mimgs/imgs/topSw.png" alt=""></div>
              <div>{{ item && item.name ? item.name : ''  }}</div>
              <div v-if="clickSwCmsSlideshow(item)"><el-button type="primary" round size="mini" @click="clickSwCmsSlide(item)">{{ item.buttonDes }}</el-button></div>
            </div>
            <div style="position: absolute;z-index: 2;top: 30%;text-align: left;left: .96rem /* 30px -> .96rem */;" class="content">
                <div class="top">
                     {{
                        item && item.name ? item.name : ''
                      }}
                </div>
                <div class="bottom">
                  {{
                      item &&
                      item.remark ? item.remark :
                        ''
                    }}
                </div>
              </div>
          </div>
      </van-swipe-item>
    </van-swipe>
    </div>
    <!-- <div class="someTitles">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide  v-for="(item,index) in blockActiveList" :key="index" >
          <img :src="item.imgUrl ||require('@/assets/mimgs/imgs/wsDynamicState.png')" alt="" />
          <div>{{ item.name }}</div>  
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" v-if="blockActiveList.length>5"></div>
      <div class="swiper-button-next" v-if="blockActiveList.length>5"></div>
    </div> -->
    <!-- <div class="threeTitles">
      <div class="item">
        <img src="@/assets/bl/Dynamic.png" alt="">
        <div class="content">
          <div class="title">
            申威动态
          </div>
          <div class="introduce">
            申威动态
          </div>
        </div>
      </div>
    </div> -->

    <div class="logscroll">
      <!-- <van-notice-bar color="#000" scrollable  background="#fff" left-icon="volume-o">
        <span style="margin-right: .64rem /* 20px -> .64rem */;" v-for="(item,index) in logList" :key="item.id">{{ index+1 }}： {{ item.softwareMsg }}</span>
      </van-notice-bar> -->
      <van-notice-bar left-icon="volume-o" color="#000" background="#fff" :scrollable="false">
        <van-swipe
          vertical
          class="softwarelog-swipe"
          :autoplay="5000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item,index) in logList" :key="item.id" @click="$nav('/ecology/basicsoftware/detail', { id: item.softwareId })">{{ item.softwareMsg }}</van-swipe-item>

        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="block-container">
          <swiper :options="swiperOption1" style="height: 100%">
            <swiper-slide v-for="blockActive  in blockActiveList" :key="blockActive.id">
              <div class="block-item" @click="handleClickBlock(blockActive)">
                <div class="block-border">
                  <img class="block-img" :src="blockActive.imgUrl" alt="pic">
                  <div class="block-title">
                    <div>{{ blockActive.name }}</div>
                    <div>{{ blockActive.intruduce }}</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div
            style="width: 2px;height: 100%;background-color: #FFFFFF;position: absolute;right: 0; top: 0;z-index: 2"></div>
          <div class="swiper-pagination"></div>
        </div>
    <!-- <div class="missionHall">
      <div class="title">
        <span>任务大厅</span>
      </div>
      <div class="img">
        <div>
          <div>领取|科研任务|星火计划|任务悬赏|</div>
          <div>
            查看更多
            <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="block">
        <el-carousel height="13.024rem" :autoplay="false" indicator-position="outside">
          <el-carousel-item v-for="item in 4" :key="item">
            <div class="btn">科研任务</div>
            <div class="title">申威境下安装weblogic报“can't connect to x11 window server using ':0' as the ssof sssssssssssssssssssssssst value</div>
            <div class="divider">
              <el-divider></el-divider>
              <div class="time">发布时间：2023-05-10 21:59</div>
              <div class="time">截止时间：2023-05-25 00:00</div>
              <div class="bottom">环境下安装weblogic报“can't connect to x11 window server using ':0' as the value of the DISPLAY variable”...</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div> -->
    <div class="activityDynamics">
      <div class="title">
        <span>活动动态</span>
      </div>
      <div class="block">
        <el-card class="box-card">
          <div>
            <div class="titleCard">
              <img src="@/assets/bl/DynamicState.png" alt="" />
              <span>申威动态</span>
            </div>
            <div class="item" v-for="(item,index) in newsList2" :key="index" @click="$nav('/activityCenter/news/detail', { 'id': item.id })">
              <div class="left">
                <!-- <img :src="newsLastOne && newsLastOne.picUrl || require('@/assets/mimgs/imgs/asyncLeft.png') " alt="" /> -->
                <img :src="item && item.picUrl || require('@/assets/mimgs/imgs/asyncLeft.png') " alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="time">发布时间：{{ item.createTime }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="item">
              <div class="left">
                <img src="@/assets/mimgs/imgs/asyncLeft.png" alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">技术优势“一骑绝尘”，「华浦电子」鲲鹏KP200重磅发布</div>
                  <div class="time">发布时间：2023-05-10 21:59</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <img src="@/assets/mimgs/imgs/asyncLeft.png" alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">技术优势“一骑绝尘”，「华浦电子」鲲鹏KP200重磅发布</div>
                  <div class="time">发布时间：2023-05-10 21:59</div>
                </div>
              </div>
            </div> -->
            <div class="seeAll" @click="$nav('/activityCenter/news')">
              <span>查看全部</span>
              <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
            </div>
          </div>
        </el-card>
        <el-card class="box-card box-card1">
          <div>
            <div class="titleCard">
              <img src="@/assets/bl/battery.png" alt="" />
              <span>申威活动</span>
            </div>
            <div class="item" v-for="(item,index) in cmsActivityTopList" :key="index" @click="$nav('/activityCenter/activity/detail', { 'id': item.id })"> 
              <div class="left">
                <img :src=" item.cover ||require('@/assets/mimgs/imgs/asyncLeft.png')" alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="time">发布时间：{{ formatDateBy(item.createTime, "yyyy-MM-dd") }} 截止时间：{{
                          formatDateBy(item.runEndTime,
                            "yyyy-MM-dd")
                        }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="item">
              <div class="left">
                <img src="@/assets/mimgs/imgs/asyncLeft.png" alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">技术优势“一骑绝尘”，「华浦电子」鲲鹏KP200重磅发布</div>
                  <div class="time">发布时间：2023-05-10 21:59</div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <img src="@/assets/mimgs/imgs/asyncLeft.png" alt="" />
              </div>
              <div class="right">
                <div class="content">
                  <div class="title">技术优势“一骑绝尘”，「华浦电子」鲲鹏KP200重磅发布</div>
                  <div class="time">发布时间：2023-05-10 21:59</div>
                </div>
              </div>
            </div> -->
            <div class="seeAll"  @click="$nav('/activityCenter/activity')">
              <span>查看全部</span>
              <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="developerColumn">
      <div class="title">
        <span>开发者专栏</span>
      </div>
      <div class="content" v-for="(item,index) in blogsList" :key="index">
        <div class="top">
          <span>{{ item.name }}</span>
          <span @click="$nav('/resource/article')">
            <span>查看全部</span>
            <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
          </span>
        </div>
        <div class="img">
          <img :src="item.cover ||require('@/assets/mimgs/imgs/developerColumn.png')" alt="" />
        </div>
        <div class="padding20px">
          <div class="item" v-for="(item1,index1) in item.articles" :key="index1"
          @click="$nav('/resource/article/detail', { id: item1.id, name: item1.name, roleName: item1.roleName })">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" style="float: left;"/>
              <span class="title1">{{ item1.title }}</span>
            </div>
            <div>
              <span style="margin-left: 0;">发布作者：{{ item1.name }}</span>
              <span>发布时间：{{ item1.createTime }}</span>
            </div>
          </div>
          <!-- <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="content">
        <div class="top">
          <span>芯片</span>
          <span>
            <span>查看全部</span>
            <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
          </span>
        </div>
        <div class="img">
          <img src="@/assets/mimgs/imgs/developerColumn.png" alt="" />
        </div>
        <div class="padding20px">
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="top">
          <span>芯片</span>
          <span>
            <span>查看全部</span>
            <img src="@/assets/mimgs/imgs/rightArrow.png" alt="" />
          </span>
        </div>
        <div class="img">
          <img src="@/assets/mimgs/imgs/developerColumn.png" alt="" />
        </div>
        <div class="padding20px">
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
          <div class="item">
            <div>
              <img src="@/assets/mimgs/imgs/circle.png" alt="" />
              <span>DataFun实时数据湖专题：申威在实时数据湖上的探索及...</span>
            </div>
            <div>
              <span>发布作者：张小萌</span>
              <span>发布时间：2023-08-10 21:59</span>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <div class="bottom">
      <div class="title">
        <!-- <span>开发者专栏</span> -->
        <span>文档资料</span>
        <span @click="$nav('/resource/document')">
          <span style="color: rgb(2, 86, 255);">查看全部</span>
          <img src="@/assets/mimgs/imgs/rightArrow.png" alt=""/>
        </span>
      </div>
      <div class="content">
        <div class="item" v-for="(item,index) in bottomList" :key="index">
          <div  @click="jumpWd(item)">
            <div class="left">
              <!-- <img src="@/assets/mimgs/imgs/leftbook.png" alt="" /> -->
              <img :src="item.picUrl" alt="" />
            </div>
            <div class="right">
              <div>
                <div class="rightTitle">{{ item.name }}</div>
                <div class="time">更新时间：{{ item.updateTime }}</div>
                <div class="bottom">
                  <div>浏览量：{{ item.viewCount }}</div>
                  <div class="rete">
                    <span>评分：</span>
                    <el-rate v-model="item.score" :disabled="true"></el-rate>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- <div class="item">
          <div class="left">
            <img src="@/assets/mimgs/imgs/leftbook.png" alt="" />
          </div>
          <div class="right">
            <div>
              <div class="rightTitle">申威Chromium编译使用指南 v1.0</div>
              <div class="time">更新时间：023-07-12 15:32:06</div>
              <div class="bottom">
                <div>浏览量：187</div>
                <div class="rete">
                  <span>评分：</span>
                  <el-rate v-model="value1"></el-rate>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="@/assets/mimgs/imgs/leftbook.png" alt="" />
          </div>
          <div class="right">
            <div>
              <div class="rightTitle">申威Chromium编译使用指南 v1.0</div>
              <div class="time">更新时间：023-07-12 15:32:06</div>
              <div class="bottom">
                <div>浏览量：187</div>
                <div class="rete">
                  <span>评分：</span>
                  <el-rate v-model="value1"></el-rate>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import {
  topImgData,
  cmsBlockActiveList,
  findCmsNewsLastList,
  findCmsActivityTopList,
  findHomeBlogArticle,
  swCmsDocumentList
} from '@/requires/index'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.css"
export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return {
      logList:[],
      blockActiveGroupList:[1,2,3],
      blockActiveList:[],
      newsList2:[],
      cmsActivityTopList:[],
      blogsList:[],
      newsLastOne:'',
      value1:0,
      swiperOption: { 
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 1,
        loopFillGroupWithBlank: true,
        direction: 'horizontal',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // on: {
        //   resize: () => {
        //     this.$refs.swiper.$swiper.changeDirection(
        //       window.innerWidth <= 960
        //         ? 'vertical'
        //         : 'horizontal'
        //     )
        //   }
        // }
      },
      swiperOption1: {
        // slidesPerView: 4,
        slidesPerView: 3,
        slidesPerGroup: 1,
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        on: {
          resize: () => {
            // this.$refs.swiper.$swiper.changeDirection(
            //   window.innerWidth <= 960
            //     ? 'vertical'
            //     : 'horizontal'
            // )
          }
        }
      },
      bottomList:[]
    }
  },
  created(){
    this.init()
  },
  mounted(){
  },
  methods:{
    softWareVerSearchList() {
      //swbus/frontapis/swCmsSlide/findCmsSlideActiveList
      let data = {}
      let url = '/swbus/frontapis/swSoftwareVer/softWareVerSearch' //+ this.$format.handleQuery(query)
      this.$apis(url, data, 'get')
        .then((res) => {
          this.logList = res
        })
        .catch((error) => {
          this.logList = ['暂无版本更新']
        })
    },
    formatDateBy(date, format) {
      if (!date) {
        return ''
      }
      return this.$date.dateFormatBy(date, format)
    },
    jumpWd(item) {
      if (item.type == 1) {
        this.$router.push({path: '/resource/document/wikiDetail', query: {docNo: item.documentNo, docName: item.name, url: item.url}})
      } else if (item.type == 0) {
        let pdfLink = '/pdf/web/viewer.html?docId=' + item.documentNo
        window.open(pdfLink)

      } else if (item.type == 2) {
        this.$fun.swLogDocView(item, this).then((res) => {
          const blob = new Blob([res], {type: 'application/zip'})
          const blobUrl = window.URL.createObjectURL(blob)
          // const content = blobUrl.getResponseHeader('Content-Disposition');
          // const fileName = content && content.split(';')[1].split('filename=')[1];
          console.log(blob)


          window.open(blobUrl)
        })
      }

    },
    handleClickBlock(item) {
      console.log('handleClickBlock', item, item.linkUrl)
      // this.$fun.swLogBlockView(item)
      if (item && item.state == '01') {
        if (item && item.linkUrl) {
          let arr = item.linkUrl.split('#')
          this.$nav(arr[1])
          // window.open(item.linkUrl, "_blank")

        }
      }

      // if (item.name == '问题反馈') {
      //   this.$nav('fwzc/yjfkxx')
      // }
    },
    init(){
      this.getTopImg()
      this.getCmsBlockActiveList()
      this.getfindCmsNewsLastList()
      this.getfindCmsActivityTopList()
      this.getfindHomeBlogArticle()
      this.getBottomData()
      this.softWareVerSearchList()
    },
    getTopImg(){
      topImgData().then(res=>{
        this.blockActiveGroupList = res.data
      })
    },
    getCmsBlockActiveList(){
      cmsBlockActiveList().then(res=>{
        this.blockActiveList = res.data && res.data.filter(item=>item.dataType == 0)
        // this.blockActiveList = res.data
      })
    },
    clickSwCmsSlideshow(item) {
      let p = false
      if (item.slideType == '01') {
        if (item.jumpType == '01' && item.jumpUrl) {
          p = true
        }
      } else if (item.slideType == '02') {
        if (item && item.slideId) {
          p = true
        }
      } else if (item.slideType == '03') {
        if (item && item.slideId) {
          p = true
        }
      } else {

      }
      return p
    },
    getfindCmsActivityTopList() {
      // let data = {}
      // let url = '/swbus/frontapis/swCmsActivity/findCmsActivityTopList'
      // this.$apis(url, data, 'get').then(res => {
      //   this.cmsActivityTopList = res.data
      // })
      //   .catch((error) => {
      //     this.cmsActivityTopList = []
      //   });
      findCmsActivityTopList().then(res=>{
        this.cmsActivityTopList = res.data
      })
    },
    getfindHomeBlogArticle(){
      findHomeBlogArticle().then(res=>{
        this.blogsList = res.data
      })
    },
    getfindCmsNewsLastList(){
      findCmsNewsLastList().then(res=>{
        // this.findCmsNewsLastList = 
        let dataList = res.data
        if (dataList.length > 0) {
          this.newsLastOne = dataList[0]
          let list2 = []
          for (let i = 1; i < dataList.length; i++) {
            let item = dataList[i]
            if (item) {
              list2.push(item)
            }
          }
          this.newsList2 = list2
        }
      })
    },
    getBottomData(){
      let data = {
        showHome: 1,
        type: 1
      }
      swCmsDocumentList(data).then(res=>{
        this.bottomList = res.data.records
      })
    }
  }
};
</script>

<style  scoped lang="scss">
.content {
  // background: rgba(255, 255, 255, 1);
  .blockTop {
    // height: 16rem /* 500px -> 16rem */;
    overflow: hidden;
    .contet{
      position: relative;
     .bottom{
      position: absolute;
      top: 3.2rem /* 100px -> 3.2rem */;
      left: 7.776rem /* 243px -> 7.776rem */;
      z-index: 9999;
      font-size: 1.024rem /* 32px -> 1.024rem */;
      text-align: center;
      img{
        width: 5.6rem /* 175px -> 5.6rem */;
        height: 2.24rem /* 70px -> 2.24rem */;
      }
      div:nth-child(n+2){
        margin-top: .96rem /* 30px -> .96rem */;
      }
     }
    }
  }

  .someTitles { 
    position: relative;
    overflow-x: auto;
    display: flex;
    justify-content: space-around;
    padding: 0.96rem /* 30px -> .96rem */ 0 1.28rem /* 40px -> 1.28rem */ 0;
    img {
      width: 3.2rem /* 100px -> 3.2rem */;
      height: 2.56rem /* 80px -> 2.56rem */;
    }
    div div {
      margin-top: 0.64rem /* 20px -> .64rem */;
      font-size: 0.768rem /* 24px -> .768rem */;
    }
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    .swiper-button-prev::after{
      font-size: 20px;
    }
    .swiper-button-next::after{
      font-size: 20px;
    }
  }
  .missionHall {
    margin-top: 1.92rem /* 60px -> 1.92rem */;
    .title {
      font-size: 1.024rem /* 32px -> 1.024rem */;
      text-align: center;
      color: #000000;
      span {
        display: inline-block;
        line-height: 0.48rem /* 15px -> .48rem */;
        border-bottom: 0.256rem /* 8px -> .256rem */ solid rgba(2, 86, 255, 0.5);
      }
    }
    .img {
      width: 22.144rem /* 692px -> 22.144rem */;
      height: 6.464rem /* 202px -> 6.464rem */;
      margin: 0 auto;
      border-radius: 10px;
      background: url(../assets/mimgs/imgs/missionHall.png) no-repeat;
      background-position: 100% 100%;
      background-size: cover;
      overflow: hidden;
      margin-top: 1.216rem /* 38px -> 1.216rem */;
      div div:first-child {
        font-size: 0.832rem /* 26px -> .832rem */;
        margin-top: 1.856rem /* 58px -> 1.856rem */;
        margin-left: 0.96rem /* 30px -> .96rem */;
      }
      div div:last-child {
        color: #0256ff;
        font-size: 0.64rem /* 20px -> .64rem */;
        margin-top: 0.64rem /* 20px -> .64rem */;
        margin-left: 0.96rem /* 30px -> .96rem */;
        img {
          width: 0.384rem /* 12px -> .384rem */;
          height: 0.64rem /* 20px -> .64rem */;
          vertical-align: middle;
        }
      }
    }
    .block {
      width: 22.08rem /* 690px -> 22.08rem */;
      margin: 0 auto;
      .btn {
        border-radius: 1.28rem /* 40px -> 1.28rem */;
        background: rgba(2, 86, 255, 0.102);
        color: rgba(93, 147, 255, 1);
        font-size: 0.64rem /* 20px -> .64rem */;
        padding: 0.192rem /* 6px -> .192rem */ 0.64rem /* 20px -> .64rem */ 0.192rem /* 6px -> .192rem */ 0.64rem /* 20px -> .64rem */;
        text-align: center;
        line-height: normal;
      }
      .title {
        font-size: 0.896rem /* 28px -> .896rem */;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        overflow: hidden;
        color: #000000;
        line-height: normal;
      }
      .divider {
        margin: 0.64rem /* 20px -> .64rem */ auto;
      }
      .time {
        font-size: 0.832rem /* 26px -> .832rem */;
        color: #898989;
      }
      .bottom {
        margin-top: 0.64rem /* 20px -> .64rem */;
        color: #898989;
        font-size: 0.832rem /* 26px -> .832rem */;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: left;
        overflow: hidden;
      }
      ::v-deep .el-carousel__indicator .el-carousel__button {
        // background: #e8e8e8;
        background: gray;
      }
      ::v-deep .is-active .el-carousel__button {
        background: #0055ff !important;
      }
    }
  }
  .activityDynamics { 
    margin-top: 1.92rem /* 60px -> 1.92rem */;
    .title {
      font-size: 1.024rem /* 32px -> 1.024rem */;
      text-align: center;
      color: #000000;
      span {
        display: inline-block;
        line-height: 0.48rem /* 15px -> .48rem */;
        border-bottom: 0.256rem /* 8px -> .256rem */ solid rgba(2, 86, 255, 0.5);
      }
    }
    .block {
      width: 22.08rem /* 690px -> 22.08rem */;
      margin: 0 auto;
      margin-top: 1.216rem /* 38px -> 1.216rem */;
      ::v-deep .el-card__body {
        padding: 0.64rem /* 20px -> .64rem */;
      }
      .box-card {
        .titleCard {
          display: flex;
          align-items: center;
          img {
            // width: 0.96rem /* 30px -> .96rem */;
            // height: 0.864rem /* 27px -> .864rem */;
            width:.96rem /* 30px -> .96rem */;
            height: .96rem /* 30px -> .96rem */;
            vertical-align: middle;
          }
          span {
            font-size: 1.024rem /* 32px -> 1.024rem */;
            margin-left: 0.32rem /* 10px -> .32rem */;
          }
        }
        .item {
          margin-top: 0.64rem /* 20px -> .64rem */;
          overflow: hidden;

          .left {
            float: left;
            img {
              width: 6.464rem /* 202px -> 6.464rem */;
              height: 5.184rem /* 162px -> 5.184rem */;
            }
          }
          .right {
            float: right;
            width: 13.76rem /* 430px -> 13.76rem */;
            .content {
              position: relative;
              height: 5.184rem;
              .title {
                font-size: 0.768rem /* 24px -> .768rem */;
                text-align: left;
              }
              .time {
                position: absolute;
                bottom: 0;
                font-size: 0.64rem /* 20px -> .64rem */;
                color: rgba(137, 137, 137, 1);
              }
            }
          }
        }
        .seeAll {
          font-size: 0.64rem /* 20px -> .64rem */;
          color: rgba(2, 86, 255, 1);
          margin-top: 0.96rem /* 30px -> .96rem */;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 0.384rem /* 12px -> .384rem */;
            height: 0.64rem /* 20px -> .64rem */;
            margin-left: 0.32rem /* 10px -> .32rem */;
          }
        }
      }
      .box-card1 {
        margin-top: 0.64rem /* 20px -> .64rem */;
      }
    }
  }
  .developerColumn {
    margin-top: 1.92rem /* 60px -> 1.92rem */;
    .title {
      font-size: 1.024rem /* 32px -> 1.024rem */;
      text-align: center;
      color: #000000;
      span {
        display: inline-block;
        line-height: 0.48rem /* 15px -> .48rem */;
        border-bottom: 0.256rem /* 8px -> .256rem */ solid rgba(2, 86, 255, 0.5);
      }
    }
    .padding20px {
      padding: 0.64rem /* 20px -> .64rem */ 0.64rem /* 20px -> .64rem */;
      .item{
        >div:first-child span:nth-child(2){
         
        }
      }
    }
    .content { 
      width: 22.08rem /* 690px -> 22.08rem */;
      margin: 0 auto;
      margin-top: 1.216rem /* 38px -> 1.216rem */;
      border-radius: 0.32rem /* 10px -> .32rem */;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      background: #ffff;
      .top {
        border-left: 0.32rem /* 10px -> .32rem */ solid rgba(2, 86, 255, 1);
        height: 3.2rem /* 100px -> 3.2rem */;
        background: rgba(2, 86, 255, 0.05);
        box-sizing: border-box;
        padding: 0 0.64rem /* 20px -> .64rem */ 0 1.28rem;
        // font-size: 18px;
        font-size: .576rem /* 18px -> .576rem */;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span:first-child {
          font-size: 1.024rem /* 32px -> 1.024rem */;
        }
        > span:last-child {
          float: right;
          display: flex;
         align-items: center;
          span {
            margin-right: 0.32rem /* 10px -> .32rem */;
            font-size: 0.64rem /* 20px -> .64rem */;
            color: rgba(2, 86, 255, 1);
           
          }
          img {
            width: 0.384rem /* 12px -> .384rem */;
            height: 0.64rem /* 20px -> .64rem */;
          }
        }
      }
      .img {
        img {
          height: 9.6rem /* 300px -> 9.6rem */;
          width: 100%;
        }
      }
      .item {
        
        margin-top: 0.96rem /* 30px -> .96rem */;
        & div:first-child {
          font-size: 0.768rem /* 24px -> .768rem */;
          display: flex;
          align-items: center;

          img {
            width: 0.64rem /* 20px -> .64rem */;
            height: 0.64rem /* 20px -> .64rem */;
            vertical-align: bottom;
          }
          span {
            margin-left: .64rem /* 20px -> .64rem */;
          }
        }
        & div:last-child {
          font-size: 0.64rem /* 20px -> .64rem */;
          color: rgba(137, 137, 137, 1);
          margin-top: 0.64rem /* 20px -> .64rem */;
          & span:first-child {
            margin-left: 0.96rem /* 30px -> .96rem */;
          }
          & span:last-child {
            float: right;
          }
        }
      }
    }
    .title1{
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .bottom {
    margin-top: 1.92rem /* 60px -> 1.92rem */;
    .title {
      font-size: 1.024rem /* 32px -> 1.024rem */;
      text-align: center;
      color: #000000;
      > span:first-child {
        display: inline-block;
        margin-left: 2.56rem /* 80px -> 2.56rem */;
        line-height: 0.48rem /* 15px -> .48rem */;
        border-bottom: 0.256rem /* 8px -> .256rem */ solid rgba(2, 86, 255, 0.5);
      }
      > span:last-child {
        float: right;
        display: flex;
        align-items: center;
        margin-right: 1.28rem /* 40px -> 1.28rem */;
        font-size: 0.64rem /* 20px -> .64rem */;
        span {
          margin-right: 0.32rem /* 10px -> .32rem */;
        }
        img {
          width: 0.384rem /* 12px -> .384rem */;
          height: 0.64rem /* 20px -> .64rem */;
        }
      }
    }
    .content {
      box-sizing: border-box;
      padding: 0.32rem /* 10px -> .32rem */;
      display: -webkit-box;
      overflow-x: auto;
      white-space: nowrap;
      margin-top: 0.96rem /* 30px -> .96rem */;
      .item {
        background: rgba(255, 255, 255, 1);
        width: 16.96rem /* 530px -> 16.96rem */;
        height: 9.92rem /* 310px -> 9.92rem */;
        display: inline-block;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
        margin-left: 0.96rem /* 30px -> .96rem */;
        padding: .32rem /* 10px -> .32rem */;
        .left {
          float: left;
          img {
            width: 5.888rem /* 184px -> 5.888rem */;
            height: 9.28rem /* 290px -> 9.28rem */;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
          
          }
        }
        .right {
          float: right;
          width: 9.6rem /* 300px -> 9.6rem */;
          .rightTitle {
            font-size: 0.768rem /* 24px -> .768rem */;
            // white-space: wrap!important;
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
          }
          > div {
            position: relative;
            height: 9.28rem /* 290px -> 9.28rem */;
          }
          .time {
            color: rgba(137, 137, 137, 1);
            font-size: 0.64rem /* 20px -> .64rem */;
            margin-top: 0.96rem /* 30px -> .96rem */;
          }
          .bottom {
            position: absolute;
            bottom: 0;
            color: rgba(137, 137, 137, 1);
            font-size: 0.64rem /* 20px -> .64rem */;
            div:first-child {
              margin-bottom: 0.32rem /* 10px -> .32rem */;
            }
            div:last-child {
              height: 0.512rem /* 16px -> .512rem */;
              span {
                float: left;
              }
            }
            ::v-deep .el-rate {
              float: left;
            }
            ::v-deep .el-rate__icon {
              font-size: 0.8rem /* 25px -> .8rem */;
              margin-top: 0.16rem /* 5px -> .16rem */;
            }
          }
        }
      }
    }
  }
}
.contetSwiper{
  position: relative;
  // height: 12.8rem /* 400px -> 12.8rem */;
  height: 9.6rem /* 300px -> 9.6rem */;
 ::v-deep img{
    // height: 12.8rem!important /* 400px -> 12.8rem */;
    height: 9.6rem /* 300px -> 9.6rem */;
  }
  .content{
    .top{
      font-size: 18px;
      color: white;
    }
    .bottom{
      color: rgb(237, 251, 254);
      font-size: 14px;
      margin-top: .64rem /* 20px -> .64rem */;
    }
  }
}
.threeTitles{
  width: 22.08rem /* 690px -> 22.08rem */;
  height: 3.84rem /* 120px -> 3.84rem */;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: .96rem /* 30px -> .96rem */;
  .item{
    img{
      height: 1.92rem /* 60px -> 1.92rem */;
      object-fit: cover;
    }

  }
}
.logscroll{
  width: 22.08rem /* 690px -> 22.08rem */;
  margin: 1.6rem /* 50px -> 1.6rem */ auto 0 auto;
}
.block-container {
  // width: 1420px;
  width: 22.08rem /* 690px -> 22.08rem */;
  height: 3.84rem /* 120px -> 3.84rem */;
  // margin: 50px auto 0 auto;
  margin: .64rem /* 20px -> .64rem */ auto 0 auto;
  position: relative;
  background-color: #FFFFFF;

  .block-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .block-border {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #DCDFE6;
    width: 100%;
  }

  .block-img {
    // width: 45px;
    // height: 45px;
    height: 1.92rem /* 60px -> 1.92rem */;
    object-fit: cover;
    // margin-right: 27px;
    margin-right: .64rem /* 20px -> .64rem */;
  }

  .block-title {
    // width: 200px;
    width: 3.52rem /* 110px -> 3.52rem */;
    color: #333333;

    > div:nth-child(1) {
      // font-size: 20px;
      font-size: .64rem /* 20px -> .64rem */;
      font-weight: bold;
      // margin-bottom: 5px;
      margin-bottom: .16rem /* 5px -> .16rem */;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > div:nth-child(2) {
      // font-size: 15px;
      font-size: .448rem /* 14px -> .448rem */;
      color: #626773;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      color: #409EFF;
    }

  }
}
::v-deep .el-icon-arrow-left,
::v-deep .el-icon-arrow-right {
  font-size: 1.12rem /* 35px -> 1.12rem */;
  color: #ffffff;
}
::v-deep .el-divider {
  background-color: #dcdfe6;
}
::v-deep .el-carousel__indicators--horizontal{
  left: 49%;
}
.content::-webkit-scrollbar {
		display: none!important;;
	}
  .swiper-pagination {
  position: static;
}

::v-deep .swiper-pagination-bullet {
  margin: 0 5px;
}
::v-deep .swiper-pagination-clickable{
  font-size: 16px;
}
</style>
<style>
  .softwarelog-swipe {
    height: 40px;
    line-height: 40px;
    font-size: .768rem /* 24px -> .768rem */;
  }
</style>
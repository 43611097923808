import JSEncrypt from 'jsencrypt'
const RSA_PUBLIC_KEY = "-----BEGIN PUBLIC KEY-----" +
                        "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhjmgLxD0lvfShtcJeDq4iz8f5swNnE6vwLu21QBXv7z0VQZR5M5dApSTHgJYkCf8fbKpeRBt258puojJ9vLN57WIETeEeV5VWmYvgmHZMmeeR6H1j7z0+9fgtOb0Fm622vHhU81LrBHOZpccnCkwDXjgBtkGO4WNJugshlCZ2zVxeGtM6wyMpRZHoWGImZlGeqpONhHVA+FHsf/8/uo2agzlcPHphPhomY5sk4DiFE72Lln5k1fGfbdIRCQA9ksh285PgwzViL8gxxoqZbKu4gMtyY00jM7BgVPWqTDv/Sb5hqU1hbgtvyLyRjlCGDpyTO43B1rOSRueijdG0mjpjQIDAQAB" + 
                        "-----END PUBLIC KEY-----" +
                        "";
const RSA_PRIVATE_KEY = "-----BEGIN RSA PRIVATE KEY-----" + 
                        "" + 
                        "-----END RSA PRIVATE KEY-----" +
                        "";

export function rsaEncrypt(str) {
    let encryptor = new JSEncrypt()  // 创建加密对象实例
    //之前ssl生成的公钥，复制的时候要小心不要有空格
    encryptor.setPublicKey(RSA_PUBLIC_KEY)//设置公钥
    var rsaPassWord = encryptor.encrypt(str)  // 对内容进行加密
    return rsaPassWord
}

export function rsaDecrypt(str){
    let decrypt = new JSEncrypt()//创建解密对象实例
    //之前ssl生成的秘钥
    decrypt.setPrivateKey(RSA_PRIVATE_KEY)//设置秘钥
    var uncrypted = decrypt.decrypt(str)//解密之前拿公钥加密的内容
    return uncrypted
}
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/mflexible";
import "element-ui/lib/theme-chalk/index.css";
import "./utils/element";
import Vant from "vant";
import "vant/lib/index.css";
import "@/assets/styles/index.scss"; // global css
import "./assets/styles/element-variables.scss";
import hljs from "highlight.js";
// 使用样式，有多种样式可选
import "highlight.js/styles/a11y-light.css";
// 增加自定义命令v-highlight
Vue.directive("highlight", function (el) {
  let blocks = el.querySelectorAll("pre code");
  blocks.forEach((block) => {
    hljs.highlightBlock(block);
  });
});
Vue.directive("removeAriaHidden", {
  bind(el, binding) {
    let ariaEls = el.querySelectorAll(".el-radio__original");
    ariaEls.forEach((item) => {
      item.removeAttribute("aria-hidden");
    });
  },
});

Vue.config.productionTip = false;
// UI框架
import {
  Carousel,
  CarouselItem,
  Image,
  Divider,
  Rate,
  Drawer,
  Upload,
  DatePicker,
  InputNumber,
  RadioGroup,
  RadioButton,
} from "element-ui";
import * as components from "@/components";
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Divider);
Vue.use(Rate);
Vue.use(Drawer);
Vue.use(Upload);
Vue.use(DatePicker);
Vue.use(InputNumber);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Vant);
// 公共组件注册
Object.keys(components).forEach((key) => {
  const component = components[key];
  if (component.name) Vue.component(component.name, component);
});
Vue.directive("focus", {
  // 当被绑定的元素插入到 DOM 中时……
  inserted: function (el) {
    // 聚焦元素
    // console.log("aaaa",el.children[0].focus);
    el.focus();
    setTimeout((_) => {
      el.children[0].focus();
    });
  },
  update: function (el) {
    // 聚焦元素
    // console.log("aaaa",el.children[0].focus);
    el.focus();
    setTimeout((_) => {
      el.children[0].focus();
    });
  },
});
//
import * as $format from "./utils/format.js";
import * as $fun from "./utils/fun.js";
import dictData from "./utils/dictData.js";
import { api, apip } from "./api/api.js";
import * as $date from "./utils/date.js";
import { getToken } from "@/utils/auth";
Vue.prototype.$headers = { "sw2-devcom-auth": "Bearer " + getToken() };
window.$getToken = getToken;
Vue.prototype.$uploadUrl = "/api/swbus/frontapis/file/upload";
import modal from "./utils/modal.js";
Vue.prototype.$modal = modal;
Vue.prototype.$date = $date; //日期时间
Vue.prototype.$apis = api; //通用请求 post
Vue.prototype.$apisp = apip; //通用 get parm
Vue.prototype.$format = $format; //方法
Vue.prototype.$fun = $fun; //函数
Vue.prototype.$nav = $fun.nav; //跳转
Vue.prototype.$dictData = dictData; //字典
Vue.prototype.$success = $format.success; //成功消息
Vue.prototype.$error = $format.error; // 错误提示
Vue.prototype.$message = $format; // 错误提示
window.$fun = $fun;
import "xe-utils";
import "./permission"; // permission control
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
Vue.use($fun.directives);

new Vue({
  router,
  store,
  render: (h) => h(App),
  beforeCreate() {
    //安装全局事件总线，$bus就是当前应用的vm
    Vue.prototype.$bus = this;
  },
}).$mount("#app");


let dictData = {
     chanType:[
          { label: '选择已适配产品', value: '01' },
          { label: '添加其他产品', value: '02' },
     ],
     ArticlecheckState: [
          { label: '审核中', value: '01' },
          { label: '审核拒绝', value: '03' },
     ],
      //01 软硬件适配表单 02 技术支持表单 03 产品问题表单
      formType:[
        { label: '软硬件适配需求表单', value: '01' },
        { label: '技术支持表单', value: '02' },
        { label: '产品问题表单', value: '03' },
      ],
      //检索类型 0 标题 1 正文 2 正文及标题
      findType:[
           { label: '标题', value: 0 },
           { label: '正文', value: 1 },
           { label: '正文及标题', value: 2 },
      ],
      searchType:[
           { label: '模糊查询', value: 0 },
           { label: '精确查询', value: 1 },
      ],
      regSearchType:[
        { label: '模糊查询', value: 0 },
        { label: '精确查询', value: 1 },
        { label: '正则查询', value: 2 },
      ],
      types:[
           { label: '基础软件', value: 1 },
           { label: '文档中心', value: 2 },
           { label: '常见问题', value: 3 },
           { label: '开发者专栏', value: 4 },
           { label: '系统仓库软件', value: 5 }
      ],

      //内存大小：改成下拉选4G/8G，单位mb
      memory:[
           { label: '1G', value: 1 },
           { label: '2G', value: 2 },
           { label: '4G', value: 4 },
           { label: '8G', value: 8 },
           { label: '16G', value: 16 },
           { label: '32G', value: 64 },
      ],
      //存储空间：改成下拉选择：10G/20G/30G/40G，单位mb
      storage:[
           { label: '40G', value: 40 },
           { label: '80G', value: 80 },
           { label: '100G', value: 100 },
           { label: '200G', value: 200 },
           { label: '500G', value: 500 },
           { label: '1000G', value: 1000 },
      ],
      //阅读状态(00未读01已读)
      readState:[
           { label: '未读', value: '00' },
           { label: '已读', value: '01' },
      ],
      //消息类别 (01系统消息09其它消息)
      msgType:[
           { label: '系统消息', value: '01' },
           { label: '其它消息', value: '09' },
      ],
      //消息数据类别
      msgDataKey:[
           { label: '活动报名申请', value: '101' , url: '/tzgg/hdxx?id='},
           { label: '活动报名审批通过', value: '102', url: '/tzgg/hdxx?id=' },
           { label: '活动报名审批拒绝', value: '103', url: '/tzgg/hdxx?id=' },
           { label: '基础软件订阅消息', value: '201', url: '/swst/jcrjxx?id=' },
           { label: '基础软件取消订阅消息', value: '202', url: '/swst/jcrjxx?id=' },
           { label: '基础软件新版本', value: '203', url: '/swst/jcrjxx?id=' },
           { label: '适配申请', value: '301', url: '/fwzc/spsq?id=' },
           { label: '适配申请审批通过', value: '302', url: '/fwzc/spsq?id=' },
           { label: '适配申请审批拒绝', value: '303', url: '/fwzc/spsq?id=' },
           { label: '众智计划报名', value: '401', url: '/stxt/rwdtxh?taskId=' },
           { label: '众智计划报名审批通过', value: '402', url: '/stxt/rwdtxh?taskId=' },
           { label: '众智计划报名审批拒绝', value: '403', url: '/stxt/rwdtxh?taskId=' },
           { label: '认证申请', value: '501', url: '/fwzc/sprzck?id=' },
           { label: '认证申请审批通过', value: '502', url: '/fwzc/sprzck?id=' },
           { label: '认证申请审批不通过', value: '503', url: '/fwzc/sprzck?id=' },
           { label: '成果共享申请', value: '601', url: '/stxt/cggxxx?id=' },
           { label: '成果共享审批通过', value: '602', url: '/stxt/cggxxx?id=' },
           { label: '成果共享审批拒绝', value: '603', url: '/stxt/cggxxx?id=' },
      ],
     //操作系统仓库
     xitongcangku:[
          { label: '仓库1', value: '1' },
          { label: '仓库2', value: '2' },
     ],
    //排序
    paixu: [
     //     { label: '按发布时间排序', value: '1' },
     //     { label: '按阅读量排序', value: '2' },
          { label: '按发布时间排序', value: '0' },
          { label: '按阅读量排序', value: '1' },
    ],
    softwareSearch: [
          { label: '标题', value: '0' },
          { label: '介绍', value: '1' },
    ],
    //adapterState	适配状态(00已申请01已审批02适配中03适配成功04适配失败)		false
    adapterState: [
         { label: '已申请', value: '00' },
         { label: '已审批', value: '01' },
         { label: '适配中', value: '02' },
         { label: '适配成功', value: '03' },
         { label: '适配失败', value: '04' },
    ],
    //resourceUsageStatus	资源使用状态(00未使用01使用中02过期03释放)		false
    resourceUsageStatus: [
         { label: '未使用', value: '00' },
         { label: '使用中', value: '01' },
         { label: '过期', value: '02' },
         { label: '释放', value: '03' },
    ],
    //authStatus	认证状态(00未认证01认证中02已认证03认证失败)
    authStatus: [
         { label: '未认证', value: '00' },
         { label: '认证中', value: '01' },
         { label: '已认证', value: '02' },
         { label: '认证失败', value: '03' },
         { label: '认证完成', value: '04' },
    ],
   	//共享类型 01 软件类 02 文档类
    shareType: [
        { label: '软件类', value: '01' },
        { label: '文档类', value: '02' }
    ],

    //资源配置
    resourceAllocation: [
         { label: '容器', value: '01' },
         { label: '虚拟机', value: '02' },
         { label: '物理机', value: '03' },
    ],
    //认证类型(01 个人认证 02 企业认证)
    authType: [
         { label: '企业认证', value: '02' },
         { label: '个人认证', value: '01' },
    ],
    //0 不采纳 1 采
    adoptStatus: [
         { label: '未采纳', value: 0 },
         { label: '已采纳', value: 1 }
    ],

    //发布状态(01待发布02已发布03已完成04已下架)
    pubState: [
         { label: '待发布', value: '01' },
         { label: '已发布', value: '02' },
         { label: '已完成', value: '03' },
         { label: '已下架', value: '04' },
    ],
   //工单状态(00待处理01处理中02处理完成)
   orderState: [
     { label: '未处理', value: '10', color: 'gray'},
     { label: '解决中', value: '20', color: 'black'},
     { label: '暂时无法解决', value: '50', color: 'black'},
     { label: '已解决', value: '60', color: 'black'},
     { label: '已关闭-已解决', value: '70', color: 'black'},
     { label: '已关闭-暂时无法解决', value: '80', color: 'black'},
   ],
   //工单状态(10未处理、20已接收、30已分配、40解决中、50暂时无法解决、60已解决、70已关闭-已解决)
   orderStateNew: [
        { label: '未处理', value: '10', color: 'gray'},
        { label: '已接收', value: '20', color: 'black'},
        { label: '已分配', value: '30', color: 'black'},
        { label: '解决中', value: '40', color: 'black'},
        { label: '暂时无法解决', value: '50', color: 'black'},
        { label: '已解决', value: '60', color: 'black'},
        { label: '已关闭-已解决', value: '60', color: 'black'},
   ],
    // 任务类型(01科研任务02星火任务03悬赏任务）
    taskKind: [
         { label: '科研任务', value: '01' },
         { label: '众智计划', value: '02' },
         { label: '悬赏任务', value: '03' },
    ],
    sendType2: [
         { label: '全部', value: '' },
         { label: '手动（需提供企业主体名单）', value: '1' },
         { label: '自动（只对企业新用户）', value: '0' },
         { label: '自动（新用户）', value: '2' },
    ],
    // 发送状态
    sendState: [
         { label: '失败', value: 0 },
         { label: '成功', value: 1 },
    ],
    // 发送状态码
    sendCode: [
         { label: '全部', value: '' },
         { label: '失败', value: '500' },
         { label: '成功', value: '200' },
    ],
    // 模板类型
    templateType: [
        { label: '短信消息模板', value: '0' },
        { label: '小程序消息模板', value: '1' },
        { label: '公众号消息模板', value: '2' },
    ],
    // 发货状态
    giftSendState: [
         { label: '未发货', value: '00' },
         { label: '已发货', value: '01' },
    ],
    // 是和否
    isNot: [
        { label: '否', value: '0' },
        { label: '是', value: '1' }
    ],
    // 营销类型（0文章 1产品）
    adType: [
        { label: '文章', value: '0' },
        { label: '产品', value: '1' }
    ],
    // 文件模板类型 授权模板类型（1用户授权模板 2经办人授权模板）
    fileTemplateType: [
        { label: '用户授权模板 ', value: '1' },
        { label: '经办人授权模板', value: '2' },
    ],
    //机构类型（1银行 2企业 3自然人）
    orgType: [
        { label: '银行 ', value: '1' },
        { label: '企业', value: '2' },
        { label: '自然人', value: '3' },
    ],
     //是否公开下载(01 公开 02 不公开)
      isOpen: [
          { label: '公开', value: '01' },
          { label: '不公开', value: '02' },
     ],
    //审批状态(00未审批01审批中02审批通过03审批拒绝)
    checkState: [
        { label: '未审批 ', value: '00' },
        { label: '审批中', value: '01' },
        { label: '审批通过', value: '02' },
        { label: '审批拒绝', value: '03' },
    ],
    //任务状态 (01进行中02已完成）
    taskState:[
      {label:'进行中', value:'01'},
      {label:'已完成',value:'02'},
    ],
    // 映射类型(100统信兼容分类映射200麒麟兼容分类映射)
    linkKind: [
         { label: '统信兼容分类映射', value: '100', color: 'gray' },
         { label: '麒麟兼容分类映射', value: '200', color: 'green'  },
    ],
    //成果适配状态
    thingsAdapterState:[
         { label: '已申请', value: '00' },
         { label: '已审批', value: '01' },
         { label: '适配中', value: '02' },
         { label: '适配成功', value: '03' },
         { label: '适配失败', value: '04' },
    ],
    //成果认证状态
    thingsAuthState:[
      {label:'认证中', value:'01'},
      {label:'已认证',value:'02'},
    ],
}

export default dictData

<template>
  <div class="content">
    <div class="introduce">评论({{ commentList.length }})</div>
    <div class="comment" :style="{ height: height }">
      <div class="infinite-list-wrapper">
        <!--    v-infinite-scroll="load" 
          :infinite-scroll-disabled="disabled" -->
        <ul class="list" style="overflow: auto">
          <li
            v-for="(item, index) in commentList"
            class="list-item"
            :key="index"
          >
            <div class="item">
              <div>
                <img src="@/assets/bl/info.png" alt="" />
                <!-- <span>{{item && item.user && item.user.name ? item.user.name : item.createBy }}</span> -->
                <span>{{
                  item && item.user && item.user.account
                    ? item.user.account
                    : item.createBy
                }}</span>
                <el-tag type="success" round v-if="item.isAdopt == '0'"
                  >已报名</el-tag
                >
                <span
                  style="
                    font-size: 0.768rem /* 24px -> .768rem */;
                    color: #898989;
                    margin-left: 0.16rem /* 5px -> .16rem */;
                  "
                  v-if="
                    userInfo &&
                    userInfo.id &&
                    taskInfo &&
                    taskInfo.taskState == '01' &&
                    taskInfo.createBy == userInfo.id &&
                    item.uid != userInfo.id
                  "
                >
                  <!-- <span>悬赏操作：</span><span style="color: #0256FF;" @click="admit(item)" v-if="taskInfo && taskInfo.taskState == '01' && taskInfo.myPublish && !item.adoptStatus && userInfo && taskInfo.createBy == userInfo.id ">采纳评论</span> -->
                  <el-button
                    style="float: right"
                    size="mini"
                    type="primary"
                    round
                    @click="admit(item)"
                    v-if="
                      taskInfo &&
                      taskInfo.taskState == '01' &&
                      taskInfo.myPublish &&
                      !item.adoptStatus &&
                      userInfo &&
                      taskInfo.createBy == userInfo.id &&
                      item.isAdopt == '0'
                    "
                    >采纳评论</el-button
                  >
                  <!-- <span style="color: #0256FF;" @click="cancelAdmit(item)"  v-if="taskInfo && taskInfo.taskState == '01' && taskInfo.myPublish && item.adoptStatus && userInfo &&taskInfo.createBy == userInfo.id ">取消采纳</span> -->
                  <!-- <span style="color: #0256FF;margin-left: .32rem /* 10px -> .32rem */" @click="rewordItem = item;rewardDialogVisible = true">奖赏评论</span> -->
                </span>
              </div>
              <div class="rightContent">
                <div style="font-size: 0.512rem /* 16px -> .512rem */">
                  <span
                    class="rewordSorce"
                    v-if="item && item.adoptStatus == 1"
                  >
                    <img src="@/assets/bl/thumb.png" alt="" />
                    作者已采纳
                  </span>
                  <span
                    v-if="
                      (item && item.wealth && taskInfo.taskState == '02') ||
                      (item && item.wealth && taskInfo.createBy == userInfo.id)
                    "
                    class="rewordSorce"
                    style="margin-left: 0.32rem /* 10px -> .32rem */"
                  >
                    奖赏 {{ item.wealth }}财富
                  </span>
                </div>
                <div
                  class="centered ck-content"
                  style="
                    height: auto;
                    overflow: hidden;
                    overflow-x: hidden;
                    margin-top: 0.16rem /* 5px -> .16rem */;
                  "
                >
                  <div class="fuwenben-detail" v-html="item.comment"></div>
                </div>
                <div class="time">
                  <span
                    class="left"
                    @click="(drawer = true), (clickReview = item), getReplay()"
                    >{{
                      (item.commentReplies && item.commentReplies.length) || 0
                    }}回复 ></span
                  >
                  <span class="right">发布于：{{ item.updateTime }}</span>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </li>
        </ul>
        <p v-if="loading">加载中...</p>
        <p v-if="noMore">没有更多了</p>
      </div>

      <!-- <div class="bottomWrite" :style="{ width, marginLeft: '-1rem' }">
        <WriteComment v-model="submitComments" :commentListConfig="commentListConfig"></WriteComment>
      </div> -->
    </div>

    <el-drawer
      :visible.sync="drawer"
      direction="btt"
      size="80%"
      :show-close="false"
    >
      <template slot="title">
        <div class="title">
          <img src="@/assets/HeadImg/open.png" alt="" @click="drawer = false" />
          <span>{{ (replayList && replayList.length) || 0 }}条回复</span>
          <span style="opacity: 0">0</span>
        </div>
      </template>
      <div class="review">
        <div class="comment">
          <div class="item">
            <div>
              <img src="@/assets/bl/info.png" alt="" />
              <span>{{
                clickReview && clickReview.user && clickReview.user.account
              }}</span>
            </div>
            <div class="rightContent">
              <div
                class="centered ck-content"
                style="height: auto; overflow: hidden"
              >
                <div
                  class="fuwenben-detail"
                  v-html="clickReview.comment"
                  v-highlight
                ></div>
              </div>
              <div class="time">
                <span class="left"
                  >{{ (replayList && replayList.length) || 0 }}回复 ></span
                >
                <span class="right">发布于：{{ clickReview.updateTime }}</span>
              </div>
            </div>

            <el-divider></el-divider>
          </div>
          <div class="introduce">全部回复</div>
          <div class="infinite-list-wrapper" style="overflow: auto">
            <ul class="list">
              <li
                v-for="(item, index) in replayList"
                class="list-item"
                :key="index"
              >
                <div class="item">
                  <div>
                    <img src="@/assets/bl/info.png" alt="" />
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="rightContent">
                    <div
                      class="centered ck-content"
                      style="height: auto; overflow: hidden"
                    >
                      <div class="fuwenben-detail" v-html="item.reply"></div>
                    </div>
                    <div class="time">
                      <!-- <span class="left">10回复 ></span> -->
                      <span class="right">发布于：{{ item.updateTime }}</span>
                    </div>
                  </div>

                  <el-divider></el-divider>
                </div>
              </li>
            </ul>
            <p>没有更多了</p>
          </div>
        </div>
        <!-- <div class="bottomWrite1" :style="{ width, marginLeft: '-0.7rem'}">
          <WriteComment ></WriteComment>
        </div> -->
        <XsWriteComment
          class="bottomWrite1"
          :item="clickReview"
          :isreply="true"
          :isHaveFiles="false"
          :commentCheckSwitch="taskInfo && taskInfo.commentCheckSwitch"
          v-model="submitComments"
          @submitSuccess="getReplay(), $emit('admitSus', 1)"
        ></XsWriteComment>
      </div>
    </el-drawer>
    <!-- 采纳评论 -->
    <el-dialog
      title="采纳评论"
      :visible.sync="addmitDialogVisible"
      width="70%"
      center
    >
      <span style="font-size: 0.832rem /* 26px -> .832rem */"
        >您确定采纳此评论？结束任务前均可修改采纳评论。</span
      >
      <span slot="footer" class="dialog-footer">
        <div style="text-align: right">
          <el-button @click="addmitDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="adaptCommit">确 定</el-button>
        </div>
      </span>
    </el-dialog>
    <!-- 取消采纳评论 -->
    <el-dialog
      title="取消采纳评论"
      :visible.sync="cancelDialogVisible"
      width="70%"
      center
    >
      <span style="font-size: 0.832rem /* 26px -> .832rem */"
        >您已采纳此评论，确定取消采纳？结束任务前均可修改采纳评论。</span
      >
      <span slot="footer" class="dialog-footer">
        <div style="text-align: right">
          <el-button @click="cancelDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="cancelAdmitComment"
            >确 定</el-button
          >
        </div>
      </span>
    </el-dialog>
    <!-- 奖赏评论 -->
    <el-dialog
      title="奖赏评论"
      :visible.sync="rewardDialogVisible"
      width="80%"
      center
    >
      <div style="overflow: hidden">
        <p style="color: #0056ff; font-size: 0.832rem /* 26px -> .832rem */">
          每一种付出 都值得被鼓励
        </p>
        <div style="">
          <el-button type="primary" plain round @click="commentScore = 1"
            >1财富</el-button
          >
          <el-button type="primary" plain round @click="commentScore = 5"
            >5财富</el-button
          >
          <el-button type="primary" plain round @click="commentScore = 10"
            >10财富</el-button
          >
          <el-button type="primary" plain round @click="commentScore = 15"
            >15财富</el-button
          >
          <el-button
            type="primary"
            plain
            round
            @click="commentScore = 20"
            style="margin-top: 0.32rem /* 10px -> .32rem */; margin-left: 0"
            >20财富</el-button
          >
        </div>

        <div class="" style="margin-top: 0.64rem /* 20px -> .64rem */">
          <span
            ><span style="color: red">*</span
            ><span style="font-size: 0.768rem /* 24px -> .768rem */"
              >奖赏积分</span
            ></span
          >
          <br />
          <el-input-number
            v-model="commentScore"
            controls-position="right"
            :min="1"
            style="margin-top: 0.32rem /* 10px -> .32rem */; width: 100%"
          ></el-input-number>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div style="text-align: right">
          <el-button @click="rewardDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleAssigned">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "XsComments",
  props: {
    commentListConfig: {
      type: Object,
      default: {},
    },
    taskInfo: {
      type: Object,
      default: {},
    },
    id: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      addmitDialogVisible: false,
      cancelDialogVisible: false,
      rewardDialogVisible: false,
      count: 10,
      loading: false,
      height: "auto",
      // height: "1000px",
      writeHeight: 0,
      width: 0,
      drawer: false,
      commentList: [],
      page: {
        pageNum: 1,
        // pageSize:10
        pageSize: 5,
      },
      noMore: false,
      clickReview: {},
      submitComments: {
        softwareId: this.id,
      },
      addmitItem: {},
      cancelItem: {},
      rewordItem: {},
      commentScore: "",
      replayList: [],
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    window.onscroll = () => {
      this.isInViewPortOfTwo(this.getComments, 20);
    };
  },
  created() {
    this.getComments();
  },
  methods: {
    isInViewPortOfTwo(fn, distance = 0) {
      let scrolTop = document.documentElement.scrollTop;
      let pageHeight = document.body.scrollHeight;
      let windowScreen = window.innerHeight;
      let difference = pageHeight - windowScreen - scrolTop - distance;
      if (difference <= 0 && !this.loading && !this.noMore) {
        fn.call(this);
      }
    },
    init() {
      this.page.pageNum = 1;
      this.commentList = [];
      this.loading = false;
      this.noMore = false;
      this.getComments();
    },
    getReplay() {
      let p = {
        comId: this.rewordItem.id,
      };
      let url =
        "/swbus/frontapis/swUcTaskCommentReply/queryBycomid?comId=" +
        this.clickReview.id;
      this.$apis(url, p, "get").then((res) => {
        this.replayList = res.data;
      });
    },
    handleAssigned() {
      this.rewardDialogVisible = false;
      let p = {
        assignedScores: this.commentScore,
        commentId: this.rewordItem.id,
        taskId: this.$route.query.taskId,
      };
      let url = "/swbus/frontapis/swTaskRewards/assignedCommentScores";
      this.$apis(url, p).then((res) => {
        // this.$success('奖赏评论成功！')
        this.$notify("奖赏评论成功！");
        this.page.pageNum = 1;
        this.commentList = [];
        this.getComments();
      });
    },
    admit(item) {
      this.addmitItem = item;
      let data = {
        taskId: this.taskInfo.id,
        commentId: item.id,
        // commentId: this.commentId,
      };
      // this.addmitDialogVisible = true
      this.$confirm("确定要采纳该条评论么? 采纳的同时会结束任务！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$apis("/swbus/frontapis/swUcTaskComment/adopt", data, "post")
          .then((res) => {
            this.$notify({ type: "success", message: "采纳成功！" });
            this.$emit("admitSus", 1);
          })
          .catch((error) => {});
      });
    },
    cancelAdmit(item) {
      this.cancelItem = item;
      this.cancelDialogVisible = true;
    },
    getComments() {
      console.log(213123123);
      this.loading = true;
      let data = {
        current: this.page.pageNum,
        size: this.page.pageSize,
        taskId: this.$route.query.taskId,
      };
      let url = this.commentListConfig.list + this.$format.handleQuery(data);
      this.$apis(url, data, "POST")
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.records);
          this.loading = false;
          console.log("this.loading", this.loading);
          this.page.pageNum += 1;
          if (this.commentList && this.commentList.length == res.data.total)
            this.noMore = true;
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        });
    },
    load() {
      console.log(1111);
      this.getComments();
    },
    adaptCommit(item) {
      this.addmitDialogVisible = false;
      let data = {
        taskId: this.$route.query.taskId,
        commentId: this.addmitItem.id,
      };
      this.$apis("/swbus/frontapis/swUcTaskComment/adopt", data, "post")
        .then((res) => {
          this.$notify("采纳成功！");
          this.init();
        })
        .catch((error) => {
          //this.$error('提交失败');
        });
    },
    cancelAdmitComment(item) {
      this.cancelDialogVisible = false;
      let data = {
        taskId: this.$route.query.taskId,
        commentId: this.cancelItem.id,
      };
      // this.$apis('/swbus/modules/swUcTaskComment/canclAdopt', data, 'post')
      //       .then((res) => {
      //         this.$notify("取消采纳成功！")
      //         this.init()
      //       })
      //       .catch((error) => {
      //         //this.$error('提交失败');
      //       })
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 50vh;
  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
  }
  .comment {
    position: relative;
    font-size: 0.768rem /* 24px -> .768rem */;
    margin-top: 0.64rem /* 20px -> .64rem */;
    .item {
      > div:first-child {
        img {
          width: 1.856rem /* 58px -> 1.856rem */;
          height: 2.112rem /* 66px -> 2.112rem */;
          vertical-align: middle;
        }
        span {
          margin-left: 0.64rem /* 20px -> .64rem */;
        }
      }
      .rightContent {
        margin-left: 2.496rem /* 78px -> 2.496rem */;
      }
      .time {
        margin-top: 0.64rem /* 20px -> .64rem */;
        font-size: 0.512rem /* 16px -> .512rem */;
        .left {
          padding: 0.16rem /* 5px -> .16rem */;
          background: rgba(2, 86, 255, 0.102);
          color: rgba(93, 147, 255, 1);
          border-radius: 1.28rem /* 40px -> 1.28rem */;
        }
        .right {
          margin-left: 0.64rem /* 20px -> .64rem */;
          color: rgba(137, 137, 137, 1);
        }
      }
    }
    p {
      text-align: center;
    }
    .write {
      position: absolute;
      bottom: 0;
    }
  }
  .review {
    position: relative;
    padding: 0.64rem /* 20px -> .64rem */;
    margin-bottom: 1.92rem /* 60px -> 1.92rem */;
    .bottomWrite1 {
      position: fixed;
      bottom: 0.96rem /* 30px -> .96rem */;
      right: 0.96rem /* 30px -> .96rem */;
      ::v-deep .el-input {
        top: -0.32rem; /* -10px -> -0.32rem */
      }
    }
  }

  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
    margin-bottom: 0.64rem /* 20px -> .64rem */;
  }
}
.title {
  display: flex;
  justify-content: space-between;
  font-size: 0.96rem /* 30px -> .96rem */;
  background: #f8f8f8;
  img {
    width: 0.96rem /* 30px -> .96rem */;
    height: 0.96rem /* 30px -> .96rem */;
  }
  margin-left: 0.16rem /* 5px -> .16rem */;
}
::v-deep #el-drawer__title {
  background: #f8f8f8 !important;
  margin-bottom: 0;
  padding-bottom: 1.333rem;
}
.rewordSorce {
  padding: 0.192rem /* 6px -> .192rem */ 0.32rem /* 10px -> .32rem */;
  background: rgba(243, 0, 0, 0.1);
  color: #f30000;
  border-radius: 1.6rem /* 50px -> 1.6rem */;
  img {
    width: 0.64rem /* 20px -> .64rem */ !important;
    height: 0.64rem /* 20px -> .64rem */ !important;
  }
}
</style>

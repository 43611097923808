import router from './router'
import Vue from 'vue'
import * as $fun from '@/utils/fun.js'
function isMobile() {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
    navigator.userAgent
  );
}
function checkdomain(){//验证是否是域名
  let doname = /^([\w-]+\.)+((com)|(net)|(org)|(gov\.cn)|(info)|(cc)|(com\.cn)|(net\.cn)|(org\.cn)|(name)|(biz)|(tv)|(cn)|(mobi)|(name)|(sh)|(ac)|   (io)|(tw)|(com\.tw)|(hk)|(com\.hk)|(ws)|(travel)|(us)|(tm)|(la)|(me\.uk)|(org\.uk)|(ltd\.uk)|(plc\.uk)|(in)|(eu)|(it)|(jp))$/;
  let flag_domain = doname.test(document.domain);
  if(!flag_domain){//错误的域名
      return false
  }else{
      return true
  }
}
// function checkSiteStatus(){
//   let ismaintenance = window.location.href.indexOf('maintenance')>0;
//   let url = '/swbus/frontapis/swCmsIndexConfig/findCmsHomeModuleActive'
//   this.$apis(url, {},'get').then((res)=>{
//       if(ismaintenance && res && !res.data){
//           location.href='/';
//       }else if(!ismaintenance && res && res.data && res.data.htmlPage){
//           location.href=res.data.htmlPage;
//       }
//   })
// }
router.beforeEach((to, from, next) => {
  let t = isMobile()
  let isDomain = checkdomain()
  // console.log("==================",location.host);
  if(!t){
    if(isDomain){
      location.host = 'developer.wxiat.com'
    }else{
      location.host = '192.168.3.158:8888'
    }
  }
  // console.log("to----",to);
  // new RegExp("^/pages.*$").test(subMenuData.data.content) || new RegExp("^http.*$").test(subMenuData.data.content)

  let path =  to.path
  let arr = []
  let arrs = ["自定义数据"]
  if(to && to.matched){
    arr = JSON.parse(JSON.stringify(arrs))
  }
  
  if(new RegExp("^/pages.*$").test(to.path) || new RegExp("^http.*$").test(to.path)){
    arr.push({})
  }else{
    path = "/#" +path
  }
  $fun.checkSiteStatus().then((res) => {
    let ismaintenance = window.location.href.indexOf('maintenance')>0;
    if(ismaintenance && res && !res.data){
      location.href='/';
    }else if(!ismaintenance && res && res.data && res.data.htmlPage){
      location.href=res.data.htmlPage;
    }
  }).catch(() => {
    
  })
  if (to.matched.length === 0 ) { next('/index') }
  $fun.swLogSiteView({ pageUrl: path, pageName: to.name }).then((res) => {
    next()
  }).catch(() => {
    // next({ path: '/index' })
    next()
  })
})

router.afterEach(() => {
  Vue.prototype.$bus.$emit("changeTitle")
})

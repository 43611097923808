import  Layout from '@/components/Layout.vue';


export default {
    path: "/synergy",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: (resolve) => require(["@/views/sw/synergy/index"], resolve),
        name: "生态协同",
        meta: { title: "生态协同", icon: ''},
      },
      {
        path: "taskHall",
        component: (resolve) => require(["@/views/sw/synergy/taskHall/index"], resolve),
        name: "任务大厅",
        meta: { title: "任务大厅", icon: ''},
      },
      {
        path: "taskHall/researchDetail",
        component: (resolve) => require(["@/views/sw/synergy/taskHall/researchDetail"], resolve),
        name: "科研任务详情",
        meta: { title: "科研任务详情", icon: ''},
      },
      {
        path: "taskHall/sparkDetail",
        component: (resolve) => require(["@/views/sw/synergy/taskHall/sparkDetail"], resolve),
        name: "众智计划详情",
        meta: { title: "众智计划详情", icon: ''},
      },
      {
        path: "taskHall/rewardDetail",
        component: (resolve) => require(["@/views/sw/synergy/taskHall/rewardDetail"], resolve),
        name: "悬赏任务详情",
        meta: { title: "悬赏任务详情", icon: ''},
      },

      {
        path: "taskHall/rewardRelease",
        component: (resolve) => require(["@/views/sw/synergy/taskHall/rewardRelease"], resolve),
        name: "新增悬赏任务",
        meta: { title: "新增悬赏任务", icon: ''},
      },



      {
        path: "resultSharing",
        component: (resolve) => require(["@/views/sw/synergy/resultSharing/index"], resolve),
        name: "成果共享",
        meta: { title: "成果共享", icon: ''},
      },
      {
        path: "resultSharing/detail",
        component: (resolve) => require(["@/views/sw/synergy/resultSharing/detail"], resolve),
        name: "成果共享详情",
        meta: { title: "成果共享详情", icon: ''},
      },
    //   {
    //     path: "resultSharing/applySharing",
    //     component: (resolve) => require(["@/views/sw/synergy/resultSharing/applySharing"], resolve),
    //     name: "申请共享",
    //     meta: { title: "申请共享", icon: ''},
    //   },
    ],
};

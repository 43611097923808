import { refreshToken } from '@/api/login'
// import { getToken, setToken, setExpiresIn, removeToken } from '@/utils/auth'
import { getToken,clearUserFromStore,getUserFromStroe ,saveUserToStore,setExpiresIn} from '@/utils/auth'

import { setStore, getStore } from '@/utils/store'
import { api, apip } from '@/api/api.js'
import { rsaEncrypt, rsaDecrypt } from "@/utils/safe"

const user = {
  //namespaced:true,
  state: {
    userInfo: getUserFromStroe(),

    token: getStore({ name: 'token' }) || '',
    unReadNum: '',

    //token: getToken(),
    name: '',
    user_name:'',
    avatar: '',
    roles: [],
    permissions: [],
    //userInfo:{},
    logInfo: {},
    rongqi: getStore({ name: 'rongqi' }) || {},
    wdgd: getStore({ name: 'wdgd' }) || {},

  },

  mutations: {
    set_rongqi: (state, rongqi) => {
      state.rongqi = rongqi;
      setStore({ name: 'rongqi', content: state.rongqi })
    },
    set_logInfo: (state, logInfo) => {
      state.logInfo = logInfo
      try {
        localStorage.setItem('logInfo', JSON.stringify(state.logInfo))
      } catch (e) {
        // error
      }
    },
    set_userInfo: (state, userInfo) => {
      state.userInfo = userInfo
      setStore({ name: 'userInfo', content: state.userInfo })
      if (window.whenUserLogin) {
        window.whenUserLogin();
      }
    },
    set_wdgd: (state, data) => {
      // console.log("state",data);
      state.wdgd = data
      setStore({ name: 'wdgd', content: state.wdgd })
    },
    set_unReadNum: (state, unReadNum) => {
      state.unReadNum = unReadNum
      if (window.whenUnMessage) {
        window.whenUnMessage(unReadNum)
      }
      // setStore({ name: 'unReadNum', content: state.unReadNum })
    },
    SET_TOKEN: (state, token) => {
      state.token = token
      setStore({ name: 'token', content: state.token, type: 'session' })
    },
    SET_EXPIRES_IN: (state, time) => {
      state.expires_in = time
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_USER_NAME: (state, user_name) => {
      state.user_name = user_name
      // console.log('mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm',user_name);
      // if (window.whenUnName) {
        // window.whenUnName(user_name)
      // }
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 容器缓存
    setrongqi({ commit, state }, datas) {
      return new Promise((resolve, reject) => {
        commit('set_rongqi', datas)
        resolve()
      })
        .catch(err => {
          reject(err)
        })
    },
    // 登录
    Login({ commit }, datas) {
      return new Promise((resolve, reject) => {
        if (datas) {
          datas.account = datas.account ? rsaEncrypt(datas.account) : '';
          datas.password = datas.password ? rsaEncrypt(datas.password) : '';
          datas.phone = datas.phone ? rsaEncrypt(datas.phone) : '';
          datas.verifyCode = datas.verifyCode ? rsaEncrypt(datas.verifyCode) : '';
        }
        api('/swbus/frontapis/user/login', datas).then(function (res) {
          if (res.code == 200) {
            let data = res.data
            let user = data.user
            saveUserToStore(user,data.accessToken)
            commit('SET_TOKEN', data.accessToken)
            // console.log(res,'=====================')
            setExpiresIn(data.expiresIn)
            commit('SET_EXPIRES_IN', data.expiresIn)
            // let user = data
            // const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.png") : user.avatar;
            // commit('set_userInfo', user)
            // commit('SET_NAME', user.name)
            // commit('SET_AVATAR', avatar)

            resolve()

          } else {

            reject(res)
          }
        }).catch(err => {
          reject(err)
        })

      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        apip('/swbus/frontapis/user/loadInfo').then(function (res) {
          // console.log('user/loadInfo', res)
          if( res&&res.data){
            if (res.code == 200) {
              let data = res.data
              let user = data
              saveUserToStore(res.data,null)
              const avatar = (user.avatar == "" || user.avatar == null) ? require("@/assets/images/profile.png") : user.avatar;
              commit('set_userInfo', user)
              commit('SET_NAME', user.name)
              commit('SET_USER_NAME', user.name)
  
              api('/swbus/frontapis/swUcMessage/getUnReadMessageNum').then(function (resp) {
                commit('set_unReadNum', resp.data)
                resolve()
  
              })

  
            } else {
              reject(res)
              console.log(res,'========');
            }
          }

        }).catch(err => {
          commit('SET_PERMISSIONS', [])
          reject(err)
        })

      })

    },

    // 刷新token
    RefreshToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        refreshToken(state.token).then(res => {
          setExpiresIn(res.data)
          commit('SET_EXPIRES_IN', res.data)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '')
        commit('set_userInfo', {})
        commit('SET_NAME', '')
        commit('SET_AVATAR', '')
        commit('SET_USER_NAME', '')

        //localStorage.setItem('logInfo', '')
        // if (window.whenUserOut) {
        //   window.whenUserOut();
        // }
        clearUserFromStore();
        // localStorage.removeItem('logInfo', null)
        resolve()

      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        resolve()
      })
    }
  }
}

export default user

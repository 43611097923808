<template>
    <div class="wd-menu">
        <div class="infos">
            <div class="infos-con">
                <div class="avatar">
                    <img :src="userInfo.avatar" v-if="userInfo && userInfo.avatar" alt="" />
                    <img v-else src="@/assets/bl/avatar.png" alt="">
                    <!-- <img src="@/assets/images/profile_user.png" v-else alt="" /> -->
                </div>
                <div class="infos-r">
                    <div class="infos-name"> {{ userInfo.account }} </div>
                    <div class="infos-jz">
                        <div>
                            <span v-for="(item, index) in userInfo && userInfo.medalsVoList ? userInfo.medalsVoList : []"
                                :key="index"><img :src="item.picUrl" alt=""> </span>
                        </div>
                        <div @click="jiangzhangiconFormDialog = true"> <i class="el-icon-arrow-right"></i></div>

                    </div>
                    <div class="infos-lv">等级：{{ userInfo.levelName }}</div>
                </div>
            </div>
            <div class="infos-icon">
                <img v-if="userInfo != null && userInfo.roleId && userInfo.roleId.split(',').includes('104')"
                    src="@/assets/bl/qylmqy.png" alt="">
                <img v-if="userInfo.authStatus == '00'" src="@/assets/bl/uwrz.png" alt="">
                <img v-if="userInfo.authStatus == '01'" src="@/assets/bl/usp.png" alt="">
                <img v-if="userInfo.authStatus == '02'" src="@/assets/bl/uyrz.png" alt="">
                <img v-if="userInfo.authStatus == '03'" src="@/assets/bl/uspsb.png" alt="">
            </div>

        </div>
        <el-dialog ref="jiangzhangiconFormDialog" title="我的成就徽章" :visible.sync="jiangzhangiconFormDialog" width="85%">
            <div style="display: flex; flex-wrap: wrap; column-gap: 1.6rem /* 50px -> 1.6rem */;
                    row-gap: 1.28rem /* 40px -> 1.28rem */;">
                <span v-for="(item, index) in userInfo && userInfo.medalsVoList ? userInfo.medalsVoList : []" :key="index"
                    style="display: flex; flex-direction: column; align-items: center; ">

                    <img style="width: 2.56rem /* 80px -> 2.56rem */; height: 2.56rem /* 80px -> 2.56rem */; margin-bottom: .32rem /* 10px -> .32rem */;"
                        :src="item.picUrl" alt="">
                    <span style="font-size: .576rem /* 18px -> .576rem */; color: #3d3d3d;">{{ item.name }}</span>


                </span>
            </div>

        </el-dialog>
    </div>
</template>
  
<script>
import { mapState } from 'vuex'

export default {
    name: "wd-menu",
    data() {
        return {
            jiangzhangiconFormDialog: false

        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    created() {

    },
    mounted() {
    },

    methods: {



    },
};
</script>
  
<style scoped lang="scss">
.wd-menu {
    .infos {
        height: 7.04rem
            /* 220px -> 7.04rem */
        ;
        box-sizing: border-box;
        background-image: url('@/assets/bl/wdbg.png');
        background-size: 100% 100%;
        padding: 1rem;
        position: relative;

        .infos-con {
            display: flex;
            column-gap: .64rem
                /* 20px -> .64rem */
            ;

            .avatar {
                width: 3.84rem
                    /* 120px -> 3.84rem */
                ;
                height: 3.84rem
                    /* 120px -> 3.84rem */
                ;

                img {
                    width: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }

            .infos-r {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .infos-name {
                    font-size: .896rem
                        /* 28px -> .896rem */
                    ;
                }

                .infos-jz {
                    display: flex;
                    justify-content: space-between;

                    i {
                        font-size: .64rem
                            /* 20px -> .64rem */
                        ;
                        color: #0256FF;
                    }

                    span {
                        img {
                            width: .832rem
                                /* 26px -> .832rem */
                            ;
                            height: .96rem
                                /* 30px -> .96rem */
                            ;
                        }
                    }
                }

                .infos-lv {
                    font-size: .64rem
                        /* 20px -> .64rem */
                    ;
                }
            }
        }

        .infos-icon {
            position: absolute;
            top: 1rem;
            right: 1rem;
            // width: calc(100% - 2rem);
            display: flex;
            justify-content: end;
            column-gap: .64rem
                /* 20px -> .64rem */
            ;

            img {
                height: 1.28rem
                    /* 40px -> 1.28rem */
                ;
                width: auto;
                object-fit: contain;
            }
        }

    }
}
</style>
  
  
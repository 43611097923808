const state = {
  top5ClickAuthorInfo:{},
  top5Pages:[],
  jjfaPage:{},
  xsMissonAgain:{},
  kfzzPage:{}
}
const mutations = {
  changeTop5ClickAuthorInfo: (state, val) => {
    state.top5ClickAuthorInfo = val
  },
  changeTop5Pages: (state, val) => {
    state.top5Pages = val
  },
  changeJjfaPage: (state, val) => {
    state.jjfaPage = val
  },
  changeSomeone(state,obj){
    for (const key in obj) {
      state[key] = obj[key]
    }
  }
}

const actions = {
    actionTop5ClickAuthorInfo({ commit }, data) {
    commit('changeTop5ClickAuthorInfo', data)
  },
  actionTop5Pages({ commit }, data) {
    commit('changeTop5Pages', data)
  },
  actionJjfaPage({ commit }, data) {
    commit('changeJjfaPage', data)
  },
  actionSomeone({ commit }, data){
    commit('changeSomeone', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}


<template>
  <div class="content">
    <div class="top">
      <img src="@/assets/bl/Sunway.png" alt="">
      <span>一起开发  共筑申威</span>
    </div>
    <el-divider></el-divider>
    <div class="bottom">
      <div class="left">
        <div>
          <img src="@/assets/bl/email.png" alt="">
        <span>
          <span>联系邮箱：</span>
          <span>{{ websiteConfig.text_email ? websiteConfig.text_email : '' }}</span>
        </span>
        </div>
        <div class="botom">
          <img src="@/assets/bl/talk.png" alt="">
          <span>
            <span>服务热线：</span>
            <span>{{ websiteConfig.text_telephone ? websiteConfig.text_telephone : ''}}</span>
          </span>
        </div>
      </div>
      <div class="right" v-for="item in picList">
            <div class="img" alt>
              <img :src="item.configPic" alt=""  v-if="item.configPic">
            </div>
            <div class="name" v-if="item.configPic">{{ item.configName }}</div>
      </div>
    </div>
    <div class="someInfo">
      <!-- 无锡先进技术研究院2023 © 版权所有 申威社区 -->
      {{ websiteConfig.footer_text }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name:"Bottom",
  data() {
    return {
      menusLenth: -1,
      menuList: [
        { text: '关于我们', url: '', type: '1' },
        { text: '授权信息', url: '', type: '2' },
        { text: '网站条款', url: '', type: '3' },
        { text: '用户协议', url: '', type: '4' },
        { text: '隐私政策', url: '', type: '5' },
        // { text: '版权保护', url: '', type: '7' },
        // { text: '友情链接', url: '', type: '8' },
        { text: '帮助中心', url: '', type: '6' }
      ],
      bottomNavigation: ['1', '2', '3', '4', '5', '6'],
      codeList: [
        { url: require('@/assets/logo/logo.png'), text: '微信公众号' },
        { url: require('@/assets/sy/er.png'), text: '官方客服' }
      ],
      configList: [],
      linkList: [],
      picList: [],
      configCode: 'website_config',
      websiteConfig: {},
    }
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
      systemSetting: (state) => state.app.systemSetting
    }),
    isMobile() {
      return this.device === 'mobile'
    },
    getMenuList() {
      if (this.isMobile) {
        const temp = this.menuList.slice(0, 4)
        temp.push({ text: '更多内容', url: '' })
        return temp
      } else {
        return this.menuList
      }
    }
  },
  watch: {
    systemSetting: {
      handler(val) {
        // 微信公众号
        //if (val.weChat) this.codeList[0].url = val.weChat
        // 官方客服号
        //if (val.official) this.codeList[1].url = val.official
      },
      deep: true,
      immediate: true
    },
    getMenuList: {
      handler(val) {
        if (val) {
          this.menusLenth = val.length
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getCmsConfigList()
  },
  methods: {
    handleMenuClick(item) {
      if (this.bottomNavigation.includes(item.type)) {
        //this.$jumpNewWindow({ path: '/bottom/navigation', query: { type: item.type }})
        // this.$router.push({
        //   path: '/bottom/navigation',
        //   query: {
        //     type: item.type
        //   }
        // })
      }
    },
    //获取网站参数
    getCmsConfigList() {
      let data = {}
      let url = "/swbus/frontapis/swCmsConfig/loadCmsConfigList"
      // api(url, data, 'get').then(res => {
     this.$apis(url, data, 'get').then(res => {
        this.configList = res.data
        this.linkList = []
        this.picList = []
        this.configList.forEach(element => {
          if(element.configCode == this.configCode){
            this.websiteConfig = JSON.parse(element.configText)
            this.linkList = this.websiteConfig.linkurl_qickLink ? JSON.parse(this.websiteConfig.linkurl_qickLink) : []
            // console.log('this.linkList==', this.linkList)
            let picList = []
            if(this.websiteConfig.pic_publictext1 && this.websiteConfig.pic_publictext1){
              picList.push({configName: this.websiteConfig.pic_publictext1, configPic: this.websiteConfig.pic_publicsite1})
            }
            if(this.websiteConfig.pic_publictext2 && this.websiteConfig.pic_publictext2){
              picList.push({configName: this.websiteConfig.pic_publictext2, configPic: this.websiteConfig.pic_publicsite2})
            }
            this.picList = picList
          }
          // let match = element.configCode.includes("linkurl")
          // let picmatch = element.configCode.includes("pic_")
          // console.log(match)
          // if (match) {
          //   this.linkList.push(element)
          // }
          // if (picmatch) {
          //   this.picList.push(element)
          // }
        });
      })
    },

    //查询
    findData(key) {
      var o = this.configList.find(Object => Object.configCode == key)
      return o
    },


  }
}
</script>


<style  scoped lang='scss'>
.content{
  position: relative;
  color: #FFFFFF;
  height: 9.6rem /* 300px -> 9.6rem */;
  background: #1C1C65!important;
  padding: .96rem /* 30px -> .96rem */ .96rem /* 30px -> .96rem */ 0;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  .top{
    font-size: .896rem /* 28px -> .896rem */;
    color: #FFFFFF;
    img{
      width: 3.2rem /* 100px -> 3.2rem */!important;
      object-fit: cover;
      vertical-align: middle;
      margin-right: 1.28rem /* 40px -> 1.28rem */;
    }
  }
  .bottom{
    font-size: .64rem /* 20px -> .64rem */;
    display: flex;
    justify-content: space-between;
    img{
          width: .96rem /* 30px -> .96rem */;
          object-fit: cover;
          vertical-align: middle;
          margin-right: .64rem /* 20px -> .64rem */;
        }
    .left{
      >div:nth-child(2){
       margin-top: 1.28rem /* 40px -> 1.28rem */;
      }
       
      }
      .right{
        text-align: right;
        img{
          width: 3.2rem /* 100px -> 3.2rem */;
          object-fit: cover;
          margin-right: 0;
        }
        .name{
          text-align: center;
          margin-top: .32rem /* 10px -> .32rem */;
          font-size: .448rem /* 14px -> .448rem */;
          color: #FFFFFF;
        }
      }
  }
  .someInfo{
    width: 100%;
    position: absolute;
    bottom: .16rem /* 5px -> .16rem */;
    text-align: center;
    font-size: .448rem /* 14px -> .448rem */;
  }
}
</style>

<template>
  <div class="content"    >
    <div class="introduce">评论({{ total}})</div>
    <div class="comment" :style="{ height: height }" >
      <div class="infinite-list-wrapper" >
        <!-- <ul
          class="list"
          v-infinite-scroll="load" :infinite-scroll-disabled="disabled" style="overflow: auto"
        > -->
        <ul
          class="list"
        >
          <li v-for="(item,index) in commentList" class="list-item" :key="index">
            <div class="item">
              <div>
                <img :src="item.user && item.user.avatar? item.user.avatar : require('@/assets/bl/info.png')" alt="" />
                <span>{{item && item.user && item.user.account ? item.user.account : item.createBy }}</span>
                <span class="right">发布于：{{ item.updateTime }}</span>
              </div>
              <div class="rightContent">
                <div  class="centered ck-content" style="height: auto;overflow: hidden; overflow-x: hidden;">
                      <div class="fuwenben-detail" v-html="item.content" v-highlight></div>
                 </div>
                <div class="time">
                  <!-- <span class="left" @click="drawer = true,clickReview = item">{{ item.replies && item.replies.length || 0}}回复 ></span> -->
                  <!-- <span class="right">发布于：{{ item.updateTime }}</span> -->
                </div>
              </div>
                <div v-for="(item1,index) in item.replies" class="list-item replies" :key="index">
                <div class="name">
                  申威超级管理员
                </div>
                <div class="content">
                  {{ item1.content }}
                </div>
             </div>
              <el-divider></el-divider>
            </div>
          </li>
        </ul>
        <p v-if="loading">加载中...</p>
        <!-- <p v-if="noMore">没有更多了</p> -->
        <div style="height: 50px;" v-if="noMore && userInfo && userInfo.id"></div>
        <div v-if="!commentList.length" style="height: 4.8rem /* 150px -> 4.8rem */;"></div>
      </div>

      <!-- <div class="item">
        <div>
          <img src="@/assets/bl/info.png" alt="" />
          <span>张小萌VVIP</span>
        </div>
        <div class="rightContent"> 
          <div>
            我晒过一次，晒完身体很舒服，后背全是汗。是真的颈椎啊，后背啊都很舒服的感觉。八点多晒到九点，坐着小凳子带着帽子晒的、也不费事。擦擦汗回屋了，不能马上洗澡。就一次，但感觉不错。后来出来玩了，没有坚持
            。
          </div>
          <div>
            <span>10回复 ></span>
            <span>发布于：2023-09-06 16:55:30</span>
          </div>
        </div>

        <el-divider></el-divider>
      </div> -->
      <!-- <div class="bottomWrite" :style="{ width, marginLeft: '-1rem' }">
        <WriteComment v-model="submitComments" :commentListConfig="commentListConfig"></WriteComment>
      </div> -->
    </div>

    <el-drawer
      :visible.sync="drawer"
      direction="btt"
      size="80%"
      :show-close="false"
    >
    <template slot="title">
        <div class="title">
            <img src="@/assets/HeadImg/open.png" alt="" @click="drawer = false">
            <span>{{clickReview.replies && clickReview.replies.length || 0}}条回复</span>
            <span style="opacity: 0;">0</span>
        </div>
        </template>
      <div class="review">
        <div class="comment">
          <div class="item">
            <div>
              <img src="@/assets/bl/info.png" alt="" />
              <span>{{ clickReview && clickReview.user && clickReview.user.name ? clickReview.user.name : clickReview.createBy}}</span>
            </div>
            <div class="rightContent">
              <div  class="centered ck-content" style="height: auto;overflow: hidden;">
                      <div class="fuwenben-detail" v-html="clickReview.content" v-highlight></div>
                 </div>
              <div class="time">
                <span class="left">{{clickReview.replies && clickReview.replies.length || 0}}回复 ></span>
                <span class="right">发布于：{{ clickReview.updateTime }}</span>
              </div>
            </div>

            <el-divider></el-divider>
          </div>
          <div class="introduce">全部回复</div>
          <div class="infinite-list-wrapper" style="overflow: auto">
            <ul
              class="list"
            >
              <li v-for="(item,index) in clickReview.replies" class="list-item" :key="index">
                <div class="item">
                  <div>
                    <img src="@/assets/bl/info.png" alt="" />
                    <span>{{item && item.user && item.user.name ? item.user.name : item.createBy }}</span>
                  </div>
                  <div class="rightContent">
                    <div  class="centered ck-content" style="height: auto;overflow: hidden;">
                      <div class="fuwenben-detail" v-html="item.content" v-highlight></div>
                     </div>
                    <div class="time">
                      <!-- <span class="left">10回复 ></span> -->
                      <span class="right">发布于：{{ item.updateTime }}</span>
                    </div>
                  </div>

                  <el-divider></el-divider>
                </div>
              </li>
            </ul>
            <p >没有更多了</p>
          </div>
        </div>
        <!-- <div class="bottomWrite1" :style="{ width, marginLeft: '-0.7rem' }">
          <WriteComment ></WriteComment>
        </div> -->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "Comments",
  props:{
    commentListConfig:{
      type:Object,
      default:{}
    },
    id:{
      type:[String,Number],
      default:''
    }
  },
  data() {
    return {
      count: 10,
      loading: false,
      height: "auto",
      // height: "1000px",
      writeHeight: 0,
      width: 0,
      drawer: false,
      commentList:[],
      total:0,
      page:{
        pageNum:1,
        // pageSize:10
        // pageSize:1
        pageSize:5
      },
      noMore:false,
      clickReview:{},
      submitComments:{
        softwareId:this.id
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted() {
    this.$nextTick(() => {
      let comment = document.querySelector(".comment");
      let writeComment = document.querySelector(".write");
      let height = comment ? comment.offsetHeight:'';
      let writeCommentheight = writeComment ? writeComment.offsetHeight:'';
      this.writeHeight = height - writeCommentheight + "px";
      console.log("height", height, writeCommentheight);
      this.height = height + "px";
      //
      let width = document.body.offsetWidth;
      this.width = width + "px";
      console.log("widht", width);
    });
    window.onscroll =  () => {
       this.isInViewPortOfTwo(this.getComments,20)
    }
  },
  created(){
    this.getComments()
  },
  methods: {
    isInViewPortOfTwo(fn,distance = 0) {
     let scrolTop = document.documentElement.scrollTop
     let pageHeight = document.body.scrollHeight
     let windowScreen = window.innerHeight
     let difference = pageHeight - windowScreen - scrolTop - distance
     if(difference <= 0 && !this.loading && !this.noMore) {
      fn.call(this)
     }
    },
    initGetComments(){
      this.page.pageNum = 1
      this.noMore = false
      this.commentList = []
      this.getComments()
    },
    getComments(){
      this.loading = true
      let data = {
        current: this.page.pageNum,
        size: this.page.pageSize,
        softwareId: this.$route.query.id,
      }
      let url = this.commentListConfig.list +this.$format.handleQuery(data)
      this.$apis(url,data, 'POST')
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.records)
          this.total = res.data.total
          this.loading = false
          console.log("this.loading",this.loading);
          this.page.pageNum += 1
          if(this.commentList && this.commentList.length == res.data.total) this.noMore = true
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        })
    },
    load() {
      console.log(1111);
      this.getComments()
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
  }
  .comment {
    position: relative;
    font-size: 0.768rem /* 24px -> .768rem */;
    margin-top: 0.64rem /* 20px -> .64rem */;
    .item {
      .right {
         float: right;
          color: rgba(137, 137, 137, 1);
          font-size: .64rem /* 20px -> .64rem */;
        }
      > div:first-child {
        img {
          width: 1.856rem /* 58px -> 1.856rem */;
          height: 2.112rem /* 66px -> 2.112rem */;
          vertical-align: middle;
        }
        span {
          margin-left: 0.64rem /* 20px -> .64rem */;
        }
      }
      .rightContent {
        margin-left: 2.496rem /* 78px -> 2.496rem */;
      }
      .time {
        margin-top: 0.64rem /* 20px -> .64rem */;
        font-size: 0.512rem /* 16px -> .512rem */;
        .left {
          padding: 0.16rem /* 5px -> .16rem */;
          background: rgba(2, 86, 255, 0.102);
          color: rgba(93, 147, 255, 1);
          border-radius: 1.28rem /* 40px -> 1.28rem */;
        }
        .right {
          margin-left: 0.64rem /* 20px -> .64rem */;
          color: rgba(137, 137, 137, 1);
        }
      }
      .replies{
        margin-left: 2.176rem /* 68px -> 2.176rem */;
        border-left: 1px solid #898989;
        padding-left: .32rem /* 10px -> .32rem */;
        .name{
          font-size: .768rem /* 24px -> .768rem */;
        }
        .content{
          margin-top: .64rem /* 20px -> .64rem */;
          color: #FF8400;
          font-size: .768rem /* 24px -> .768rem */;
        }
      }
    }
    p {
      text-align: center;
    }
    .write {
      position: absolute;
      bottom: 0;
    }
  }
  .review {
    position: relative;
    padding: 0.64rem /* 20px -> .64rem */;
    margin-bottom: 1.92rem /* 60px -> 1.92rem */;
    .bottomWrite1 {
      position: fixed;
      bottom: 0;
      ::v-deep .el-input {
        top: -0.32rem; /* -10px -> -0.32rem */
      }
    }
  }

  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
    margin-bottom: 0.64rem /* 20px -> .64rem */;
  }

}
.title{
        display: flex;
        justify-content: space-between;
        font-size: .96rem /* 30px -> .96rem */;
        background: #F8F8F8;
        img{
            width: .96rem /* 30px -> .96rem */;
            height: .96rem /* 30px -> .96rem */;
        }
        margin-left: .16rem /* 5px -> .16rem */;
    }
    ::v-deep #el-drawer__title{
    background: #F8F8F8!important;
    margin-bottom: 0;
    padding-bottom: 1.333rem;
}
</style>
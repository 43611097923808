<template>
    <div class="upload-file">
      <el-upload multiple :action="uploadUrl" :file-list="fileList" :limit="limit" :headers="headers"
        class="upload-file-uploader" ref="upload" :accept="accept" :data="postData" :disabled="disabled"
        :on-success="handleSuccess" :on-remove="handleRemove" :on-error="handleUploadError" :on-exceed="handleExceed"
        :before-upload="beforeUpload">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">
          <div v-if="limit>1">
            可上传{{ limit }}个文件，单文件不超过 <b style="color: #f56c6c">{{ fileSize }} MB</b>
          </div>  
          <div v-if="limit<=1">
            文件不超过 <b style="color: #f56c6c">{{ fileSize }} MB</b>
          </div>
        
        </div>
      </el-upload>
    </div>
  </template>
  
  <script>
  import { getToken } from '@/utils/auth'
  export default {
    name: "FileUpload3",
    props: {
      // 值
      value: [String, Object, Array],
      // 数量限制
      limit: {
        type: Number,
        default: 3,
      },
      fileSize: {
        type: Number,
        default: 100,
      },
      accept: [String],
      disabled:{
        type:Boolean,
      },
      headers: {
          type: [Object],
          default: () => {
              return {
              "sw2-devcom-auth": "Bearer " + getToken()
            }
          }
      },
      //额外请求参数
      postData: {
        type: [Object],
        default: () => {
          return {};
        },
      }
    },
    data() {
      return {
        number: 0,
        uploadList: [],
        uploadUrl: this.$uploadUrl, //process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
       
        fileList: [],
      };
    },
    watch: {
      value: {
        handler(val) {
          if (typeof val === "string") {
            if (val) {
              // 组件需要参数名为 name
              let p = JSON.parse(val);
              p.map((item) => {
                if (item.fileName) {
                  item.name = item.fileName;
                }
                return item;
              });
              this.fileList = p;
            } else {
              this.fileList = [];
            }
          } else {
            if (val && val.length > 0) {
              this.fileList = val;
            } else {
              this.fileList = [];
            }
          }
        },
        deep: true,
        immediate: true,
      },
    },
    computed: {
  
    },
    methods: {
      handleSuccess(response, file, fileList) {
        this.emitFileList(fileList);
      },
      handleRemove(file, fileList) {
        this.emitFileList(fileList);
      },
      handleUploadError(err, file, fileList) {
        this.$modal.msgError(`${file.name}上传失败`);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      emitFileList(fileList) {
        var newFileList = [];
        for (var i = 0; i < fileList.length; i++) {
          var item = fileList[i];
          var newitem = item;
          if (item.response) {
            newitem = item.response;
            newitem.status = "success";
            newitem.fileSize = item.size;
          }
          newFileList.push(newitem);
        }
        console.info(fileList);
        console.info(newFileList);
        this.$emit("input", JSON.stringify(newFileList));
      },
      beforeUpload(file) {
        console.log('文件：', file)
        var FileExt = file.name.replace(/.+\./, "")
        const tooLarge = file.size / 1024 / 1024 > this.fileSize
        
        if (tooLarge) {
        //   this.$message({
        //     type: 'warning',
        //     message: `${file.name}大小超限，文件不能超过 ${this.fileSize}MB`
        //   })
          this.$message.warning(`${file.name}大小超限，文件不能超过 ${this.fileSize}MB`)
          return false
        }
      }
    }
  
  };
  </script>
  
  
  <style>
  .el-upload-list{max-width: 350px;}
  .upload-file-uploader {
    margin-bottom: 5px;
  }
  </style>
import  Layout from '@/components/Layout.vue';


export default {
    path: "/ecology",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: (resolve) => require(["@/views/sw/ecology/index"], resolve),
        name: "申威生态",
        meta: { title: "申威生态", icon: ''},
      },
      {
        path: "basicSoftware",
        component: (resolve) => require(["@/views/sw/ecology/basicSoftware/index"], resolve),
        name: "基础软件",
        meta: { title: "基础软件", icon: ''},
      },
      {
        path: "basicSoftware/detail",
        component: (resolve) => require(["@/views/sw/ecology/basicSoftware/detail"], resolve),
        name: "基础软件详情",
        meta: { title: "基础软件详情", icon: ''},
      },

      {
        path: "repositories",
        component: (resolve) => require(["@/views/sw/ecology/repositories/index"], resolve),
        name: "仓库镜像",
        meta: { title: "仓库镜像", icon: ''},
      },

      {
        path: "openSource",
        component: (resolve) => require(["@/views/sw/ecology/openSource/index"], resolve),
        name: "开源项目",
        meta: { title: "开源项目", icon: ''},
      },
      {
        path: "resultQuery",
        component: (resolve) => require(["@/views/sw/ecology/resultQuery/index"], resolve),
        name: "成果查询详情",
        meta: { title: "成果查询详情", icon: ''},
      },
      {
        path: "solution",
        component: (resolve) => require(["@/views/sw/ecology/solution/index"], resolve),
        name: "解决方案",
        meta: { title: "解决方案", icon: ''},
      },
      {
        path: "solution/detail",
        component: (resolve) => require(["@/views/sw/ecology/solution/detail"], resolve),
        name: "解决方案详情",
        meta: { title: "解决方案详情", icon: ''},
      },
      {
        path: "vendorColumn",
        component: (resolve) => require(["@/views/sw/ecology/vendorColumn/index"], resolve),
        name: "厂商专栏",
        meta: { title: "厂商专栏", icon: ''},
      }
      
    ],
};

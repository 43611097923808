import  Layout from '@/components/Layout.vue';

export default {
    path: "/activityCenter",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "activity",
        component: (resolve) => require(["@/views/sw/activityCenter/activity/index"], resolve),
        name: "申威活动",
        meta: { title: "申威活动", icon: ''},
      },
      {
        path: "activity/detail",
        component: (resolve) => require(["@/views/sw/activityCenter/activity/detail"], resolve),
        name: "活动详情",
        meta: { title: "活动详情", icon: ''},
      },
      {
        path: "news",
        component: (resolve) => require(["@/views/sw/activityCenter/news/index"], resolve),
        name: "申威动态",
        meta: { title: "申威动态", icon: ''},
      },
      {
        path: "news/detail",
        component: (resolve) => require(["@/views/sw/activityCenter/news/detail"], resolve),
        name: "动态详情",
        meta: { title: "动态详情", icon: ''},
      },
      
    ],
};

// import http from './http';
import request from '@/utils/request'

//头部走马灯数据
// export function topImgData(parmas){
// 	return http.get('/swbus/frontapis/swCmsSlide/findCmsSlideActiveList', parmas)
// }
export function topImgData(parmas){
	return request({
		url: '/swbus/frontapis/swCmsSlide/findCmsSlideActiveList',
		method: 'get',
		params: {
			parmas
		}
	})
}
//
// export function cmsBlockActiveList(parmas){
// 	return http.get('/swbus/frontapis/swCmsBlock/findCmsBlockActiveList', parmas)
// }
export function cmsBlockActiveList(parmas){
	return request({
		url: '/swbus/frontapis/swCmsBlock/findCmsBlockActiveList',
		method: 'get',
		params: {
			parmas
		}
	})
}
//活动动态
// export function findCmsNewsLastList(parmas){
// 	return http.get('/swbus/frontapis/swCmsNews/findCmsNewsLastList', parmas)
// }
export function findCmsNewsLastList(parmas){
	return request({
		url: '/swbus/frontapis/swCmsNews/findCmsNewsLastList',
		method: 'get',
		params: {
			parmas
		}
	})
}
//申威活动
// export function findCmsActivityTopList(parmas){
// 	return http.get('/swbus/frontapis/swCmsActivity/findCmsActivityTopList', parmas)
// }
export function findCmsActivityTopList(parmas){
	return request({
		url: '/swbus/frontapis/swCmsActivity/findCmsActivityTopList',
		method: 'get',
		params: {
			parmas
		}
	})
}
//首页的开发者专栏
// export function findHomeBlogArticle(parmas){
// 	return http.get('/swbus/frontapis/swBlogArticle/findHomeIndexTopBlogArticle', parmas)
// }
export function findHomeBlogArticle(parmas){
	return request({
		url: '/swbus/frontapis/swBlogArticle/findHomeIndexTopBlogArticle',
		method: 'get',
		params: {
			parmas
		}
	})
}
//首页底部数据
// export function swCmsDocumentList(parmas){
// 	return http.get('/swbus/frontapis/swCmsDocument/list', parmas)
// }
export function swCmsDocumentList(parmas){
	return request({
		url: '/swbus/frontapis/swCmsDocument/list',
		method: 'get',
		params: {
			parmas
		}
	})
}
//路由
// export function buildTree(parmas){
// 	return http.get('/swbus/frontapis/swCmsNav/buildNavigationBar', parmas)
// }
export function buildTree(parmas){
	return request({
		url: '/swbus/frontapis/swCmsNav/buildNavigationBar',
		method: 'get',
		params: {
			parmas
		}
	})
}
//基础软件列表
// export function jcrjList(parmas){
// 	return http.get('/swbus/frontapis/swSoftware/search', parmas)
// }
export function jcrjList(parmas){
	return request({
		url: '/swbus/frontapis/swSoftware/search',
		method: 'get',
		params: {
			parmas
		}
	})
}
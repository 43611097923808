<template>
  <!-- ckeditor 父元素 -->
  <div :class="{'editor-view':true,'editor-view1':editorView,'editor-view2':notEditor}" style="position: relative">
    <!-- 编辑器 -->
    <!-- <div id="editor"></div> -->
    <div id="editor" ref="editorRef"></div>
    <span v-if="isLimitWorld" class="wordNumber">{{ haveWorld }}/{{ worldMax }}</span>
  </div>
</template>
  
  <script>
import { getToken } from '@/utils/auth'
import upimg from './upimg.js'
export default {
  name: 'ws-editor',
  props: {
    /* 编辑器的内容 */
    value: {
      type: String,
      default: '',
    },
    // 回显
    initValue: {
      type: String,
      default: '',
    },
    notEditor:{
      type: Boolean,
      default: false,
    },
    editorRef:{
      type:String,
      default:'editorRef'
    },
    isLimitWorld: {
      type: Boolean,
      default: false,
    },
    worldMax: {
      type: Number,
      default: 1000,
    },
    /* 高度 */
    height: {
      type: Number,
      default: 400,
    },
    /* 最小高度 */
    minHeight: {
      type: Number,
      default: null,
    },
    /* 只读 */
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // 上传文件大小限制(MB)
    fileSize: {
      type: Number,
      default: 5,
    },
    /* 类型（base64格式、url格式） */
    type: {
      type: String,
      default: 'url',
    },
    /* 图片上传地址 */
    // uploadUrl: {
    //   type: String,
    //   default: process.env.VUE_APP_BASE_API + '/swbus/modules/swCommon/uploadFile',
    // },
    //图片上传头部
    headers: {
      type: [Object],
      default: () => {
        return {
          Authorization: 'Bearer ' + getToken(),
        }
      },
    },
    //是否需要图片
    isHaveImg: {
      type: [Boolean],
      default: () => {
        return true
      },
    },
    postData: {
      type: [Object],
      default: () => {
        return {}
      },
    },
    plcontent:{
      type: [String],
      default: () => {
        return ''
      },
    },
    editorView: {
      type: Boolean,
      default: false,
    },
  },
  watch:{
    'value':{
      handler(newval,oldval){
        if(!newval) this.editor.setData(newval)
      }
    },
    'initValue':{
      handler(newval,oldval){
        this.editor.setData(newval)
      }
    }
  },
  data() {
    return {
      // 编辑器对象
      editor: undefined,
      // 编辑内容
      editorData: '',
      // 编辑器配置
      editorConfig: {
        // 配置语言
        language: 'zh-cn',
        ckfinder: {
          //后端URL
          // uploadUrl: "http://61.174.243.80:10789/file/upload"
          // uploadUrl: process.env.VUE_APP_BASE_API + '/swbus/modules/swCommon/uploadFile',
        },
        image: {
          toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side'],
        },
        fontSize: {
                        options: [
                        'default',
                            12,
                            14,
                            16,
                            18,
                            25,
                            30
                        ]
                    },
        //功能栏
        toolbar: {
          // items: ['Bold', 'Italic', 'Link','code']
        },
        contentEditable:false
        // 更多的配置....
      },
      haveWorld: 0,
    }
  },
  methods: {
    countChars(text) {
      // 需保留的常用标点符号
      const punctuation = ',，。.、/?:;"!@#$%^&*()《》（……￥）_+-={}【】[]""......\n\r'
      const parten = / /
      let count = 0
      // 过滤掉空白字符
      text = text.replace(/\s/g, '')
      for (let char of text) {
        if (punctuation.includes(char)) {
          count++
        } else if (char) {
          count++
        }
      }
      return count
    },
  },
  mounted() {
    // 初始化编辑器
    // window.ClassicEditor.create(document.querySelector('#editor'), this.editorConfig)
    //   .then((editor) => {
    //     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    //       return new upimg(loader)
    //     }
    //     console.log('创建成功')
    //     // 记录编辑器对象
    //     this.editor = editor
    //     //设置初始值
    //     this.editor.setData(this.value)
    //     // 监听内容变化
    //     editor.model.document.on('change:data', (e) => {
    //       // 输出当前内容
    //       let textLength = editor.getData().replace(/<.*?>/ig,"").length
    //       console.log("editor.getData().replace(/<.*?>/ig)",editor.getData().replace(/<.*?>/ig,""));
    //       if (textLength <= this.worldMax) {
    //         this.haveWorld = textLength
    //         this.editorData = editor.getData()
    //       } else if (this.isLimitWorld) {
    //         this.$message.warning('字数超了')
    //         editor.setData(this.editorData)
    //         return
    //       }
    //       this.$emit('input', editor.getData())
    //     })
    //     // 设置内容
    //     // editor.setData(this.editorData)
    //   })
    //   .catch((error) => {
    //     console.log('创建失败')
    //   })
    window.CKSource.Editor.create(
      document.querySelector("#editor"),
      this.editorConfig
    )
      .then((editor) => {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return new upimg(loader);
        };
        console.log("创建成功");
        // 记录编辑器对象
        this.editor = editor
         //设置初始值
         this.editor.setData(this.value)
        // 监听内容变化
        editor.model.document.on("change:data", (e) => {
          // 输出当前内容
          let textLength = editor.getData().replace(/<.*?>/gi, "").length;
          if (textLength <= this.worldMax) {
            this.haveWorld = textLength
            this.editorData = editor.getData()
          } else if (this.isLimitWorld) {
            this.$message.warning('字数超了')
            editor.setData(this.editorData)
            return
          }
          this.$emit("input", editor.getData());
        });
        // 设置内容
        // editor.setData(this.editorData)
       
      })
      .catch((error) => {
        console.log("创建失败");
      });
  },
  methods:{
    setData(){
      this.editor.setData(this.plcontent)
    }
  },
}
</script>
<style lang="scss">
.editor-view2{
  cursor: not-allowed;
  .ck-editor{
    pointer-events: none;
  }
}
.editor-view .ck-content {
  /* min-height: 200px; */
  max-width: 1186px;
  height: 200px;
}
.ck-powered-by-balloon{
  display: none !important;
}
.wordNumber {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  color: gray;
}
.editor-view{
.ck-content{
  height: 200px;
}
.ck-powered-by-balloon,
.ck-off[data-cke-tooltip-text="插入 HTML"],
.ck-toolbar__line-break {
  display: none !important;
}
.ck-source-editing-button {
  display: none !important;
}
.wordNumber {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  color: gray;
}
.ck-dropdown:has([data-cke-tooltip-text="插入媒体"]){
  display: none;
}
[data-cke-tooltip-text="分页符"]{
  display: none;
}
}
.editor-view1{
  .ck-content{
  height: 700px!important;
}
}

</style>
<template>
  <!-- <div class="content1" :style="{width}"> -->
  <div class="content1" >
    <!-- <div class="write">
      <div class="left" @click="drawer = true">
        <el-input placeholder="请留下您宝贵的意见和建议" v-model="input4" >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div> -->
    <el-button type="primary"  round class="submit" @click="isLogin">{{ isreply? "回复":"评论" }}</el-button> 
      
    <!--:size="size"  -->
    <el-drawer
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      direction="btt"
      :size="size1"
    >
      <div>
        <div class="writeCommetn">
          <el-input
            type="textarea"
            :rows="7"
            placeholder="请留下您宝贵的意见和建议"
            v-model="value.comment"
            :disabled="!userInfo || !userInfo.id"
            v-focus 
            style="font-size: 0.9rem;"
          >
          </el-input>
          <div class="rate" v-if="isHaveFiles">
            <span>
              <span style="float: left"> 附件： </span>
              <span style="float: left">
                <FileUpload :disabled="!userInfo || !userInfo.id" v-model="value.files"></FileUpload>
              </span>
            </span>
          </div>
          <div class="bottom" v-if="userInfo && userInfo.id"> 
            <span>
              <span style="margin-right: .32rem /* 10px -> .32rem */;" v-if="commentCheckSwitch == 1">评论需管理员审核后公开</span><el-button type="primary" round @click="()=>isreply? handleReply():handlebaocun()">发布评论</el-button>
            </span>
          </div>
          <div class="bottom" v-else>
           <el-button type="primary" round @click="$router.push('/login')">登录后发布评论</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      :visible.sync="drawer1"
      :with-header="false"
      :modal="false"
      direction="btt"
      :size="size"
    >
      <div>
        <div class="writeCommetn">
          <div class="rate">
            <span>任务评分：</span><el-rate v-model="point" style="margin-right: auto;"></el-rate>
            <span style="float: right;">
              <el-button type="primary" round @click="setPoint">确 定</el-button>
            </span>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: "XsWriteComment",
  // components: {
  //   [Notify.Component.name]: Notify.Component,
  // },
  props:{
    value:{
        type:Object,
        default: ()=>{ return {} }
      },
      commentListConfig:{
        type:Object,
        default: ()=>{ return {} }
      },
      commentCheckSwitch:{
        type:String,
        default:'0'
      },
      isHaveFiles:{
        type:Boolean,
        default:true
      },
      item:{
        type:Object,
        default: () => ({})
      },
      isreply:{
        type:Boolean,
        default:false
      }
  },
  data() {
    return {
      drawer: false,
      drawer1:false,
      point:'',
      width:0,
      size:0,
      size1:250
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted(){
    console.log('this.value',this.value);
    let width = document.body.offsetWidth
    this.width=width + 'px'
    console.log("widht",width);
    this.size = this.isHaveFiles? 250/window.screen.availHeight * 100 +'%' : 200/window.screen.availHeight * 100 +'%'
    this.$nextTick(()=>{
      console.log("this.$refs.ipt",this.$refs.ipt);
      if(this.$refs.ipt){
        this.$refs.ipt.focus()
      }
    })
    if(this.isHaveFiles){
      this.size1 = 300
    }
  },
  
  methods:{
    // submit(){
    //   Promise.all([this.feedback(),this.point()]).then(res=>{
    //     this.$notify({ type: 'success', message: '提交成功' })
    //     // this.$toast.success('成功文案');
    //     this.drawer = false
    //   })
    // },
    // feedback(){
    //   this.$apis(this.commentListConfig.feedback,this.value, 'POST')
    //     .then((res) => {
     
    //     })
    //     .catch((error) => {
    //       //this.$error("操作失败!" );
    //     })
    // },
    // point(){
    //  return this.$apis(this.commentListConfig.point,this.value, 'POST')
    //     .then((res) => {
    //       this.commentList = this.commentList.concat(res.data.records)
    //       this.loading = false
    //       this.page.pageNum += 1
    //       if(this.commentList.length == res.data.total) this.noMore = true
    //     })
    //     .catch((error) => {
    //       //this.$error("操作失败!" );
    //     })
    // }
    isLogin(){
      if(this.userInfo && this.userInfo.id){
        this.drawer = true
      }else{
        this.$router.push('/login')
      }
    },
    //回复
    handleReply() {
        let data = {}
        data.taskId = this.$route.query.taskId
        data.commentId = this.item.id
        data.reply = this.value.comment
        console.log(data)
        let url = '/swbus/frontapis/swUcTaskCommentReply/submitSwUcTaskCommentReply'
        this.$apis(url, data)
          .then((res) => {
            this.$emit("submitSuccess",1)
              this.drawer = false
              this.commentCheckSwitch == '1'? this.$notify({ type: 'success', message: '已提交回复，待管理员审核' }) :this.$notify({ type: 'success', message: '提交成功!' })
              this.value.comment = ''
          })
          .catch((error) => {})
    },
    handlebaocun() {
          let data = this.value
          // data.taskId = this.$route.query.taskId
          // let url = '/swbus/frontapis/swUcTaskComment/submitTaskComment'
          let url
          if(this.$route.query.taskId){
            data.taskId = this.$route.query.taskId
            url = '/swbus/frontapis/swUcTaskComment/submitTaskComment'
          }else{
            data.articleNo = this.$route.query.id
           url = '/swbus/frontapis/swBlogComment/submitSwBlogComment'
          }
          this.$apis(url, data)
            .then((res) => {
              this.$emit("submitSuccess",1)
              this.drawer = false
              this.commentCheckSwitch == '1'? this.$notify({ type: 'success', message: '已提交回复，待管理员审核' }) :this.$notify({ type: 'success', message: '提交成功!' })
            })
            .catch((error) => {
              
            })
    },
    setPoint(){
      var data = {
          point:this.point,
          // taskId: this.$route.query.taskId,
        }
        let url
        let method
          if(this.$route.query.taskId){
            data.taskId = this.$route.query.taskId
            url = '/swbus/frontapis/swUcTaskComment/submitTaskComment'
            method = 'post'
          }else{
            data.blogId = this.$route.query.id
           url = '/swbus/frontapis/swBlogPoint/submitBlogPoint'
           method = 'get'
          }
        this.$apis(url + this.$format.handleQuery(data), data,method)
          .then((res) => {
            this.$emit("submitSuccess",1)
              this.drawer1 = false
          })
          .catch((error) => {
            this.drawer1 = false
          })
    }
  }
};
</script>

<style scoped lang="scss">

.content1 {
  // overflow: hidden;
  // background: #ffffff;
  // width: 22.08rem /* 690px -> 22.08rem */;
  // height: 2.88rem /* 90px -> 2.88rem */;
  // display: flex;
  //   justify-content: center;
  //   align-items: center;
  .write {
    width: 22.08rem /* 690px -> 22.08rem */;
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
      width: 100%;
    }
    .right {
      font-size: 0.512rem /* 16px -> .512rem */;
      width: 10%;
      img {
        vertical-align: top;
        width: 0.96rem /* 30px -> .96rem */;
        height: 0.96rem /* 30px -> .96rem */;
      }
      // margin-top: 0.64rem /* 20px -> .64rem */;
    }
  }
  // box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.1);
  ::v-deep .el-input {
    top: 0.32rem; /* -10px -> -0.32rem */
  }
  .writeCommetn {
    width: 22.08rem /* 690px -> 22.08rem */;
    margin: 0 auto;
    padding-top: 0.64rem /* 20px -> .64rem */;
    font-size: 0.768rem /* 24px -> .768rem */;
    .rate {
      display: flex;
      justify-content: space-between;
      margin-top: .64rem /* 20px -> .64rem */;
    }
  }
  ::v-deep .el-input__inner{
  height: 2rem;
}
.bottom{
  display: flex;
  justify-content: right;
  margin-top: .64rem /* 20px -> .64rem */;
  align-items: center;
  font-size: .768rem /* 24px -> .768rem */;
  color: rgb(230, 162, 60);
}
.submit{
  // position: fixed;
  // bottom: .96rem /* 30px -> .96rem */;
  // right: 1.6rem /* 50px -> 1.6rem */;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}
}
</style>
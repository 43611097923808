<template>
  <div>
    <div
    >
      <div class="content contentFather"
      v-infinite-scroll="load"
      :infinite-scroll-disabled="disabled"
      style="overflow: auto;"
      >
        <div class="items">
          <slot name="content" :list="list"></slot>
          <!-- <div class="item">
            <div class="top">
              <div>
                <img src="@/assets/bl/Rewards.png" alt="" />
                <span>奖励</span>
                <span>积分200</span>
              </div>
              <div>
                <el-tag>任务发布中</el-tag>
              </div>
            </div>
            <div class="title">
              《申威平台.NET 5、Asp.NET 5及SDK研发》需求发布
            </div>
            <el-divider></el-divider>
            <div class="time">
              <span>发布时间： 2023-11-01 09:00</span>
              <span>截止时间： 2024-01-31 18:00</span>
            </div>
            <div class="introduce">
              近年来随着微软的发力，.NET技术得到了大量的使用，.NET生态得到了长足的发展。但申威平台尚未支持.NET运行时及SDK，基于.NET框架开发的应用程序在申威平台无法运行。
            </div>
          </div> -->
          <p v-if="loading">加载中...</p>
          <p v-if="noMore">没有更多了</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pages",
  props: {
    config: {
      type: Object,
      default: {},
    },
    postData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      noMore: false,
      loading: false,
      list: [],
      page: {
        pageNum: 1,
        size: 10,
      },
      height:'1px'
    };
  },
  mounted(){
    this.$nextTick(()=>{
      // this.height='auto'
    })
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  mounted(){
    this.getList()
  },
  methods: {
    load() {
      this.getList();
    },
    getList() {
      this.loading = true;
      let url =
        this.config.list +
        this.$format.handleQuery({
          ...this.postData,
          current: this.page.pageNum,
          size: this.page.size,
        });
      this.$apis(url, this.postData, this.config.method)
        .then((res) => {
          this.list = this.list.concat(res.data.records);
          if (this.list.length == res.data.total) this.noMore = true;
          this.loading = false;
          this.page.pageNum += 1;
        })
        .catch((error) => {
          // this.treeData = []
        });
    },
  },
};
</script>

<style scoped lang="scss">
.contentFather{
    // height: calc(100vh - 2.56rem);
    height: calc(100vh - 2.56rem);
 
}
.content {
  margin: 0 auto;
  .items {
    font-size: 0.768rem /* 24px -> .768rem */;
    margin-top: 0.928rem /* 29px -> .928rem */;
    overflow: hidden;
  }
  p {
    text-align: center;
  }
}
</style>
import  Layout from '@/components/Layout.vue';


export default {
    path: "/support",
    component: Layout,
    redirect: "index",
    children: [
      {
        path: "index",
        component: (resolve) => require(["@/views/sw/support/index"], resolve),
        name: "支持与服务",
        meta: { title: "支持与服务", icon: ''},
      },
      {
        path: "authentication",
        component: (resolve) => require(["@/views/sw/support/authentication/index"], resolve),
        name: "适配认证",
        meta: { title: "适配认证", icon: ''},
      },

      {
        path: "feedback",
        component: (resolve) => require(["@/views/sw/support/feedback/index"], resolve),
        name: "意见反馈",
        meta: { title: "意见反馈", icon: ''},
      },
      
      {
        path: "qa",
        component: (resolve) => require(["@/views/sw/support/qa/index"], resolve),
        name: "常见问题",
        meta: { title: "常见问题", icon: ''},
      },
      {
        path: "qa/detail",
        component: (resolve) => require(["@/views/sw/support/qa/detail"], resolve),
        name: "常见问题",
        meta: { title: "常见问题", icon: ''},
      },

    ],
};

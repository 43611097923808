import axios from 'axios'
import dictData from './dictData.js'
import XEUtils from 'xe-utils'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import { Notify } from 'vant';
import { api } from '@/api/api.js'
import store from '@/store'
// 打印
export function log (val,title='打印------------') {
    console.log(title,val)
}

// 消息提示
export function success (msg, duration = 2000) {
    Notify({
      message: msg,
      duration:duration,
      type: 'success'
    })

}
export function warning (msg, duration = 3000) {
    Notify({
      message: msg,
      duration:duration,
      type: 'warning'
    })

}
export function error (msg, duration = 2000) {
    console.log('error',msg)
    Notify({
      message: msg,
      duration:duration,
      type: 'danger'
    });
}



export function getprice (e, zhi) {
    let texts = ''

    if (zhi == 'F') {
        return texts
    }
    if (e && e.isExport == 'T') {
        if (e && e.currency && e.exchangeRate) {
            texts = parseFloat(zhi / e.exchangeRate).toFixed(2)
        } else {
            if (zhi || zhi == 0) {
                texts = parseFloat(zhi).toFixed(2)
            }
        }
    } else {
        if (zhi || zhi == 0) {
            texts = parseFloat(zhi).toFixed(2)
        }
    }

    return texts
}
// 处理 query
export function handleQuery (query) {
    let text = '?'
    if (query && Object.keys(query).length > 0) {
        for (let i in query) {
            if (Object.keys(query)[0] == i) {
                text = text + i + '=' + query[i]
            } else {
                text = text + '&' + i + '=' + query[i]
            }
        }
    } else {
        text = ''
    }
    return text
}
export function debounce (fn, delay) {
    let timer = null

    return function () {
        let context = this;
        let args = arguments;
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, delay)
    }
}

export function getDictItemUrl (key, value, list2) {
    let list = list2?list2:dictData[key]
    if (list) {
        for (let i in list) {
            if (value == list[i].value) {
                return list[i].url
            }
        }
    }
    return ''
}

export function getValue (key, value,list2) {

    let list = list2?list2:dictData[key]
    if (list) {
        for (let i in list) {
            if (value == list[i].value) {
                return list[i].label
            }
        }
    }
}
export function getFormat (key, value,list) {
    // console.log('getFormat:: key, value', key, value)
    if (!key || value === undefined) {
        return ''
    }

    return getValue(key, value,list)
}
export function getFormat2 (key, cellValue,list) {
    if (!cellValue && cellValue != 0) {
        return ''
    }
    return getValue(key, cellValue,list)
}
/**
 * 表格时间格式化 utc转换
 */
export function formatDate (cellValue, type) {
    if (cellValue == null || cellValue == '') return '';

    var date = new Date(cellValue);
    var year = date.getFullYear();
    var month =
        date.getMonth() + 1 < 10
            ? '0' + (date.getMonth() + 1)
            : date.getMonth() + 1;
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    if (type == 1) {
        return (
            year + '-' + month
        );
    } else if (type == 2) {
        return (
            year + '-' + month + '-' + day
        );
    } else {
        return (
            year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
        );
    }
}
// 数组内部去重

export function handeleGetCode (arr) {
    let newArr = []
    console.log('arr---------------------', arr)
    if (arr && arr.length > 0) {
        for (let i in arr) {
            newArr.push(arr[i].productCode)
        }
    }
    return newArr
}
// 数组内部去重

export function handeleArr (arr) {
    let newArr = []
    if (arr && arr.length > 0) {
        arr.sort()
        let preItemValue = ''// arr[0]
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] && arr[i] !== preItemValue) {
                newArr.push(arr[i])
                preItemValue = arr[i]
            }
        }
    }
    return newArr
}
// 去重
export function handeleRepeatFilter (mapList, DataList) {
    console.log('handeleRepeatFilter', mapList, DataList)
    let arr = []
    if (DataList && DataList.length > 0) {
        for (let j in DataList) {
            let count = 0
            if (mapList && mapList.length > 0) {
                for (let i in mapList) {
                    if (mapList[i].productCode == DataList[j].productCode) {
                        count = count + 1
                    }
                }
            }
            if (count == 0) {
                arr.push(DataList[j])
            }
        }
        return arr
    } else {
        return DataList
    }
}
// 还原
export function handeleRest (list) {
    let arr = []
    if (list && list.length > 0) {
        var gettree = function (data, node) {
            for (let i in data) {
                if (Reflect.has(data[i], 'childs')) {
                    Reflect.deleteProperty(data[i], 'childs');
                }
                arr.push(data[i])

                // if (data[i].childs && data[i].childs.length > 0) {
                //     gettree(data[i].childs, data[i])
                // }
            }
        }
        gettree(list)
    }
    // if(arr&&arr.length>0){
    // 	for(let i in list){
    // 		if (Reflect.has(list[i], 'childs')) {
    // 		    Reflect.deleteProperty(list[i], 'childs');
    // 		}
    // 	}
    // }
    return arr
}
// 删除数据
export function handeledel (list, row, that) {
    if (list && list.length > 0) {
        var gettree = function (data, node) {
            for (let i in data) {
                if (Reflect.has(data[i], 'name')) {
                    // Reflect.deleteProperty(tagList[i], '_X_ID');
                } else {
                    if (row.productCode == data[i].productCode) {
                        data.splice(i, 1);
                    }
                }
                if (data[i] && data[i].children && data[i].children.length > 0) {
                    gettree(data[i].children, data[i])
                }
            }
        }
        gettree(list)
    }
}
// 分组
export function handeleGroup (data, keys) {
    const result = []
    let dataKeys = []
    let data2 = []
    if (data && data.length > 0) {
        for (let j in data) {
            if (data[j][keys]) {
                dataKeys.push(data[j])
            } else {
                data2.push(data[j])
            }
        }
    }
    XEUtils.each(XEUtils.groupBy(dataKeys, keys), (childs, key) => {
        result.push({
            name: key,
            children: childs
        })
    })
    // result = result.concat(data2);
    return result.concat(data2);
}

// 加载远程字典数据
export async function getDictData(code,orgType='',method='post') {
  // 机构("baseOrg", "机构"),
  // 信息类别("baseDataCategory", "信息类别"),
  // 信息提供者("baseProvider", "信息提供者"),
  // 使用场景("baseSense", "使用场景"),
  let p = await getDictData2(code,orgType,method)
  return p

}
export  function getDictData2(code,orgType,method='post') {
  return new Promise((resolve, reject) => {
      let data = {
        code: code
      }
      if(orgType){
        data.orgType = orgType
      }
      let url = '/system/common/loadDictOptions'
      if (!code) {
          resolve([]);
          return
      }
	  let notLimitReqTime = true;//不限制请求时间间隔
      api(url, data, method, notLimitReqTime).then(res => {
          resolve(res.data);
      })
      .catch((error) => {
          resolve([]);
          // reject(error);
      });
  })
}
export  function getPermi(value) {
  let p = false
  const all_permission = "*:*:*";
  const permissions = store.getters && store.getters.permissions

  if (value && value instanceof Array && value.length > 0) {
    const permissionFlag = value

    const hasPermissions = permissions.some(permission => {
      return all_permission === permission || permissionFlag.includes(permission)
    })

    if (hasPermissions) {
      p = true
    }
  }
  return p
}


export  function formatDuring(mss) {
  let days = parseInt(mss / (1000 * 60 * 60 * 24));
  let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = (mss % (1000 * 60)) / 1000;
  let ms = mss % 1000;
  let ss = "";
  if(days > 0){
    ss += ays + " 天 ";
  }
  if(hours > 0){
    ss += hours + " 小时 ";
  }
  if(minutes > 0){
    ss += minutes + " 分钟 ";
  }
  if(seconds > 0){
    ss += seconds + " 秒 ";
  }
  // if(ms > 0){
  //   ss += ms + " 毫秒 ";
  // }
  return ss;
}


export function dateFormatBy(date, format) {
  date = date ? new Date(date) : null
    // let format = 'yyyy-MM-dd hh:mm:ss';
    if (date && date != 'Invalid Date') {
        var o = {
            "M+": date.getMonth() + 1, //month
            "d+": date.getDate(), //day
            "h+": date.getHours(), //hour
            "m+": date.getMinutes(), //minute
            "s+": date.getSeconds(), //second
            "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
            "S": date.getMilliseconds() //millisecond
        }
        if (/(y+)/.test(format)) format = format.replace(RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
            if (new RegExp("(" + k + ")").test(format))
                format = format.replace(RegExp.$1,
                    RegExp.$1.length == 1 ? o[k] :
                        ("00" + o[k]).substr(("" + o[k]).length));
        return format;
    }
    return '';

}

/*
["/操作系统/银河麒麟/桌面操作系统", "/操作系统/银河麒麟/服务器操作系统"]
转化为
银河麒麟桌面操作系统, 银河麒麟服务器操作系统
*/
export function simpleLastItemOfJsonList2(jsonStr) {
  if(!jsonStr){
    return ''
  }
  let list = jsonStr && JSON.parse(jsonStr)?JSON.parse(jsonStr):''

  let str = list.join(", ")
  return str
}


/*
["/操作系统/银河麒麟/", "/操作系统/银河麒麟/"]
转化为
银河麒麟, 银河麒麟
*/
export function simpleLastItemOfJsonList(jsonStr) {
  if(!jsonStr){
    return ''
  }
  let list = jsonStr && JSON.parse(jsonStr)?JSON.parse(jsonStr):''
  if(!list || list.length == 0){
    return ''
  }
  let index = 0
  let str = ''
  for(let i = 0; i < list.length; i++){
    let item = list[i]
    if(item){
      if(index > 0){
        str += ", "
      }
      let arr = item.split('/')
      if(arr && arr.length > 0){
        for(let k = arr.length - 1; k >= 0; k--){
          let part = arr[k]
          if(part){
            str += part
            break
          }
        }
      }
      index++
    }

  }
  return str
}

/*
/兼容适配分类/业务软件及系统/企业级应用服务/
转化为
业务软件及系统/企业级应用服务
*/
export function simpleRemoveFirstPart(jsonStr) {
  if(!jsonStr){
    return ''
  }
  let arr = jsonStr.split('/')
  if(arr && arr.length > 0){
    let index = 0
    let str = ''
    for(let k = 0; k < arr.length - 1; k++){
      let part = arr[k]
      if(part){
        if(index > 0){
          if(index > 1){
            str += "/"
          }
          str += part
        }
        index++
      }
    }
    return str
  }else{
    return jsonStr
  }
}

export function convertDateString(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}


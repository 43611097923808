<template>
  <div class="upload-file">
    <!-- :limit="limit" -->
    <!--      :multiple="multiple" -->
    <el-upload
      :action="uploadUrl"
      :before-upload="handleBeforeUpload"
      :file-list="fileList"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-success="handleUploadSuccess"
      :show-file-list="false"
      :headers="headers"
      class="upload-file-uploader"
      ref="upload"
      :accept="accept"
      :data="postData"
      :disabled="disabled"
    >
      <!-- 上传按钮 -->
      <el-button v-if="$slots&&!$slots.default" icon="el-icon-upload" size="mini" type="primary" :disabled="disabled">选取文件</el-button>
      <slot>

      </slot>
      <!-- 上传提示 -->
      <div  slot="tip" class="el-upload__tip" v-if="showTip">
          <div >
              请上传
              <template v-if="fileSize"> 大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b> </template>
              <template v-if="fileType&&fileType.length>0"> 格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b> </template>
              <!-- 的文件,不超过{{ limit }}个 -->
              的文件
          </div>
      </div>
    </el-upload>

    <!-- 文件列表 -->
    <transition-group v-if="type==1" class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
      <li :key="file.url" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileList">
        <el-link :href="file.url" :underline="false" target="_blank">
          <span class="el-icon-document"> {{ getFileName(file.name)?getFileName(file.name):file.url }} </span>
        </el-link>
        <div class="ele-upload-list__item-content-action">
          <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
        </div>
      </li>
    </transition-group>
	<transition-group v-if="type==2" class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul">
	  <li :key="file[keyConfig.url]" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in fileList" >
	    <el-link :href="file[keyConfig.url]" :underline="false" target="_blank">
	      <span class="el-icon-document"> {{ file[keyConfig.name]?file[keyConfig.name]:getFileName(file[keyConfig.url])}} </span>
	    </el-link>
	    <div class="ele-upload-list__item-content-action">
        <!-- <el-link :underline="false" @click="handleshangyi(fileList,index)" v-if="index!=0" type="primary">上移</el-link>
        <el-link :underline="false" @click="handlexiayi(fileList,index)" v-if="index<fileList.length" type="primary">下移</el-link> -->
	      <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
	    </div>
	  </li>
	</transition-group>
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    multiple:{
      type:[Boolean],
      default:true
    },
    isAll:{
      type:[Boolean],
      default:false
    },
    // 值
    value: [String, Object, Array],
    // 数量限制
    limit: {
      type: Number,
      // default: 5,
      default: 1,
    },
    limit1: {
      type: Number,
      default: 1,
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 50,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg'] "txt",
    fileType: {
      type: Array,
      // default: () => ['doc', 'xls', 'ppt', 'pdf','zip','rar'],
      default: () => ['jpg', 'png', 'jpeg'],
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },

    accept: [String],
	//额外请求参数
    postData:{
          type: [Object],
          default: () => {
              return {}
          }
    },
    disabled:{
        type: Boolean,
        default: false
    },
	//1 字符串 2数组
	type:{
	    type: [String, Number],
	    default:2
	},
	//数组情况下的，key
	keyConfig: {
	    type: [Object],
	    default: () => {
	        return {
	          name: "fileName",
	          url: 'url'
	        }
	    }
	}
  },
  data() {
    return {
      number: 0,
      uploadList: [],
      uploadUrl: this.$uploadUrl, //process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers:this.$headers,
      fileList: [],
    };
  },
  watch: {
    value: {
      handler(val) {
		if(this.type==2){
			if (typeof val === "string") {
			  if(val){
				  let p = JSON.parse(val)
				  this.fileList = p;
			  }else{
				  this.fileList = [];
			  }
			}else{
				if(val&&val.length>0){
					this.fileList = val;
				}else{
					this.fileList = [];
				}
			}

		}else{
			if (val) {
			  let temp = 1;
			  // 首先将值转为数组
			  const list = Array.isArray(val) ? val : this.value.split(',');
			  // 然后将数组转为对象数组
			  this.fileList = list.map(item => {
			    if (typeof item === "string") {
			      item = { name: item, url: item };
			    }
			    item.uid = item.uid || new Date().getTime() + temp++;
			    return item;
			  });
			} else {
			  this.fileList = [];
			  return [];
			}
		}

      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 是否显示提示
    showTip() {
      return this.isShowTip && (this.fileType || this.fileSize);
    },
  },
  methods: {
    swapItems(arr, index1, index2) {
            arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            return arr;
    },
    handleshangyi(arr,index){
      if(index == 0) {
          return;
      }
      this.swapItems(arr, index, index - 1);
      let that = this
      if(that.type==2){
          that.$emit("input", JSON.stringify(that.fileList));
      }else{
        that.$emit("input", that.listToString(that.fileList));
      }
    },
    handlexiayi(arr,index){
      if(index == arr.length -1) {
          return;
      }
      this.swapItems(arr, index, index + 1);
      let that = this
      if(that.type==2){
          that.$emit("input", JSON.stringify(that.fileList));
      }else{
        that.$emit("input", that.listToString(that.fileList));
      }
    },
    // 上传前校检格式和大小
    handleBeforeUpload(file) {
      console.log("file",file);
      // 校检文件类型
      if (this.fileType&&this.fileType.length>0) {
        let fileExtension = "";
        if (file.name.lastIndexOf(".") > -1) {
          fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
        }
        const isTypeOk = this.fileType.some((type) => {
          if (file.type.indexOf(type) > -1) return true;
          if (fileExtension && fileExtension.indexOf(type) > -1) return true;
          return false;
        });
        if (!isTypeOk) {
          this.$modal.msgError(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
          return false;
        }
      }
      this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },
    // 文件个数超出
    handleExceed() {
      // this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
      this.$modal.msgError(`只能上传一个文件！`);
    },
    // 上传失败
    handleUploadError(err) {
      this.$fun.handleUploadError(this,err)
      // this.$modal.msgError("上传失败，请重试");
      this.$modal.closeLoading()
    },
    // 上传成功回调
    handleUploadSuccess(res) {
      //只允许上传一个并且再次上传取代上次上传的文件
      this.fileList = []
      console.log('handleUploadSuccess-----',res)
      this.$fun.handleUploadSuccess(res,this)

      // let url = res.url ? res.url : (res.data && res.data.url ? res.data.url : '');
      // this.uploadList.push({ name: url, url: url });
      // if (this.uploadList.length === this.number) {
      //   this.fileList = this.fileList.concat(this.uploadList);
      //   this.uploadList = [];
      //   this.number = 0;
      //   this.$emit("input", this.listToString(this.fileList));
      //   this.$modal.closeLoading();
      // }
       if(!this.fileList.length) this.$error('上传文件失败!')
       this.$modal.closeLoading()

    },
    // 删除文件
    handleDelete(index) {
      // this.fileList.splice(index, 1);
      // //this.$emit("input", this.listToString(this.fileList));
      // this.$emit("input", this.listToString(this.fileList));
	  this.$fun.handleDelete(index,this)
    },
    // 获取文件名称
    getFileName(name) {
	  if(!name){
		  return "";
	  }
      if (name.lastIndexOf("/") > -1) {
        return name.slice(name.lastIndexOf("/") + 1);
      } else {
        return "";
      }
    },
    // 对象转成指定字符串分隔
    listToString(list, separator) {
      let strs = "";
      separator = separator || ",";
      for (let i in list) {
        strs += list[i].url + separator;
      }
      return strs != '' ? strs.substr(0, strs.length - 1) : '';
    }
  }
};
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>

<template>
  <div class="content">
    <div class="introduce">评论({{ commentList.length }})</div>
    <div class="comment" :style="{ height: height }" >
      <div class="infinite-list-wrapper" >
        <ul
          class="list"
       
           style="overflow: auto;"
        >
          <li v-for="(item,index) in commentList" class="list-item" :key="index">
            <div class="item">
              <div>
                <img src="@/assets/bl/info.png" alt="" />
                <span>{{item && item.user && item.user.name ? item.user.name : item.createBy }}</span>
                <!-- <span style="font-size: .768rem /* 24px -> .768rem */;color: #898989;margin-left: .16rem /* 5px -> .16rem */;"  v-if="userInfo && userInfo.id && taskInfo && taskInfo.taskState == '01' && taskInfo.createBy == userInfo.id && item.uid != userInfo.id">
                  <span>悬赏操作：</span><span style="color: #0256FF;" @click="admit(item)" v-if="taskInfo && taskInfo.taskState == '01' && taskInfo.myPublish && !item.adoptStatus && userInfo && taskInfo.createBy == userInfo.id ">采纳评论</span>
                  <span style="color: #0256FF;" @click="cancelAdmit(item)"  v-if="taskInfo && taskInfo.taskState == '01' && taskInfo.myPublish && item.adoptStatus && userInfo &&taskInfo.createBy == userInfo.id ">取消采纳</span>
                  <span style="color: #0256FF;margin-left: .32rem /* 10px -> .32rem */" @click="rewordItem = item;rewardDialogVisible = true">奖赏评论</span>
                
                </span> -->
                
              </div>
              <div class="rightContent">
                <div style="font-size: .512rem /* 16px -> .512rem */;">
                    <span class="rewordSorce" v-if="item && item.adoptStatus == 1">
                    <img src="@/assets/bl/thumb.png" alt="">
                    作者已采纳
                  </span>
                  <span v-if="(item && item.wealth && taskInfo.taskState == '02') || (item && item.wealth && taskInfo.createBy == userInfo.id)" class="rewordSorce" style="margin-left: .32rem /* 10px -> .32rem */;">
                    奖赏 {{ item.wealth }}财富
                  </span>
                  </div>
                <div  class="centered ck-content" style="height: auto;overflow: hidden; overflow-x: hidden;margin-top: .16rem /* 5px -> .16rem */;">
                      <div class="fuwenben-detail" v-html="item.comment" ></div>
                 </div>
                <div class="time timeSpecile">
                  <!-- <span class="left" @click="drawer = true,clickReview = item">{{ item.commentReplies && item.commentReplies.length || 0}}回复 ></span> -->
                  <span class="right">发布于：{{ item.updateTime }}</span>
                  <span v-if="item && item.files" @click="downLoad(item.files)"><img src="@/assets/bl/specile.png"/> 附件</span>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </li>
        </ul>
        <p v-if="loading">加载中...</p>
        <p v-if="noMore">没有更多了</p>
      </div>
    </div>

    <el-drawer
      :visible.sync="drawer"
      direction="btt"
      size="80%"
      :show-close="false"
    >
    <template slot="title">
        <div class="title">
            <img src="@/assets/HeadImg/open.png" alt="" @click="drawer = false">
            <span>{{clickReview.commentReplies && clickReview.commentReplies.length || 0}}条回复</span>
            <span style="opacity: 0;">0</span>
        </div>
        </template>
      <div class="review">
        <div class="comment">
          <div class="item">
            <div>
              <img src="@/assets/bl/info.png" alt="" />
              <span>{{ clickReview && clickReview.user && clickReview.user.name ? clickReview.user.name : clickReview.createBy}}</span>
            </div>
            <div class="rightContent">
              <div  class="centered ck-content" style="height: auto;overflow: hidden;">
                      <div class="fuwenben-detail" v-html="clickReview.content" v-highlight></div>
                 </div>
              <div class="time">
                <span class="left">{{clickReview.commentReplies && clickReview.commentReplies.length || 0}}回复 ></span>
                <span class="right">发布于：{{ clickReview.updateTime }}</span>
              </div>
            </div>

            <el-divider></el-divider>
          </div>
          <div class="introduce">全部回复</div>
          <div class="infinite-list-wrapper" style="overflow: auto">
            <ul
              class="list"
            >
              <li v-for="(item,index) in clickReview.commentReplies" class="list-item" :key="index">
                <div class="item">
                  <div>
                    <img src="@/assets/bl/info.png" alt="" />
                    <span>{{item.name}}</span>
                  </div>
                  <div class="rightContent">
                    <div  class="centered ck-content" style="height: auto;overflow: hidden;">
                      <div class="fuwenben-detail" v-html="item.content"></div>
                     </div>
                    <div class="time">
                      <!-- <span class="left">10回复 ></span> -->
                      <span class="right">发布于：{{ item.updateTime }}</span>
                    </div>
                  </div>

                  <el-divider></el-divider>
                </div>
              </li>
            </ul>
            <p >没有更多了</p>
          </div>
        </div>
        <!-- <div class="bottomWrite1" :style="{ width, marginLeft: '-0.7rem'}">
          <WriteComment ></WriteComment>
        </div> -->
      </div>
    </el-drawer>
    <!-- 采纳评论 -->
    <el-dialog
      title="采纳评论"
      :visible.sync="addmitDialogVisible"
      width="70%"
      center
      :before-close="handleClose">
      <span style="font-size: .832rem /* 26px -> .832rem */;">您确定采纳此评论？结束任务前均可修改采纳评论。</span>
  <span slot="footer" class="dialog-footer">
    <div style="text-align: right;">
      <el-button @click="addmitDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="adaptCommit">确 定</el-button>
    </div>
  </span>
</el-dialog>
 <!-- 取消采纳评论 -->
 <el-dialog
      title="取消采纳评论"
      :visible.sync="cancelDialogVisible"
      width="70%"
      center
      :before-close="handleClose">
      <span style="font-size: .832rem /* 26px -> .832rem */;">您已采纳此评论，确定取消采纳？结束任务前均可修改采纳评论。</span>
  <span slot="footer" class="dialog-footer">
    <div style="text-align: right;">
      <el-button @click="cancelDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="cancelAdmitComment">确 定</el-button>
    </div>
  </span>
</el-dialog>
 <!-- 奖赏评论 -->
 <el-dialog
      title="奖赏评论"
      :visible.sync="rewardDialogVisible"
      width="80%"
      center
      :before-close="handleClose">
      <div style="overflow: hidden;">
        <p style="color: #0056ff;font-size: .832rem /* 26px -> .832rem */;">每一种付出 都值得被鼓励</p>
        <div style="">
          <el-button type="primary" plain round @click="commentScore = 1">1财富</el-button>
          <el-button type="primary" plain round @click="commentScore = 5">5财富</el-button>
          <el-button type="primary" plain round @click="commentScore = 10">10财富</el-button>
          <el-button type="primary" plain round @click="commentScore = 15">15财富</el-button>
          <el-button type="primary" plain round @click="commentScore = 20" style="margin-top: .32rem /* 10px -> .32rem */;margin-left: 0;">20财富</el-button>
        </div>

        <div class="" style="margin-top: .64rem /* 20px -> .64rem */;">
          <span><span style="color: red;">*</span><span style="font-size: .768rem /* 24px -> .768rem */;">奖赏积分</span></span> <br />
          <el-input-number v-model="commentScore" controls-position="right" :min="1" style="margin-top: .32rem /* 10px -> .32rem */;width: 100%;"></el-input-number>
        </div>
      </div>
  <span slot="footer" class="dialog-footer">
    <div style="text-align: right;">
      <el-button @click="rewardDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleAssigned">确 定</el-button>
    </div>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: "DeveloperComments",
  props:{
    commentListConfig:{
      type:Object,
      default: ()=>{ return {} }
    },
    taskInfo:{
      type:Object,
      default: ()=>{ return {} }
    },
    id:{
      type:[String,Number],
      default:''
    }
  },
  data() {
    return {
      addmitDialogVisible:false,
      cancelDialogVisible:false,
      rewardDialogVisible:false,
      count: 10,
      loading: false,
      height: "auto",
      // height: "1000px",
      writeHeight: 0,
      width: 0,
      drawer: false,
      commentList:[],
      page:{
        pageNum:1,
        // pageSize:10
        pageSize:5
      },
      noMore:false,
      clickReview:{},
      submitComments:{
        softwareId:this.id
      },
      addmitItem:{},
      cancelItem:{},
      rewordItem:{},
      commentScore:''
    };
  },
  computed: {
    disabled() {
      return this.loading || this.noMore;
    },
  },
  computed:{
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    window.onscroll =  () => {
       this.isInViewPortOfTwo(this.getComments,20)
    }
  },
  created(){
    this.init()
  },
  methods: {
    isInViewPortOfTwo(fn,distance = 0) {
     let scrolTop = document.documentElement.scrollTop
     let pageHeight = document.body.scrollHeight
     let windowScreen = window.innerHeight
     let difference = pageHeight - windowScreen - scrolTop - distance
     if(difference <= 0 && !this.loading && !this.noMore) {
      fn.call(this)
     }
    },
    init(){
      this.page.pageNum = 1
      this.commentList = []
      this.loading = false
      this.noMore = false
      this.getComments()       
    },
    handleAssigned() {
      this.rewardDialogVisible = false
      let p = {
        assignedScores: this.commentScore,
        commentId: this.rewordItem.id,
        taskId: this.$route.query.taskId,
      }
      let url = '/swbus/frontapis/swTaskRewards/assignedCommentScores'
      this.$apis(url, p).then((res) => {
        // this.$success('奖赏评论成功！')
        this.$notify('奖赏评论成功！')
        this.page.pageNum = 1
        this.commentList = []
        this.getComments()
      })
    },
    admit(item){
      this.addmitItem = item
      this.addmitDialogVisible = true
    },
    cancelAdmit(item){
      this.cancelItem = item
      this.cancelDialogVisible = true
    },
    getComments(){
      console.log(213123123);
      this.loading = true
      let data = {
        current: this.page.pageNum,
        size: this.page.pageSize,
        articleNo: this.$route.query.id,
      }
      let url = this.commentListConfig.list +this.$format.handleQuery(data)
      this.$apis(url,data, 'POST')
        .then((res) => {
          this.commentList = this.commentList.concat(res.data.records)
          this.loading = false
          console.log("this.loading",this.loading);
          this.page.pageNum += 1
          if(this.commentList && this.commentList.length == res.data.total) this.noMore = true
        })
        .catch((error) => {
          //this.$error("操作失败!" );
        })
    },
    load() {
      console.log(1111);
      this.getComments()
    },
    adaptCommit(item) {
      this.addmitDialogVisible = false
      let data = {
        taskId: this.$route.query.taskId,
        commentId: this.addmitItem.id,
      }
      // this.$apis('/swbus/modules/swUcTaskComment/adopt', data, 'post')
      //       .then((res) => {
      //         this.$notify("采纳成功！")
      //         this.init()
      //       })
      //       .catch((error) => {
      //         //this.$error('提交失败');
      //       })
    },
    cancelAdmitComment(item){
      this.cancelDialogVisible = false
      let data = {
        taskId: this.$route.query.taskId,
        commentId: this.cancelItem.id,
      }
      // this.$apis('/swbus/modules/swUcTaskComment/canclAdopt', data, 'post')
      //       .then((res) => {
      //         this.$notify("取消采纳成功！")
      //         this.init()
      //       })
      //       .catch((error) => {
      //         //this.$error('提交失败');
      //       })
    },
    downLoad(item) {
      window.open(JSON.parse(item)[0].url)
    },
    handleClose(){
      
    }
  },
};
</script>

<style scoped lang="scss">
.content {
  height: 50vh;
  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
  }
  .comment {
    position: relative;
    font-size: 0.768rem /* 24px -> .768rem */;
    margin-top: 0.64rem /* 20px -> .64rem */;
    .item {
      > div:first-child {
        img {
          width: 1.856rem /* 58px -> 1.856rem */;
          height: 2.112rem /* 66px -> 2.112rem */;
          vertical-align: middle;
        }
        span {
          margin-left: 0.64rem /* 20px -> .64rem */;
        }
      }
      .rightContent {
        margin-left: 2.496rem /* 78px -> 2.496rem */;
      }
      .time {
        margin-top: 0.64rem /* 20px -> .64rem */;
        font-size: 0.512rem /* 16px -> .512rem */;
        .left {
          padding: 0.16rem /* 5px -> .16rem */;
          background: rgba(2, 86, 255, 0.102);
          color: rgba(93, 147, 255, 1);
          border-radius: 1.28rem /* 40px -> 1.28rem */;
        }
        .right {
          // margin-left: 0.64rem /* 20px -> .64rem */;
          color: rgba(137, 137, 137, 1);
        }
      }
    }
    p {
      text-align: center;
    }
    .write {
      position: absolute;
      bottom: 0;
    }
  }
  .review {
    position: relative;
    padding: 0.64rem /* 20px -> .64rem */;
    margin-bottom: 1.92rem /* 60px -> 1.92rem */;
    .bottomWrite1 {
      position: fixed;
      bottom: 0;
      ::v-deep .el-input {
        top: -0.32rem; /* -10px -> -0.32rem */
      }
    }
  }

  .introduce {
    border-left: 3px solid #0256ff;
    font-size: 0.896rem /* 28px -> .896rem */;
    padding-left: 0.416rem /* 13px -> .416rem */;
    margin-top: 0.96rem /* 30px -> .96rem */;
    margin-bottom: 0.64rem /* 20px -> .64rem */;
  }
 

}
.title{
        display: flex;
        justify-content: space-between;
        font-size: .96rem /* 30px -> .96rem */;
        background: #F8F8F8;
        img{
            width: .96rem /* 30px -> .96rem */;
            height: .96rem /* 30px -> .96rem */;
        }
        margin-left: .16rem /* 5px -> .16rem */;
    }
    ::v-deep #el-drawer__title{
    background: #F8F8F8!important;
    margin-bottom: 0;
    padding-bottom: 1.333rem;
}
.rewordSorce{
  padding: .192rem /* 6px -> .192rem */ .32rem /* 10px -> .32rem */;
  background: rgba(243, 0, 0, 0.1);
  color: #F30000;
  border-radius: 1.6rem /* 50px -> 1.6rem */;
  img{
    width: .64rem /* 20px -> .64rem */!important;
    height: .64rem /* 20px -> .64rem */!important;
  }
}
.timeSpecile{
  display: flex;
  justify-content: space-between;
  color: #0256FF;
  & span:nth-child(2){
    text-decoration: underline;
  }
  img{
    width: .576rem /* 18px -> .576rem */;
    height: auto;
  }
}
</style>
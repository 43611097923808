import axios from "axios";
import request from "@/utils/request";
import dictData from "./dictData.js";
import XEUtils from "xe-utils";
import { Notification, MessageBox, Message, Loading } from "element-ui";
import { api } from "@/api/api.js";
import store from "@/store";
import router from "../router";
import { Notify } from "vant";
// export function getfiles(url){
//   let p = []
//   if(url){
//     p
//   }
//   return p cutArray

// } cutArray
export function getfu(e) {
  if (!e) {
    return "";
  }
  var reg = /<[^<>]+>/g; //去标签
  // var reg = /<(?!img).*?>/g //去除img以外的所有标签
  var value = e.replace(reg, "");
  value = value.replace(/&nbsp;/gi, ""); //将空格全部替换
  let TiLength = value.length; //文字长度显示
  return value;
}
//图片，文件 上传成功回调
export function handleUploadSuccess(res, that) {
  if (that.type == 2) {
    // let data = res.data ? res.data : {};
    let data = res ? res : {};
    that.uploadList.push(data);
    if (that.uploadList.length === that.number) {
      that.fileList = that.fileList.concat(that.uploadList);
      that.uploadList = [];
      that.number = 0;
      that.$emit("input", JSON.stringify(that.fileList));
      that.$modal.closeLoading();
    }
  } else {
    let url = res.url ? res.url : res.data && res.data.url ? res.data.url : "";
    that.uploadList.push({ name: url, url: url });
    if (that.uploadList.length === that.number) {
      that.fileList = that.fileList.concat(that.uploadList);
      that.uploadList = [];
      that.number = 0;
      that.$emit("input", that.listToString(that.fileList));
      that.$modal.closeLoading();
    }
  }
}
//图片，文件 上传失败回调
export function handleUploadError(that, err) {
  that.$modal.msgError("上传图片失败，请重试");
  that.$modal.closeLoading();
}
// 删除文件
export function handleDelete(index, that) {
  that.fileList.splice(index, 1);
  //this.$emit("input", this.listToString(this.fileList));
  if (that.type == 2) {
    that.fileList.length
      ? that.$emit("input", JSON.stringify(that.fileList))
      : that.$emit("input", "");
  } else {
    // that.$emit("input", that.listToString(that.fileList));
    that.fileList.length
      ? that.$emit("input", that.listToString(that.fileList))
      : that.$emit("input", "");
  }
}
// 删除图片
export function handleRemove(file, fileList, that) {
  const findex = that.fileList.map((f) => f.name).indexOf(file.name);
  if (findex > -1) {
    that.fileList.splice(findex, 1);
    if (that.type == 2) {
      that.$emit("input", JSON.stringify(that.fileList));
    } else {
      that.$emit("input", that.listToString(that.fileList));
    }
  }
}
//富文本
export function editorUploadSuccess(res, file, that) {
  // 获取富文本组件实例
  let quill = that.Quill;
  // 如果上传成功
  if (res.code == 200) {
    let url = res.url ? res.url : res.data && res.data.url ? res.data.url : "";
    // 获取光标所在位置
    let length = quill.getSelection().index;
    // 插入图片  res.url为服务器返回的图片地址
    quill.insertEmbed(length, "image", url);
    // 调整光标到最后
    quill.setSelection(length + 1);
  } else {
    that.$message.error("图片插入失败");
  }
}
export function editorUploadError(that, err) {
  that.$message.error("图片插入失败");
}
export function nav(url, query, type = 1) {
  let p = {
    path: url,
    query: query,
  };
  if (type == 1) {
    router.push(p);
  } else {
    let routerJump = router.resolve(p);
    window.open(routerJump.href, "_blank");
  }
}
// 打印
export function log(val, title = "打印------------") {
  console.log(title, val);
}
//弹框确定
export function msgBox(msg, type = "warning") {
  return new Promise((resolve, reject) => {
    let message = msg;
    MessageBox.confirm(message, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
}
// 消息提示
export function success(msg, duration = 2000) {
  Message({
    message: msg,
    showClose: true,
    duration: duration,
    type: "success",
  });
}
export function error(msg, duration = 2000) {
  console.log("error", msg);
  Message({
    message: msg,
    showClose: true,
    duration: duration,
    type: "error",
  });
}
export function notice(title, msg, key = "info", position = "top-right") {
  Notification[key]({
    title: title,
    message: msg,
    showClose: true,
    position: "top-right",
  });
}

export function getprice(e, zhi) {
  let texts = "";

  if (zhi == "F") {
    return texts;
  }
  if (e && e.isExport == "T") {
    if (e && e.currency && e.exchangeRate) {
      texts = parseFloat(zhi / e.exchangeRate).toFixed(2);
    } else {
      if (zhi || zhi == 0) {
        texts = parseFloat(zhi).toFixed(2);
      }
    }
  } else {
    if (zhi || zhi == 0) {
      texts = parseFloat(zhi).toFixed(2);
    }
  }

  return texts;
}
// 处理 query
export function handleQuery(query) {
  let text = "?";
  if (query && Object.keys(query).length > 0) {
    for (let i in query) {
      if (Object.keys(query)[0] == i) {
        text = text + i + "=" + query[i];
      } else {
        text = text + "&" + i + "=" + query[i];
      }
    }
  } else {
    text = "";
  }
  return text;
}
//

export function getSplit(val) {
  //console.log('getJson---',val)
  if (val) {
    let p = val.split(",");
    return p;
  } else {
    return [];
  }
}
export function getJson(val) {
  //console.log('getJson---',val)
  if (val) {
    let p = JSON.parse(val);
    return p;
  } else {
    return [];
  }
}
export function debounce(fn, delay) {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

function getValue(key, value) {
  let list = dictData[key];
  if (list) {
    for (let i in list) {
      if (value == list[i].value) {
        return list[i].label;
      }
    }
  }
}
export function getFormat(key, value) {
  // console.log('getFormat:: key, value', key, value)
  if (!key || value === undefined) {
    return "";
  }

  return getValue(key, value);
}
export function getFormat2(key, cellValue) {
  if (!cellValue && cellValue != 0) {
    return "";
  }
  return getValue(key, cellValue);
}
export function getuuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23];
  var uuid = s.join("");
  return uuid;
}
/**
 * 表格时间格式化 utc转换
 */
export function formatDate(cellValue, type, con = "-") {
  if (cellValue == null || cellValue == "") return "";

  var date = new Date(cellValue);
  var year = date.getFullYear();
  var month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  if (type == 1) {
    return year + con + month;
  } else if (type == 2) {
    return year + con + month + con + day;
  } else if (type == 3) {
    return year + con + month + con + day + " " + hours + ":" + minutes;
  } else {
    return (
      year +
      con +
      month +
      con +
      day +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds
    );
  }
}
export function cutArray(arr, length) {
  let arrLength = arr.length; // 数组长度
  let num = length; // 每页显示 10 条
  let index = 0;
  let newArr = [];
  for (let i = 0; i < arrLength; i++) {
    if (i % num === 0 && i !== 0) {
      // 可以被 10 整除
      newArr.push(arr.slice(index, i));
      index = i;
    }
    if (i + 1 === arrLength) {
      newArr.push(arr.slice(index, i + 1));
    }
  }
  return newArr;
}

// 数组内部去重

export function handeleGetCode(arr) {
  let newArr = [];
  console.log("arr---------------------", arr);
  if (arr && arr.length > 0) {
    for (let i in arr) {
      newArr.push(arr[i].productCode);
    }
  }
  return newArr;
}
// 数组内部去重

export function handeleArr(arr) {
  let newArr = [];
  if (arr && arr.length > 0) {
    arr.sort();
    let preItemValue = ""; // arr[0]
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i] !== preItemValue) {
        newArr.push(arr[i]);
        preItemValue = arr[i];
      }
    }
  }
  return newArr;
}
// 去重
export function handeleRepeatFilter(mapList, DataList) {
  console.log("handeleRepeatFilter", mapList, DataList);
  let arr = [];
  if (DataList && DataList.length > 0) {
    for (let j in DataList) {
      let count = 0;
      if (mapList && mapList.length > 0) {
        for (let i in mapList) {
          if (mapList[i].productCode == DataList[j].productCode) {
            count = count + 1;
          }
        }
      }
      if (count == 0) {
        arr.push(DataList[j]);
      }
    }
    return arr;
  } else {
    return DataList;
  }
}
// 还原
export function handeleRest(list) {
  let arr = [];
  if (list && list.length > 0) {
    var gettree = function (data, node) {
      for (let i in data) {
        if (Reflect.has(data[i], "childs")) {
          Reflect.deleteProperty(data[i], "childs");
        }
        arr.push(data[i]);

        // if (data[i].childs && data[i].childs.length > 0) {
        //     gettree(data[i].childs, data[i])
        // }
      }
    };
    gettree(list);
  }
  // if(arr&&arr.length>0){
  // 	for(let i in list){
  // 		if (Reflect.has(list[i], 'childs')) {
  // 		    Reflect.deleteProperty(list[i], 'childs');
  // 		}
  // 	}
  // }
  return arr;
}
// 删除数据
export function handeledel(list, row, that) {
  if (list && list.length > 0) {
    var gettree = function (data, node) {
      for (let i in data) {
        if (Reflect.has(data[i], "name")) {
          // Reflect.deleteProperty(tagList[i], '_X_ID');
        } else {
          if (row.productCode == data[i].productCode) {
            data.splice(i, 1);
          }
        }
        if (data[i] && data[i].children && data[i].children.length > 0) {
          gettree(data[i].children, data[i]);
        }
      }
    };
    gettree(list);
  }
}
// 分组
export function handeleGroup(data, keys) {
  const result = [];
  let dataKeys = [];
  let data2 = [];
  if (data && data.length > 0) {
    for (let j in data) {
      if (data[j][keys]) {
        dataKeys.push(data[j]);
      } else {
        data2.push(data[j]);
      }
    }
  }
  XEUtils.each(XEUtils.groupBy(dataKeys, keys), (childs, key) => {
    result.push({
      name: key,
      children: childs,
    });
  });
  // result = result.concat(data2);
  return result.concat(data2);
}

// 加载远程字典数据
export async function getDictData(code, method = "post") {
  // 机构("baseOrg", "机构"),
  // 信息类别("baseDataCategory", "信息类别"),
  // 信息提供者("baseProvider", "信息提供者"),
  // 使用场景("baseSense", "使用场景"),
  let p = await getDictData2(code, method);
  return p;
}
export function getDictData2(code, method = "post") {
  return new Promise((resolve, reject) => {
    let data = {
      code: code,
    };
    let url = "/system/common/loadDictOptions";
    if (!code) {
      resolve([]);
      return;
    }
    let notLimitReqTime = true; //不限制请求时间间隔
    api(url, data, method, notLimitReqTime)
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        resolve([]);
        // reject(error);
      });
  });
}
export function getPermi(value) {
  let p = false;
  const all_permission = "*:*:*";
  const permissions = store.getters && store.getters.permissions;

  if (value && value instanceof Array && value.length > 0) {
    const permissionFlag = value;

    const hasPermissions = permissions.some((permission) => {
      return (
        all_permission === permission || permissionFlag.includes(permission)
      );
    });

    if (hasPermissions) {
      p = true;
    }
  }
  return p;
}

export function formatDuring(mss) {
  let days = parseInt(mss / (1000 * 60 * 60 * 24));
  let hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = (mss % (1000 * 60)) / 1000;
  let ms = mss % 1000;
  let ss = "";
  if (days > 0) {
    ss += ays + " 天 ";
  }
  if (hours > 0) {
    ss += hours + " 小时 ";
  }
  if (minutes > 0) {
    ss += minutes + " 分钟 ";
  }
  if (seconds > 0) {
    ss += seconds + " 秒 ";
  }
  // if(ms > 0){
  //   ss += ms + " 毫秒 ";
  // }
  return ss;
}
export function downfile(downloadUrl, fileNames = "") {
  if (!downloadUrl) {
    return;
  }
  window.open(downloadUrl, "_blank");
}

/**
 * 字符串格式化：移除字符串的第1个和最后1个斜杠
 * 如：/开发者生态/开发环境/
 * 变：开发者生态/开发环境
 */
export function strFormatTrimLRX(s) {
  if (s && s.length > 2) {
    if (s.indexOf("/") == 0) {
      s = s.substr(1);
    }
    if (s.substr(s.length - 1) == "/") {
      s = s.substring(0, s.length - 1);
    }
    return s;
  }
}
export function gettags(val, list) {
  let p = "";
  if (val) {
    if (list && list.length > 0) {
      for (let i in list) {
        let tags = val.split(",");
        // console.log('tags-----------',tags)
        if (tags && tags.length > 0) {
          for (let k in tags) {
            if (list[i].id == tags[k]) {
              if (p) {
                p = p + "," + list[i].tag;
              } else {
                p = list[i].tag;
              }
            }
          }
        }
      }
    }
  }
  return p;
}

export function selectStoreOsImageList() {
  return new Promise((resolve, reject) => {
    let data = {};
    let url = "/swbus/frontapis/swStoreOsImage/selectStoreOsImageList";
    api(url, data, "GET")
      .then((res) => {
        let dataList = [];
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item) {
              dataList.push({
                label: item.title,
                value: item.image,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}
// 模块统计
export function swLogBlockView(item, that) {
  return new Promise((resolve, reject) => {
    let data = {
      blockId: item.id,
      // "blockId": 0,
      // "blockName": "",
      // "createBy": "",
      // "createTime": "",
      // "cuid": "",
      // "dbxStatus": 0,
      // "id": 0,
      // "ip": "",
      // "remark": "",
      // "sortx": 0,
      // "tenantId": "",
      // "uid": 0,
      // "updateBy": "",
      // "updateTime": "",
      // "userId": ""
    };
    let url = "/swbus/modules/swLogBlockView/submit";
    api(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
// 文档下载统计
export function swLogDocView(item, that) {
  return new Promise((resolve, reject) => {
    let data = {
      docNo: item.documentNo,
    };
    let url =
      "/swbus/frontapis/swLogDocView/downloadFile?docNo=" + item.documentNo;
    request({
      method: "get",
      url: url,
      responseType: "blob",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error, err, msg) => {
        // Message({
        //   message: '您当前无权限访问文档。',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        Notify({ type: "danger", message: "您当前无权限访问文档。" });
        reject(error);
      });
  });
}
// 文档浏览统计
export function swLogDocViewWiki(item, that) {
  return new Promise((resolve, reject) => {
    let data = {
      docNo: item.documentNo,
    };
    let url =
      "/swbus/frontapis/swLogDocView/readWikiAndBuildTree?docNo=" +
      item.documentNo;
    request({
      method: "get",
      url: url,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error, err, msg) => {
        reject(error);
      });
  });
}

//判断系统维护
export function systemIsService() {
  return new Promise((resolve, reject) => {
    api("/swbus/frontapis/swCmsIndexConfig/findCmsHomeModuleActive", {}, "get")
      .then((res) => {
        let cmsIndexConfig = res.data;
        if (cmsIndexConfig) {
          store.dispatch("settings/changeSetting", {
            key: "pageKind",
            value: "00",
          });
          window.location.href = cmsIndexConfig.htmlPage;
        } else {
          store.dispatch("settings/changeSetting", {
            key: "pageKind",
            value: "01",
          });
        }
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//判断系统维护 --新
export function checkSiteStatus() {
  return new Promise((resolve, reject) => {
    api("/swbus/frontapis/swCmsIndexConfig/findCmsHomeModuleActive", {}, "get")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// 网站访问统计
export function swLogSiteView(item) {
  return new Promise((resolve, reject) => {
    let data = {
      pageUrl: item.pageUrl,
      pageName: item.pageName,
    };

    let url = "/swbus/frontapis/swLogSiteView/submit";
    api(url, data)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//获取自定义表单
export function getFrontFormConfig(formType = "03") {
  return new Promise((resolve, reject) => {
    let data = { formType: formType };
    let url =
      "/swbus/frontapis/swDefineFormConfig/getFrontFormConfig?formType=" +
      formType;
    api(url, data, "get")
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//解析 自定义表单
export function handleyulan(list = [], type = 1) {
  let p = [];
  let peizhi = {
    text: "vxe-input",
    textarea: "vxe-textarea",
    richtext: "ws-editor",
    select: "ws-select-input",
    radio: "ws-radios",
    checkbox: "ws-checkboxs",
    file: "ws-fileUpload3",
  };
  if (type == 3) {
    peizhi = {
      text: "ws-text",
      textarea: "ws-text",
      richtext: "ws-editor-text",
      select: "ws-text",
      radio: "ws-text",
      checkbox: "ws-text",
      file: "ws-filelists",
    };
  }
  console.log("list", list);
  let formdata = {};
  if (list && list.length > 0) {
    for (let i in list) {
      let props = {
        placeholder: list[i].placeholder,
      };

      if (list[i].inputType == "radio") {
        props.options = list[i].data;
        props.isconfig = false;
      }
      if (list[i].inputType == "checkbox") {
        props.options = list[i].data;
        props.isconfig = false;
        if (type == 3) {
          props.type = 2;
        }
      }
      if (list[i].inputType == "select") {
        let d = ["其它"];
        props.options = d.concat(list[i].data);
        props.clearable = true;
        props.isconfig = false;
      }
      if (list[i].inputType == "file") {
        // props.isShowTip = false
        props.fileType = ["doc", "xls", "ppt", "pdf", "zip", "rar"];
        props.fileSize = parseInt(list[i].fileSize);
        props.limit = 5;
      }

      let component = {
        name: peizhi[list[i].inputType],
        props: props,
      };

      let edit = {
        span: 24,
        component: component,
        defaultValue: list[i].defaultValue,
      };
      if (list[i] && list[i].required) {
        edit.rule = [
          {
            required: true,
            message: list[i].label + "不能为空",
            trigger: "blur",
          },
        ];
      }
      let k = {
        label: list[i].label,
        prop: list[i].prop,
        edit: edit,
      };

      //formdata[list[i].prop] = list[i].values
      p.push(k);
    }
  }
  console.log("this.formColumn------------------", formdata, p);
  return { formdata: formdata, formColumn: p };
}
const wsresize = {
  bind(el, binding) {
    let height = "";
    function isResize() {}
    el.__vueSetInterval__ = setInterval(isResize, 1000);
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__);
  },
};

const wsClick = {
  inserted(el, binding) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 1000);
      }
    });
  },
};
const wsdirectives = {
  wsresize,
  wsClick,
};
export const directives = {
  install(Vue) {
    Object.keys(wsdirectives).forEach((key) => {
      Vue.directive(key, wsdirectives[key]);
    });
  },
};

export function selectAdapteConfigMemoryList() {
  return new Promise((resolve, reject) => {
    let data = {};
    let url =
      "/swbus/frontapis/swBaseDictData/findBaseDictDataListByPno?code=sw_adapte_config_memory_list";
    api(url, data, "GET")
      .then((res) => {
        let dataList = [];
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item) {
              dataList.push({
                label: item.name,
                value: item.code,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

export function selectAdapteConfigStorageList() {
  return new Promise((resolve, reject) => {
    let data = {};
    let url =
      "/swbus/frontapis/swBaseDictData/findBaseDictDataListByPno?code=sw_adapte_config_storage_list";
    api(url, data, "GET")
      .then((res) => {
        let dataList = [];
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item) {
              dataList.push({
                label: item.name,
                value: item.code,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

//获取所有通过审核的企业
export function listAllPassCompany() {
  return new Promise((resolve, reject) => {
    let data = {};
    let url = "/api/swbus/frontapis/swUcCompany/listAllPass";
    api(url, data, "GET")
      .then((res) => {
        let dataList = [];
        // console.log(url, res.data)
        if (
          res.data &&
          res.data.success &&
          res.data.data &&
          res.data.data.length > 0
        ) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if (item) {
              dataList.push({
                label: item.name,
                value: item.id,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}
//获取部门
export function getdept(comIdx) {
  return new Promise((resolve, reject) => {
    if (!comIdx) {
      resolve([]);
      return;
    }
    let data = {
      comIdx: comIdx,
    };
    let url = "/api/sw2-devcom-system/dept/listAll?comIdx=" + comIdx;
    if (!url) {
      return;
    }
    api(url, data, "GET")
      .then((res) => {
        // let list = res&&res.data&&res.data.data?res.data.data:[]
        // resolve(list);
        let dataList = [];
        if (
          res.data &&
          res.data.success &&
          res.data.data &&
          res.data.data.length > 0
        ) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if (item) {
              dataList.push({
                label: item.deptName,
                value: item.id,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}
//获取岗位
export function getpost(comIdx) {
  return new Promise((resolve, reject) => {
    if (!comIdx) {
      resolve([]);
      return;
    }
    let data = {
      comIdx: comIdx,
    };
    let url = "/api/sw2-devcom-system/post/listAll?comIdx=" + comIdx;
    if (!url) {
      return;
    }
    api(url, data, "GET")
      .then((res) => {
        // let list = res&&res.data&&res.data.data?res.data.data:[]
        // resolve(list);
        let dataList = [];
        if (
          res.data &&
          res.data.success &&
          res.data.data &&
          res.data.data.length > 0
        ) {
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            if (item) {
              dataList.push({
                label: item.postName,
                value: item.id,
              });
            }
          }
        }
        resolve(dataList);
      })
      .catch((error) => {
        resolve([]);
      });
  });
}

/**
 * @author yjy
 * @function 节流
 * @param {Function} fn 执行函数
 * @param {Number} delay 延迟时间
 * @return {Function} 节流函数
 *
 */
export function throttle(fn, delay) {
  let nowFirst = Date.now();
  let arg = arguments;
  return function () {
    let that = this;
    let nowLast = Date.now();
    if (nowLast - nowFirst < delay) return;
    fn.apply(that, arg);
    nowFirst = Date.now();
  };
}

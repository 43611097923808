import Layout from "@/components/Layout.vue";

export default {
  path: "/resource",
  component: Layout,
  redirect: "index",
  children: [
    {
      path: "index",
      component: (resolve) => require(["@/views/sw/resource/index"], resolve),
      name: "资源中心",
      meta: { title: "资源中心", icon: "user" },
    },
    {
      path: "document",
      component: (resolve) =>
        require(["@/views/sw/resource/document/index"], resolve),
      name: "文档中心",
      meta: { title: "文档中心", icon: "" },
    },
    {
      path: "document/allDocuments",
      redirect: "/resource/document",
    },
    //   {
    //     path: "document/allDocuments",
    //     component: (resolve) => require(["@/views/sw/resource/document/allDocuments"], resolve),
    //     name: "文档资料列表",
    //     meta: { title: "文档资料列表", icon: 'user'},
    //   },
    {
      path: "document/wikiDetail",
      component: (resolve) =>
        require(["@/views/sw/resource/document/wikiDetail"], resolve),
      name: "文档资料列表",
      meta: { title: "文档资料列表", icon: "user" },
    },
    {
      path: "article",
      component: (resolve) =>
        require(["@/views/sw/resource/article/index"], resolve),
      name: "开发者专栏",
      meta: { title: "开发者专栏", icon: "user" },
    },
    //   {
    //     path: "article/release",
    //     component: (resolve) => require(["@/views/sw/resource/article/release"], resolve),
    //     name: "发表文章",
    //     meta: { title: "发表文章", icon: 'user'},
    //   },
    {
      path: "article/detail",
      component: (resolve) =>
        require(["@/views/sw/resource/article/detail"], resolve),
      name: "文章详情",
      meta: { title: "文章详情", icon: "user" },
    },
    {
      path: "article/authorColumn",
      component: (resolve) =>
        require(["@/views/sw/resource/article/authorColumn"], resolve),
      name: "作者专栏",
      meta: { title: "作者专栏", icon: "user" },
    },

    //   {
    //     path: "preview",
    //     component: (resolve) => require(["@/views/sw/resource/preview"], resolve),
    //     name: "预览",
    //     meta: { title: "预览", icon: 'user'},
    //   },
    {
      path: "share",
      component: (resolve) => require(["@/views/sw/resource/share"], resolve),
      name: "分享",
      meta: { title: "分享", icon: "user" },
    },
  ],
};

import Cookies from 'js-cookie'
import { setStore, getStore,removeStore } from '@/utils/store'

const TokenKey = 'Admin-Token-swgw'

const ExpiresInKey = 'Admin-Expires-In'

export function getUserFromStroe(){
  var token = getToken();
  if(token){
    var u= getStore({ name: 'userInfo' })
    if(u)
      return u;
  }else{
    removeStore({name:'userInfo'});
  }
  return {}
}

export function clearUserFromStore(){
  removeStore({name:'userInfo'});
  removeStore({name:'token'});
  // Cookies.remove(cookie_token_key);
  // Cookies.remove(cookie_token_key,{domain: 'wxiat.com'});
}

export function getToken() {
  return getStore({ name: 'token' })
}

export function saveUserToStore(u,token){
  setStore({ name: 'userInfo', content:u })
  if(token){
    setStore({ name: 'token', content:token})
  }else{
    token = getToken();
  }
  // Cookies.set(cookie_token_key, token,{ domain: 'wxiat.com' })
  // Cookies.set(cookie_token_key, token)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}

<template>
  <div class="content">
    <div class="header">
      <Head></Head>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
    name:"Layout",
    data(){
      return {

      }
    },
    created(){
      this.$store.dispatch('GetInfo')
    }
}
</script>
<style>
.van-notify{
  z-index:99999!important
}
</style>
<style lang="scss" scoped>
.content{
  background-color: #f4f5f5;
  position: relative;
  .header{
    position: sticky;
    top: 0;
    z-index: 9999;
  }
}
</style>
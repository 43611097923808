import  Layout from '@/components/Layout.vue';



export default {
    path: "/my",
    component: Layout,
    children: [
      {
        path: "menu",
        component: (resolve) => require(["@/views/sw/my/menu"], resolve),
        name: "个人中心",
        meta: { title: "个人中心", icon: ''},
      },
      {
        path: "index",
        component: (resolve) => require(["@/views/sw/my/index"], resolve),
        name: "用户信息",
        meta: { title: "用户信息", icon: ''},
      },
      {
        path: "myinfo",
        component: (resolve) => require(["@/views/sw/my/myinfo"], resolve),
        name: "用户信息编辑",
        meta: { title: "用户信息编辑", icon: ''},
      },
      {
        path: "address",
        component: (resolve) => require(["@/views/sw/my/address"], resolve),
        name: "收货地址信息",
        meta: { title: "收货地址信息", icon: ''},
      },
      {
        path: "company",
        component: (resolve) => require(["@/views/sw/my/company"], resolve),
        name: "企业信息",
        meta: { title: "企业信息", icon: ''},
      },
      {
        path: "message",
        component: (resolve) => require(["@/views/sw/my/message"], resolve),
        name: "消息管理",
        meta: { title: "消息管理", icon: ''},
      },
      {
        path: "gift",
        component: (resolve) => require(["@/views/sw/my/gift"], resolve),
        name: "礼品兑换",
        meta: { title: "礼品兑换", icon: ''},
      },
      {
        path: "giftDetail",
        component: (resolve) => require(["@/views/sw/my/giftDetail"], resolve),
        name: "礼品兑换详情",
        meta: { title: "礼品兑换详情", icon: ''},
      },
      {
        path: "task",
        component: (resolve) => require(["@/views/sw/my/task"], resolve),
        name: "任务管理",
        meta: { title: "任务管理", icon: ''},
      },
      {
        path: "uploadResult",
        component: (resolve) => require(["@/views/sw/my/uploadResult"], resolve),
        name: "上传成果",
        meta: { title: "上传成果", icon: ''},
      },
      {
        path: "activity",
        component: (resolve) => require(["@/views/sw/my/activity"], resolve),
        name: "我的活动",
        meta: { title: "我的活动", icon: ''},
      },
      {
        path: "workOrder",
        component: (resolve) => require(["@/views/sw/my/workOrder"], resolve),
        name: "我的工单",
        meta: { title: "我的工单", icon: ''},
      },
      {
        path: "workOrder/detail",
        component: (resolve) => require(["@/views/sw/my/orderDetail"], resolve),
        name: "工单详情",
        meta: { title: "工单详情", icon: ''},
      },
      {
        path: "result",
        component: (resolve) => require(["@/views/sw/my/result"], resolve),
        name: "我的成果",
        meta: { title: "我的成果", icon: ''},
      },
      {
        path: "result/detail",
        component: (resolve) => require(["@/views/sw/my/resultDetail"], resolve),
        name: "成果详情",
        meta: { title: "成果详情", icon: ''},
      },
      {
        path: "points",
        component: (resolve) => require(["@/views/sw/my/points"], resolve),
        name: "我的积分",
        meta: { title: "我的积分", icon: ''},
      },
      {
        path: "article",
        component: (resolve) => require(["@/views/sw/my/article"], resolve),
        name: "我的文章",
        meta: { title: "我的文章", icon: ''},
      },

      {
        path: "authentication",
        component: (resolve) => require(["@/views/sw/my/authentication"], resolve),
        name: "我的适配认证",
        meta: { title: "我的适配认证", icon: 'user'},
      },
    ],
};
